import React, { FC } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

import DialogLayout from './Dialog';
import Button from '../components/Button/Button';

import assets from '../assets';
import NftImage from '../components/NftImage/NftImage';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  gameId: string;
  onContinue: () => void;
}

const AssetTransfered: FC<IProps> = ({ open, setOpen, gameId, onContinue }) => {
  const nft = useSelector((state: RootState) => state.nfts.selectedNft)!;
  const metadata = JSON.parse(nft.metadata);
  const itemName = metadata?.name.split(' ').at(-1);

  const scholar = useSelector(
    (state: RootState) => state.vault.selectedScholar
  );

  const games = useSelector(
    (state: RootState) => state.games.gamesWithContracts
  );
  const game = games.find(g => g.game_id === gameId);
  return (
    <DialogLayout open={open}>
      <Container>
        <div className='close-button'>
          <img
            src={assets.closeIcon}
            style={{ cursor: 'pointer' }}
            onClick={() => setOpen(false)}
            alt=''
          />
        </div>
        <Content>
          <h1 className='title'>Asset Transfered Successfully!</h1>
          <p className='font-size-14' style={{ marginTop: '24px' }}>
            <b>
              {itemName} ({nft.token_id})
            </b>{' '}
            by <b>{game?.name}</b> was transferred to{' '}
            <b>{scholar?.nick_name}</b>.
          </p>

          <Nft>
            <div className='image'>
              <NftImage nftData={nft} container size={180} />
            </div>
            <div className='info'>
              <p className='font-size-10'>{nft.name}</p>
              <div className='row'>
                <p className='font-size-14 bold'>{itemName}</p>
                <img src={assets.moreVert} alt='' />
              </div>
            </div>
          </Nft>

          <ButtonContainer>
            <Button variant='secondary'>Pull Back</Button>
            <Button
              variant='primary'
              onClick={() => {
                setOpen(false);
                onContinue();
              }}
            >
              Continue
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default AssetTransfered;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 392px;

  & .close-button {
    display: flex;
    justify-content: flex-end;
    padding-block: 6px;
  }
`;

const Content = styled.div`
  padding: 40px 30px;
  background: #121212;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const Nft = styled.div`
  width: 184px;
  margin-inline: auto;
  border: 1px solid #ffffff33;
  overflow: hidden;
  border-radius: 8px;
  margin-block: 30px 40px;
  & div.image {
    aspect-ratio: 1/1;
    border-bottom: 1px solid #ffffff33;
    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  & div.info {
    padding: 12px 16px;
    & .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & img {
        cursor: pointer;
      }
    }
  }
`;
