import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

import Game from '../Game/Game';
import assets from '../../assets';
import { IFilterGame } from '../../types/interfaces';

interface IProps {
  games: Array<IFilterGame>;
}

const GameSlider: FC<IProps> = ({ games }) => {
  const [showArrows, setShowArrows] = useState(true);

  const handleWindowResize = () => {
    if (window.innerWidth > 800 && games.length > 4) {
      setShowArrows(true);
    } else if (
      window.innerWidth > 500 &&
      window.innerWidth <= 800 &&
      games.length > 3
    ) {
      setShowArrows(true);
    } else if (window.innerWidth <= 500 && games.length > 2) {
      setShowArrows(true);
    } else {
      setShowArrows(false);
    }
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Container>
      {/* @ts-ignore */}
      <Splide
        renderControls={() => (
          <>
            {showArrows && (
              <div className='splide__arrows'>
                <div className='splide__arrow--prev' role='button'>
                  <img src={assets.arrowLeft} alt='' />
                </div>
                <div className='splide__arrow--next' role='button'>
                  <img src={assets.arrowRight} alt='' />
                </div>
              </div>
            )}
          </>
        )}
        options={{
          perPage: 4,
          gap: '24px',
          pagination: false,
          rewind: true,
          breakpoints: {
            1000: {
              perPage: 4,
              gap: '24px',
            },
            800: {
              perPage: 3,
              gap: '20px',
            },
            500: {
              perPage: 2,
              gap: '16px',
            },
          },
        }}
      >
        {games ? (
          <>
            {games.map((game, index) => (
              <SplideSlide key={index}>
                <Game gameData={game} />
              </SplideSlide>
            ))}
          </>
        ) : (
          <>
            {Array.from({ length: 12 }, (_, i) => i).map(i => (
              <SplideSlide key={i}>
                <Game />
              </SplideSlide>
            ))}
          </>
        )}
      </Splide>
    </Container>
  );
};

export default GameSlider;

const Container = styled.div`
  & .splide__arrows {
    & .splide__arrow--prev {
      position: absolute;
      top: 0;
      left: 0px;
      height: calc(100% - 32px);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      cursor: pointer;
    }
    & .splide__arrow--next {
      position: absolute;
      top: 0;
      right: 0px;
      height: calc(100% - 32px);
      background-color: red;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 700px) {
    & .splide__arrows {
      & .splide__arrow--prev,
      & .splide__arrow--next {
        width: 50px;
        & img {
          width: 10px;
        }
      }
    }
  }
  @media only screen and (max-width: 420px) {
    & .splide__arrows {
      & .splide__arrow--prev,
      & .splide__arrow--next {
        width: 40px;
        & img {
          width: 10px;
        }
      }
    }
  }
`;
