import { createModel } from '@rematch/core';
import { RootModel } from '.';

import { getSupportedChains } from '../../http';

interface IChain {
  name: string;
  chain: string;
}
interface IType {
  name: string;
  contract_type: string;
}

interface IState {
  erc_contract: Array<IChain>;
  nft_contract: Array<IChain>;
  nft_type: Array<IType>;
}

export const chains = createModel<RootModel>()({
  name: 'chains',
  state: {
    erc_contract: [],
    nft_contract: [],
    nft_type: [],
  } as IState,
  reducers: {
    setErcContractChains(state, payload: Array<IChain>) {
      state.erc_contract = payload;
    },
    setNftContractChains(state, payload: Array<IChain>) {
      state.nft_contract = payload;
    },
    setNftTypes(state, payload: Array<IType>) {
      state.nft_type = payload;
    },
  },
  effects: dispatch => ({
    async getChains() {
      const { data } = await getSupportedChains();
      const { erc_contract, nft_contract, nft_type } = data;
      dispatch.chains.setErcContractChains(erc_contract);
      dispatch.chains.setNftContractChains(nft_contract);
      dispatch.chains.setNftTypes(nft_type);
    },
  }),
});
