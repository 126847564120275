import axios from 'axios';

import {
  IGetNfts,
  ICreateSetApiData,
  IGetScholarAssets,
  IAssignBadge,
  IPullBackNft,
  IPayoutData,
  // IGame,
  IGameWithContracts,
  ITokenMetadata,
  IVaultNftResponse,
  ICreateScholarship,
  IPullBackNfts,
  INFTContract,
  IGameTokenBalance,
  IPayoutHistory,
  IBenjiPerformance,
} from '../types/interfaces';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
  },
});

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers!['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (
      (error?.response?.status === 401 || error?.response?.status === 403) &&
      window.location.pathname !== '/login'
    ) {
      localStorage.clear();
      window.location.pathname = '/login';
    }
    return Promise.reject(error);
  }
);

interface Ilogin {
  email: string;
  password: string;
}

interface IRecoverPassword {
  code: string;
  password: string;
  confirm_password: string;
}

// registration apis

export const signup = (data: any) => api.post('/v1/auth/signup', data);

export const emailVerification = (code: string, token: string) =>
  api.post(`/v1/auth/email-verification/${token}`, { code });

export const login = (data: Ilogin) => api.post('/v1/auth/login', data);

export const forgotPassword = (email: string) =>
  api.post('/v1/auth/forgot-password', { email });

export const recoverPassword = (data: IRecoverPassword, token: string) =>
  api.post(`/v1/auth/recover-password/${token}`, data);

// get userinfo

export const getUserInfo = () => api.get('/v1/user');

// get scholar by id

export const getScholarById = (id: string) => api.get(`/v1/user/${id}/profile`);

// get all scholar users

export const getAllScholars = (query?: string) =>
  api.get(`/v1/scholar?${query}`);

// get badges

export const getBadges = () => api.get('/v1/badges');

export const getScholarBadges = (id: string) =>
  api.get(`/v1/scholar/${id}/badges`);

// connect wallet

export const connectWallet = (public_address: string) =>
  api.post('/v1/wallet/generate', { public_address });

export const getWalletAccounts = () => api.get('/v1/wallet/accounts');

// minting api

// export const mint = (data: {
//   chain_id: number;
//   game_id: number;
//   badge_id: number;
// }) => api.post('/v1/minting', data);

// nft apis

export const getAllNft = (data: IGetNfts) => api.post('/v1/assets', data);

export const getAllFilteredNft = (data: any) =>
  api.post('/v1/assets/filter', data);

export const assignBadge = (data: IAssignBadge) =>
  api.post('/v1/token-badge/create', data);

// sets apis

export const getSets = (query: string) =>
  api.get(`/v1/sets/game/badge${query}`);

export const createSet = (data: ICreateSetApiData) => {
  return api.post('/v1/sets/create', data);
};

// Get scholar assets --- based on scholar address and game_id

export const getScholarAssets = (data: IGetScholarAssets) => {
  return api.get(`/v1/assets/${data.public_address}/${data.user_id}/nfts`);
};

// Game apis

// <IGame[]>

export const getGames = (filter?: string) =>
  api.get(`/v1/games${filter ? `?filter=${filter}` : ''}`);

export const getGamesWithContracts = () =>
  api.get<IGameWithContracts[]>('/v1/games/contracts');

// Add game apis

export const addGame = (data: any) => api.post('/v1/game', data);
export const addGameImages = (data: any, game_id: string) =>
  api.post(`v1/game/image/${game_id}`, data);
export const addGameScreenShots = (data: any, game_id: string) =>
  api.post(`v1/game/${game_id}/screenshots`, data);

export const getGameScreenshots = (game_id: string) =>
  api.get(`/v1/game/${game_id}/screenshots`);

export const deleteGameScreenshots = (data: string[]) =>
  api.delete(`/v1/game/screenshot?ids=${JSON.stringify(data)}`);

export const addGameNftContract = (id: string, data: any) =>
  api.post(`/v1/game/${id}/contracts`, data);

export const getGameNftContract = (id: string) =>
  api.get<INFTContract[]>(`/v1/game/${id}/contracts`);

export const updateGameNftContract = (game_id: string, id: string, data: any) =>
  api.patch(`/v1/game/${game_id}/contracts/${id}`, data);

export const deleteGameNftContract = (game_id: string, id: string) =>
  api.delete(`/v1/game/${game_id}/contracts/${id}`);

export const updateGame = (id: string, data: any) =>
  api.patch(`/v1/game/${id}`, data);

export const getScholarGames = (id: string) =>
  api.get(`/v1/scholar/${id}/game`);

export const updateGameImages = (id: string, data: any) =>
  api.patch(`/v1/game/image/${id}`, data);

export const getBalanceInGame = (gameId: string, publicAddress: string) =>
  api.get(`/v1/scholar/game/${gameId}/public-address/${publicAddress}/balance`);

// percentage api

export const updateScholarPercentage = (
  id: string,
  data: { percentage: number }
) => api.put(`/v1/scholar/${id}/percentage`, data);

export const getSupportedChains = () => api.get('/v1/supported-chain');

// assets pullback api

export const pullbackNfts = (data: IPullBackNfts) =>
  api.post('/v1/pull/nft', data);
// export const pullbackNfts = (data: IPullBackNft) =>
//   api.post('/v1/pull/nft', data);

export const pullBackNftHistory = (data: IPullBackNft) =>
  api.get(`nft/logs/${data.set_id}/${data.user_id}/${data.game_id}`);

// scholar sets
export const scholarSets = (id: string) => api.get(`/v1/sets/user/${id}`);

// Payout apis

export const payout = (data: IPayoutData) => api.post('/v1/payout', data);

// metadata
export const getNftMetadata = (data: {
  contract_address: string;
  token_id: string;
  token_uri?: string;
}) => api.post('/v1/nft/metadata', data);

export const getTokenMetaData = (data: {
  public_address: string;
  chain_id: string;
}) =>
  api.get<ITokenMetadata>(
    `/v1/token/${data.public_address}/${data.chain_id}/metadata`
  );

// admin timeline

export const adminTimeline = (query?: string) => api.get(`/v1/logs?${query}`);

// nft api

export const getAllNfts = (data: { contract_id: string }, cursor?: string) =>
  api.post<IVaultNftResponse>(
    `/v1/assets/list${cursor && '?cursor=' + cursor}`,
    data
  );

export const getNftsByGameAndChain = (data: {
  public_address: string;
  gameId: string;
  chainId: string;
}) =>
  api.get(`/v1/assets/${data.public_address}/${data.gameId}/${data.chainId}`);

// scholarship api

export const createScholarship = (data: ICreateScholarship) =>
  api.post('/v1/game/scholarship', data);

export const updateScholarship = (
  data: ICreateScholarship,
  scholarshipId: string
) => api.patch(`/v1/game/${scholarshipId}/scholarship`, data);

export const addScholarshipImage = (data: any, scholarship_id: string) =>
  api.patch(`/v1/game/${scholarship_id}/scholarship/image`, data);

export const getScholarship = (scholarshipId: string) =>
  api.get(`/v1/game/scholarship/${scholarshipId}`);

export const getAllScholarships = () => api.get('/v1/game-scholarships');

export const getUserScholarshipById = (scholarshipId: string) =>
  api.get(`/v1/user/scholarship/${scholarshipId}`);

export const updateUserScholarship = (
  scholarshipId: string,
  data: { status: 1 | 2 }
) => api.patch(`/v1/user/scholarship/${scholarshipId}`, data);
// notifications

export const getNotifications = () => api.get('/v1/notification');

// transfer assets

export const transferAssets = (data: {
  game_id: string;
  contract_id: string;
  token_id: string;
  user_id: string;
  amount?: number;
}) => api.post('/v1/transfer/nft', data);

// user minted badges

export const getUserMintedBadges = (user_id: string) =>
  api.get(`/v1/user/${user_id}/badges`);

// scholar that have applied in a game and their scholarship is accepted

export const getScholarsByGameId = (game_id: string) =>
  api.get(`/v1/scholar/game/${game_id}`);

// fungible token balance of game

export const gameFungibleTokens = (game_id: string) =>
  api.get<IGameTokenBalance>(`/v1/token/${game_id}`);

// transfer fungible token

export const transferFungibleToken = (data: {
  game_id: string;
  to: string;
  value: string;
}) => api.post('/v1/transfer/fungible', data);

// user payout history

export const getUserPayoutHistory = (user_id: string) =>
  api.get<IPayoutHistory[]>(`/v1/payout/${user_id}`);

// captain scholars

export const getCaptainScholars = (capId: string, game_id: string) =>
  api.get(`/v1/captain/scholar?captainId=${capId}&game_id=${game_id}`);

export const generatePasswordsForCaptainScholars = (capId: string) =>
  api.post('/v1/generate/password', { captainId: capId });

export const getCaptains = (gameId: string) =>
  api.get(`/v1/captain/game/${gameId}`);

export const getCaptainGames = (capID: string) =>
  api.get(`/v1/captain/${capID}/game`);

export const benjiPerformance = (public_address: string) =>
  api.get<IBenjiPerformance>(`v1/benji/perfomance/${public_address}`);

export const captainPayout = (data: any) =>
  api.post('/v1/captain/payout', data);

// claim reward from benji for normal scholar

export const benjiClaim = (address: string) =>
  api.post('/v1/benji/claim', { address });

// benji payout

export const benjiPayout = (data: any) => api.post('/v1/benji/payout', data);
