import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../store';

import DialogLayout from './Dialog';
import Button from '../components/Button/Button';
import TransferAssetRow from './TransferAssetRow';

import assets from '../assets';
import { IScholarshipUser } from '../types/interfaces';
import { transferAssets } from '../http';
import axios from 'axios';
import Loader from '../components/Loader/Loader';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onAssetTransfer: () => void;
  category: string;
  gameId: string;
}

interface IApiData {
  game_id: string;
  contract_id: string;
  token_id: string;
  user_id: string;
  amount?: number;
}

const TransferAsset: FC<IProps> = ({
  open,
  setOpen,
  onAssetTransfer,
  category,
  gameId,
}) => {
  const dispatch = useDispatch<Dispatch>();

  const scholarshipData = useSelector(
    (state: RootState) => state.notifications.selected
  );
  const scholars = useSelector((state: RootState) => state.vault.scholars);
  const scholarsLoading = useSelector(
    (state: RootState) => state.vault.scholarLoading
  );
  const nft = useSelector((state: RootState) => state.nfts.selectedNft);
  const nftAmount = useSelector(
    (state: RootState) => state.nfts.nftAmountToTransfer
  );

  const [loading, setLoading] = useState(false);

  const [allScholars, setAllScholars] = useState<IScholarshipUser[]>([]);

  const [selectedUser, setSelectedUser] = useState<IScholarshipUser | null>(
    null
  );

  const handleAssetTransfer = async () => {
    try {
      setLoading(true);
      if (selectedUser && nft) {
        let apiData: IApiData = {
          game_id: gameId,
          contract_id: category,
          token_id: nft.token_id,
          user_id: selectedUser?.id,
        };

        if (nft.contract_type === 'ERC1155') {
          apiData = { ...apiData, amount: nftAmount };
        }

        await transferAssets(apiData);
        setOpen(false);
        onAssetTransfer();
      } else {
        dispatch.feedback.open({
          title: 'Error',
          subtitle: 'Please select a user first!',
        });
      }
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const errMessage = (err as any).response?.data.message;
        dispatch.feedback.open({ title: 'Error', subtitle: errMessage });
      } else {
        console.log(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch.scholars.getScholars({ query: `game_id=${gameId}` });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (scholarshipData) {
      const selected = scholars.find(
        (scholar: IScholarshipUser) => scholar.id === scholarshipData.user_id
      )!;
      const remaining = scholars.filter(scholar => scholar.id !== selected.id);
      setAllScholars([selected, ...remaining]);
      setSelectedUser(selected);
      dispatch.vault.setSelectedScholar(selected);
    } else {
      setAllScholars([...scholars]);
    }
    // eslint-disable-next-line
  }, [scholars]);
  return (
    <DialogLayout open={open}>
      <Container>
        <div className='close-button'>
          <img
            src={assets.closeIcon}
            style={{ cursor: 'pointer' }}
            onClick={() => setOpen(false)}
            alt=''
          />
        </div>
        <Content>
          <h1 className='title'>Transfer Asset</h1>
          <SearchBar htmlFor='search-bar'>
            <img src={assets.searchIcon} alt='' />
            <input
              type='text'
              name=''
              className='font-size-12'
              id='search-bar'
              placeholder='Search'
            />
          </SearchBar>
          {scholarsLoading ? (
            <Loader />
          ) : (
            <TableContainer>
              <table>
                <thead>
                  <tr className='header-row'>
                    <th>User</th>
                    <th>Email Address</th>
                    <th>Badge</th>
                    <th>Wallet Address</th>
                  </tr>
                </thead>
                <tbody>
                  {allScholars.map(scholar => (
                    <TransferAssetRow
                      key={scholar.id}
                      scholar={scholar}
                      selectedUser={selectedUser!}
                      setSelectedUser={setSelectedUser}
                    />
                  ))}
                </tbody>
              </table>
            </TableContainer>
          )}

          <ButtonContainer>
            <Button variant='secondary' onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={handleAssetTransfer}
              disabled={loading || !selectedUser}
              loading={loading}
              // onClick={() => {
              //   handleAssetTransfer();
              //   // setOpen(false);
              //   // onAssetTransfer();
              // }}
            >
              Confirm
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default TransferAsset;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;

  & .close-button {
    display: flex;
    justify-content: flex-end;
    padding-block: 6px;
  }
`;

const Content = styled.div`
  padding: 40px 30px;
  background: #121212;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
`;

const SearchBar = styled.label`
  height: 34px;
  display: flex;
  align-items: center;
  padding-inline: 16px;
  gap: 8px;
  background-color: #000000;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  margin-block: 30px 24px;

  & input {
    flex-grow: 1;
    outline: none;
    border: none;
    background-color: transparent;
    height: 100%;
  }
`;

const TableContainer = styled.div`
  overflow: auto;
  border: 1px solid #ffffff33;
  padding-bottom: 10px;
  width: 100%;
  border-radius: 4px;
  max-height: 408px;
  & table {
    border-collapse: collapse;
    min-width: 500px;
    width: 100%;

    & .header-row {
      height: 40px;
      background-color: #0e0e0e;

      & th {
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        opacity: 0.6;
        padding-top: 4px;
        border-bottom: 1px solid #ffffff33;
        text-align: left;
        &:first-of-type {
          padding-left: 32px;
        }

        & .row {
          display: flex;
          align-items: center;
          gap: 8px;

          & .col {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
        }
      }
    }
    & tr {
      height: 60px;
      &.active {
        background-color: #ffffff33;
      }
    }
    & td {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      padding-block: 12px;
      &:first-of-type {
        padding-left: 32px;
      }

      & .address {
        padding: 4px;
        border-radius: 2px;
        background-color: #ffffff1a;
      }

      & .row {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
    & tr:last-child {
      & td {
        border: none;
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
  justify-content: center;
`;
