import React, { FC } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../store';

import DialogLayout from './Dialog';
import Button from '../components/Button/Button';

import assets from '../assets';

const ErrorDialog: FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const state = useSelector((state: RootState) => state.errorModal);
  return (
    <DialogLayout open={state.open}>
      <Container>
        <div
          className='close-icon'
          onClick={() => dispatch.errorModal.closeDialog()}
        >
          <img src={assets.closeIcon} alt='' />
        </div>
        <div className='content'>
          <h1 className='title'>Error:</h1>
          <p className='font-size-14'>{state.error}</p>
          <div className='btn'>
            {state?.action ? (
              <Button variant='primary'>Buy STZ</Button>
            ) : (
              <Button
                variant='primary'
                onClick={() => dispatch.errorModal.closeDialog()}
              >
                Continue
              </Button>
            )}
          </div>
        </div>
      </Container>
    </DialogLayout>
  );
};

export default ErrorDialog;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 420px;
  & .close-icon {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    & img {
      cursor: pointer;
    }
  }
  & .content {
    margin-top: 6px;
    padding: 40px 30px;
    background-color: #121212;
    @media only screen and (max-width: 400px) {
      padding: 30px 20px;
    }

    & p {
      margin-block: 20px 40px;
    }

    & .btn {
      display: flex;
      justify-content: center;
    }
  }
`;
