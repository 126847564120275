import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../store';

import DialogLayout from './Dialog';
import TokenAmountSelector from '../components/TokenAmountSelector/TokenAmountSelector';
import Button from '../components/Button/Button';
import Loader from '../components/Loader/Loader';

import assets from '../assets';
import { gameFungibleTokens, transferFungibleToken } from '../http';
import { IGameTokenBalance } from '../types/interfaces';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  gameId: string;
}

const SendFungibleTokens: FC<IProps> = ({ open, setOpen, gameId }) => {
  const dispatch = useDispatch<Dispatch>();

  const [loading, setLoading] = useState(false);
  const [transferLoading, setTransferLoading] = useState(false);
  const [value, setValue] = useState('');

  const [tokenBalance, setTokenBalance] = useState<IGameTokenBalance | null>(
    null
  );

  const selectedScholar = useSelector(
    (state: RootState) => state.scholars.selected
  );

  const handleTransferToken = async () => {
    try {
      setTransferLoading(true);
      if (+value > +tokenBalance!.balance) {
        dispatch.feedback.open({
          title: 'Invalid input',
          subtitle: `You do not have enough ${tokenBalance?.symbol} tokens`,
        });
      } else {
        const apiData = {
          game_id: gameId,
          to: selectedScholar!.starz_wallet_address,
          value: value,
        };
        // eslint-disable-next-line
        const { data } = await transferFungibleToken(apiData);
        setOpen(false);
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setTransferLoading(false);
    }
  };

  const getGameTokenData = async () => {
    try {
      setLoading(true);
      const { data } = await gameFungibleTokens(gameId);
      setTokenBalance(data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGameTokenData();
    // eslint-disable-next-line
  }, []);

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='close-button'>
          <img src={assets.closeIcon} alt='' onClick={() => setOpen(false)} />
        </div>

        <Content>
          <h1 className='title'>Send Fungible Tokens</h1>

          <p className='font-size-14 subtitle'>
            You are about to send tokens to <b>{selectedScholar?.nick_name}</b>.
          </p>
          {loading ? (
            <Loader noPadding style={{ paddingBlock: '30px' }} />
          ) : (
            <div className='token-amount'>
              {tokenBalance && (
                <>
                  <TokenAmountSelector
                    tokenBalance={tokenBalance}
                    value={value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setValue(e.target.value)
                    }
                  />
                  <div className='remaining-token-count'>
                    <img src={assets.stzIcon} alt='' />
                    {/* <p className='font-size-14'>{tokenBalance?.symbol}</p> */}
                    <div>
                      <p className='font-size-18'>
                        {tokenBalance.balance} {tokenBalance?.symbol}
                      </p>
                      <p className='font-size-10' style={{ opacity: '0.4' }}>
                        left available for you to transfer
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          <ButtonContainer>
            <Button variant='secondary' onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={handleTransferToken}
              loading={transferLoading}
              disabled={transferLoading}
            >
              Confirm
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default SendFungibleTokens;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  & .close-button {
    display: flex;
    justify-content: flex-end;
    padding-block: 6px;

    & img {
      cursor: pointer;
    }
  }
`;

const Content = styled.div`
  background: #121212;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
  padding: 40px 30px;

  & p.subtitle {
    margin-top: 24px;
  }

  & .token-amount {
    margin-block: 30px 40px;
    display: flex;
    align-items: center;
    gap: 30px;

    & .remaining-token-count {
      display: flex;
      align-items: flex-start;
      margin-top: 30px;
      gap: 8px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;
