import React, { FC, useState, useEffect } from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { useNavigate } from 'react-router-dom';

import Page from '../../layouts/Page';
import Select from '../../components/Select/Select';
import Filters from '../../components/Filters/Filters';
import NotificationDetails from '../../dialogs/NotificationDetails';
import Loader from '../../components/Loader/Loader';

import { getFormatedTime } from '../../utils';
import assets from '../../assets';
import theme from '../../theme';
import { NotificationTypes } from '../../types/enums';
import { INotification } from '../../types/interfaces';

dayjs.extend(utc);

const Notification: FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const notifications = useSelector(
    (state: RootState) => state.notifications.notifications
  );

  const loading = useSelector(
    (state: RootState) => state.notifications.loading
  );

  const [openNotification, setOpenNotification] = useState(false);

  const [filters, setFilters] = useState({
    game: '',
    sortBy: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.currentTarget;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleRenderNotifications = (notification: INotification) => {
    if (notification.type === NotificationTypes.SCHOLARSHIP) {
      return (
        <NotificationItem
          key={notification.id}
          onClick={() => {
            if (notification.is_accepted === 0) {
              dispatch.notifications.setSelected(notification);
              setOpenNotification(true);
            }
            if (notification.is_accepted === 1) {
              dispatch.notifications.setSelected(notification);
              navigate('/dashboard/vault');
            }
          }}
        >
          <div className='image'>
            <img src={notification.image_url || assets.user} alt='' />
            {/* don't render this if offline user */}
            {/* <div className='dot'></div> */}
          </div>
          <div>
            <p className='font-size-14'>
              <b>{notification.nick_name}</b> applied for scholarship in{' '}
              <b>{notification.name}</b>.
            </p>

            <p className='font-size-10' style={{ opacity: '0.4' }}>
              {getFormatedTime(notification.created_at)}
            </p>
          </div>
        </NotificationItem>
      );
    } else if (notification.type === NotificationTypes.ASSET_TRANSFER) {
      return (
        <NotificationItem key={notification.id}>
          <div className='image'>
            <img src={notification.image_url || assets.user} alt='' />
            {/* don't render this if offline user */}
            {/* <div className='dot'></div> */}
          </div>
          <div>
            <p className='font-size-14'>
              Asset <b>({notification.asset_id})</b> by{' '}
              <b>{notification.name}</b> was sent to{' '}
              <b>{notification.nick_name}</b>.
            </p>
            <p className='font-size-10' style={{ opacity: '0.4' }}>
              {getFormatedTime(notification.created_at)}
            </p>
          </div>
        </NotificationItem>
      );
    } else if (notification.type === NotificationTypes.OTHERGUILDSCHOLARS) {
      return (
        <NotificationItem
          key={notification.id}
          onClick={() => {
            if (notification.is_accepted === 0) {
              dispatch.notifications.setSelected(notification);
              navigate('/dashboard/captain-scholars');
            }
          }}
        >
          <div className='image'>
            <img src={notification.logo} alt='' />
            {/* don't render this if offline user */}
            {/* <div className='dot'></div> */}
          </div>
          <div>
            <p className='font-size-14'>
              <b>{notification.nick_name}</b> applied for other guild captain in{' '}
              <b>{notification.name}</b>.
            </p>

            <p className='font-size-10' style={{ opacity: '0.4' }}>
              {getFormatedTime(notification.created_at)}
            </p>
          </div>
        </NotificationItem>
      );
    }
  };

  useEffect(() => {
    dispatch.notifications.getAllNotifications();
    // eslint-disable-next-line
  }, []);
  return (
    <Page>
      <Container>
        <h1 className='title'>Notifications</h1>
        <Filters>
          <Grid>
            <Select
              title='Select Game'
              options={[{ label: 'sorare', value: 'sorare' }]}
              name='game'
              value={filters.game}
              onChange={handleChange}
            />
            <Select
              title='Sort by..'
              options={[{ label: 'sorare', value: 'sorare' }]}
              name='sortBy'
              value={filters.sortBy}
              onChange={handleChange}
            />
            <Select
              title='Sort by..'
              options={[{ label: 'sorare', value: 'sorare' }]}
              name='sortBy'
              value={filters.sortBy}
              onChange={handleChange}
            />
          </Grid>
        </Filters>
        {loading && !notifications.length ? (
          <Loader />
        ) : (
          <>
            {notifications.length ? (
              <NotificationList>
                {notifications.map((notification: INotification) => {
                  return handleRenderNotifications(notification);
                })}
              </NotificationList>
            ) : (
              <NotFound>
                <p className='font-size-20'>No notifications found</p>
              </NotFound>
            )}
          </>
        )}
      </Container>
      {openNotification && (
        <NotificationDetails
          open={openNotification}
          setOpen={setOpenNotification}
        />
      )}
    </Page>
  );
};

export default Notification;

const Container = styled.div`
  padding-bottom: 30px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
`;

const NotificationList = styled.div`
  margin-top: 30px;
  background: #121212;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 10px 30px;
`;

const NotificationItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  transition: 0.4s ease;
  cursor: pointer;
  border-radius: 2px;
  &:hover {
    background-color: #ffffff1a;
  }

  & .image {
    position: relative;
    & img {
      height: 48px;
      width: 48px;
      border-radius: 50%;
      object-fit: cover;
    }

    & .dot {
      height: 12px;
      width: 12px;
      position: absolute;
      top: 2px;
      right: 2px;
      background-color: ${theme.palette.primary};
      border-radius: 50%;
    }
  }
`;
// eslint-disable-next-line
const Divider = styled.div`
  border-top: 1px solid #ffffff33;
  margin-block: 24px;
`;

const NotFound = styled.div`
  padding-top: 100px;
  display: flex;
  justify-content: center;
`;
