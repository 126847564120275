import React, { FC } from 'react';
import styled from 'styled-components';
import theme from '../../theme';

interface IProps {
  [x: string]: any;
}

const Checkbox: FC<IProps> = ({ ...rest }) => {
  return <CheckboxInput {...rest} type='checkbox'></CheckboxInput>;
};

export default Checkbox;

const CheckboxInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  height: 12px;
  width: 12px;
  border: 1px solid #ffffff66;
  border-radius: 2px;
  position: relative;
  aspect-ratio: 1/1;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 0px;
    width: 0px;
    background-color: ${theme.palette.primary};
    border-radius: 1px;
    transition: 0.4s ease;
  }
  &:checked::before {
    content: '';
    height: 8px;
    width: 8px;
  }
`;
