import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';

import DialogLayout from './Dialog';
import assets from '../assets';

import AssetsPullRow from './AssetsPullRow';
import TokenPullRow from './TokenPullRow';

import { scholarSets } from '../http';
import { IScholarSet, IScholarUser } from '../types/interfaces';
import Loader from '../components/Loader/Loader';

interface IProps {
  scholar: IScholarUser;
  nfts: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AssetsPullDialog: FC<IProps> = ({ nfts, open, setOpen, scholar }) => {
  const [sets, setSets] = useState<Array<IScholarSet> | null>(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await scholarSets(scholar.id);
        setSets(data);
        console.log(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='close-btn'>
          <img
            src={assets.closeIcon}
            onClick={() => setOpen(false)}
            style={{ cursor: 'pointer' }}
            alt=''
          />
        </div>
        <div className='content'>
          <h1 className='title text-center'>
            Asset pulling from {scholar.fullname}
          </h1>
          {!loading && !sets?.length && (
            <p className='font-size-14 text-center'>
              No sets found for this user
            </p>
          )}
          {loading ? (
            <Loader />
          ) : (
            <>
              {sets?.length && (
                <div className='tableContainer'>
                  {nfts ? (
                    <div className='inner'>
                      <div className='f-header'>
                        <div className='header-items'>Games</div>
                        <div className='header-items'>Badge</div>
                        <div className='header-items assets'>Token Balance</div>
                        <div className='header-items'>Enter Amount</div>
                        <div className='header-items'>Withdraw</div>
                      </div>
                      <TokenPullRow />
                      <TokenPullRow />
                      <TokenPullRow />
                      <TokenPullRow />
                      <TokenPullRow />
                    </div>
                  ) : (
                    <div className='inner'>
                      <div className='header'>
                        <div className='header-items'>Games</div>
                        <div className='header-items'>Badge</div>
                        <div className='header-items assets'>Assets</div>
                        <div className='header-items'>Withdraw</div>
                      </div>
                      {sets?.map((set, index) => (
                        <AssetsPullRow
                          key={index}
                          set={set}
                          scholarId={scholar.id}
                        />
                      ))}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </Container>
    </DialogLayout>
  );
};

export default AssetsPullDialog;

const Container = styled.div`
  & .close-btn {
    text-align: right;
    padding: 6px 0px;
  }
  & .content {
    padding-block: 30px 40px;
    padding-inline: 30px;
    background-color: #121212;
    width: 816px;
    & .title {
      margin-bottom: 30px;
    }
  }
  & .tableContainer {
    width: 100%;
    margin-top: 16px;
    overflow-x: auto;
    border: 1px solid #ffffff33;
    padding-inline: 30px;
    padding-bottom: 10px;
    & .inner {
      min-width: 690px;
      width: 100%;
      & .header {
        height: 40px;
        border-bottom: 1px solid #ffffff33;
        display: flex;
        align-items: center;
        padding-inline: 10px;
        & .header-items {
          flex: 1;
          font-size: 10px;
          line-height: 16px;
          font-weight: 600;
          color: #ffffff99;
          min-width: 120px;
          &.assets {
            min-width: 250px;
          }
        }
      }
      & .f-header {
        height: 40px;
        border-bottom: 1px solid #ffffff33;
        display: flex;
        align-items: center;
        padding-inline: 10px;
        & .header-items {
          flex: 1;
          font-size: 10px;
          line-height: 16px;
          font-weight: 600;
          color: #ffffff99;
          min-width: 120px;
        }
      }
    }
  }
`;
