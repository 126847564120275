import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import Button from '../../components/Button/Button';
import InputField from '../../components/InputField/InputField';
import Page from '../../layouts/Page';
import EthAddress from '../../components/EthAddress/EthAddress';
import NftImage from '../../components/NftImage/NftImage';
import EditAssetsDialog from '../../dialogs/EditAssetsDialog';

import assets from '../../assets';
import { badgeImagesByName } from '../../app.config';
import {
  getScholarAssets,
  getScholarById,
  getScholarGames,
  getUserMintedBadges,
  getUserPayoutHistory,
  updateScholarPercentage,
} from '../../http';
import {
  IMintedBadge,
  INfts,
  IPayoutHistory,
  IScholarGame,
  IScholarNftResponse,
  ISingleScholar,
} from '../../types/interfaces';
import Loader from '../../components/Loader/Loader';
// import { revenueSplitOptions } from "../../app.config";
// import Select from "../../components/Select/Select";
// import { showToast } from "../../utils";
import { toast } from 'react-toastify';

dayjs.extend(utc);
// interface IRevenue {
//   revenueSplit: number;
//   setRevenueSplit: number;
// }
const UserDetails: FC = () => {
  const [tab, setTab] = useState(0);
  const [editAssets, setEditAssets] = useState(false);
  const params = useParams();
  const userId = params.id;
  const [nfts, setNfts] = useState<INfts[]>([]);

  const [scholar, setScholar] = useState<ISingleScholar | null>(null);
  const [games, setGames] = useState<IScholarGame[]>([]);
  const [maxBadge, setMaxBadge] = useState<IMintedBadge | null>(null);
  const [loading, setLoading] = useState(false);
  const [revenueSplit, setRevenueSplit] = useState('');
  const [rLoading, setRLoading] = useState(false);
  const [payoutHistory, setPayoutHistory] = useState<IPayoutHistory[]>([]);

  const handleUpdateRevenueSplit = async () => {
    try {
      setRLoading(true);

      await updateScholarPercentage(scholar!.id, { percentage: +revenueSplit });
      toast.success('Revenue Is Updated Successfully..!');
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setRLoading(false);
    }
  };

  const getFormattedDate = (date: string): string => {
    const formattedDate = dayjs
      .utc(date)
      .local()
      .format('YYYY-DD-MM  hh:mm:ss');
    return formattedDate;
  };

  const getUserData = async () => {
    try {
      setLoading(true);
      const { data } = await getScholarById(userId!);
      setScholar(data);
      const res = await Promise.all([
        getScholarGames(userId!),
        getScholarAssets({
          public_address: data?.accounts[0]?.starz_wallet_address,
          user_id: userId!,
        }),
        getUserMintedBadges(userId!),
        getUserPayoutHistory(userId!),
      ]);

      setPayoutHistory(res[3].data);

      setRevenueSplit(data?.scholar_percentage);

      setGames(res[0].data);
      const scholarNftsResponse: IScholarNftResponse[] = res[1].data;

      let sNfts: INfts[] = [];
      scholarNftsResponse.forEach(e => {
        e.Nfts.forEach(nft => {
          sNfts.push({
            ...nft,
            contract: e.contract,
            game_id: e.game_id,
            contract_id: e.contract_id,
          });
        });
      });
      setNfts(sNfts);
      const mintedBadges: IMintedBadge[] = res[2].data;
      let _maxBadge = mintedBadges[0];
      mintedBadges.forEach(b => {
        if (b.badge_type > _maxBadge.badge_type) {
          _maxBadge = b;
        }
      });
      setMaxBadge(_maxBadge);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <Container>
        <ProfileAndAssets>
          <div className='wrapper'>
            <div className='user-image'>
              <img src={scholar?.image_url || assets.user} alt='' />
            </div>
            <p className='font-size-18 title'>{scholar?.nick_name}</p>
            <div className='row highest-badge'>
              {maxBadge && (
                <img src={badgeImagesByName[maxBadge?.name]} alt='' />
              )}
              <p
                className='font-size-14'
                style={{ textTransform: 'capitalize' }}
              >
                {scholar?.role}
              </p>
            </div>

            {loading && <Loader />}

            {!loading && games.length && (
              <>
                <p className='font-size-14 title'>Games</p>
                <Divider />

                <UserGamesAndAssets>
                  {/* user games go here */}
                  {games.map(game => (
                    <div className='user-game' key={game.id}>
                      <img src={game.mini_icon} alt='' />
                    </div>
                  ))}
                </UserGamesAndAssets>
              </>
            )}

            {!loading && nfts.length && (
              <>
                <p className='font-size-14 title'>Assets</p>
                <Divider />
                <UserGamesAndAssets className='assets'>
                  {/* user assets go here */}
                  {nfts.map((nft, index) => (
                    <div className='nft' key={index}>
                      <NftImage nftData={nft} size={36} />
                    </div>
                  ))}
                </UserGamesAndAssets>
              </>
            )}
          </div>

          <div className='btn'>
            {nfts.length && !loading && (
              <Button variant='primary' onClick={() => setEditAssets(true)}>
                Edit Assets
              </Button>
            )}
          </div>
        </ProfileAndAssets>
        <Information>
          <Tabs>
            <p
              className={classNames(
                'font-size-14 title tab',
                tab === 0 && 'active'
              )}
              onClick={() => setTab(0)}
            >
              Account Information
            </p>
            <p
              className={classNames(
                'font-size-14 title tab',
                tab === 1 && 'active'
              )}
              onClick={() => setTab(1)}
            >
              Payout History
            </p>
          </Tabs>

          {tab === 0 && (
            <TabPanel>
              <div className='grid'>
                <InputField
                  placeholder='Nickname*'
                  type='text'
                  value={scholar?.nick_name || ''}
                  disabled
                />

                <InputField
                  placeholder='Email Address'
                  type='text'
                  value={scholar?.email || ''}
                  disabled
                />

                <InputField
                  placeholder='Country*'
                  type='text'
                  value={scholar?.country || ''}
                  disabled
                />
                <InputField
                  placeholder='Discord Handle'
                  type='text'
                  value={scholar?.discord || ''}
                  disabled
                />
                <InputField
                  placeholder='Payout Wallet Address'
                  type='text'
                  value={scholar?.accounts[0].wallet_address || ''}
                  disabled
                />

                {/* <Select
                  title="Revenue Split (scholar / admin)"
                  options={revenueSplitOptions}
                  value={revenueSplit || ""}
                  name="rev"
                  onChange={(e) => setRevenueSplit(e.target.value)}
                /> */}

                <InnerDiv>
                  <div className='revenueSplits'>
                    <p>
                      {100 - +revenueSplit}/{revenueSplit}
                    </p>
                  </div>
                  <InputField
                    type='range'
                    className='rangestyle'
                    name='rev'
                    placeholder='Revenue Split (Admin / Scholar)'
                    value={revenueSplit || ''}
                    max='100'
                    min='0'
                    step='1'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setRevenueSplit(e.target.value)
                    }
                    style={{ paddingInline: '0px  ' }}
                  />
                </InnerDiv>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',
                }}
              >
                <Button
                  variant='primary'
                  onClick={handleUpdateRevenueSplit}
                  loading={rLoading}
                  disabled={rLoading}
                >
                  Save changes
                </Button>
              </div>
            </TabPanel>
          )}
          {tab === 1 && (
            <TabPanel>
              {payoutHistory.length && (
                <TableContainer>
                  <table>
                    <thead>
                      <tr className='header-row'>
                        <th>Wallet Address</th>
                        <th>Subwallet Address</th>
                        <th>Payout</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payoutHistory.map((history, index) => (
                        <tr key={index}>
                          <td>
                            <EthAddress
                              address={
                                scholar?.accounts[0].wallet_address || ''
                              }
                            />
                          </td>
                          <td>
                            <EthAddress
                              address={
                                scholar?.accounts[0].starz_wallet_address || ''
                              }
                            />
                          </td>
                          <td>
                            <div className='row'>
                              <p className='font-size-14'>
                                {history.payout_amount_user}{' '}
                                {history.payout_symbol}
                              </p>
                            </div>
                          </td>
                          <td>{getFormattedDate(history.created_at)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </TableContainer>
              )}
            </TabPanel>
          )}
        </Information>
      </Container>
      {editAssets && (
        <EditAssetsDialog
          getUserData={getUserData}
          user={scholar}
          open={editAssets}
          setOpen={setEditAssets}
        />
      )}
    </Page>
  );
};

export default UserDetails;

const Container = styled.div`
  margin-block: 80px 60px;
  display: flex;
  gap: 64px;

  @media only screen and (max-width: 950px) {
    flex-direction: column;
  }
`;

const ProfileAndAssets = styled.div`
  flex: 0 0 39%;
  max-width: 456px;
  padding-bottom: 40px;
  & .wrapper {
    background-image: url(${assets.glitch});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-inline: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & .user-image {
      margin-top: -40px;
      & img {
        height: 144px;
        width: 144px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    & .row {
      display: flex;
      align-items: center;
      gap: 8px;

      &.highest-badge {
        & img {
          width: 25px;
        }
      }
    }

    & p.title {
      margin-top: 30px;
      margin-bottom: 8px;
    }
  }

  & .btn {
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 950px) {
    flex: 0 0 100%;
    margin-inline: auto;

    & .wrapper {
      padding-inline: 20px;
    }
  }
`;

const UserGamesAndAssets = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 12px;
  margin-top: 24px;
  padding-inline: 24px;
  flex-wrap: wrap;

  & .nft {
    height: 36px;
    width: 36px;
    border-radius: 6px;
    overflow: hidden;
  }

  &.assets {
    gap: 8px;
  }

  & .user-game {
    height: 48px;
    width: 48px;
    border-radius: 12px;
    background-color: #ffffff1a;
    padding: 10px;
    & img {
      width: 100%;
    }
  }

  & .user-asset {
    height: 36px;
    width: 36px;

    & img {
      width: 100%;
    }
  }
`;

const Information = styled.div`
  flex-grow: 1;
`;

const Divider = styled.div`
  border-top: 1px solid #ffffff33;
  width: 100%;
`;

const Tabs = styled.div`
  display: flex;
  gap: 32px;
  padding-inline: 16px;
  border-bottom: 1px solid #ffffff33;
  padding-block: 8px;

  & .tab {
    cursor: pointer;
    opacity: 0.4;
    transition: 0.4s ease;
    &.active {
      opacity: 1;
    }
  }
`;

const TabPanel = styled.div`
  & .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px 38px;
    margin-top: 52px;
    & .rangestyle {
      /* background-color: aqua; */
      padding: 5px;
    }
  }

  @media only screen and (max-width: 420px) {
    & .grid {
      grid-template-columns: 1fr;
    }
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
  border: 1px solid #ffffff33;
  padding-bottom: 10px;
  width: 100%;
  margin-block: 30px 60px;
  border-radius: 4px;
  & table {
    border-collapse: collapse;
    min-width: 650px;
    width: 100%;
    & tbody {
      padding-inline: 10px;
    }
    & .header-row {
      height: 40px;
      background-color: #0e0e0e;
      & th {
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        opacity: 0.6;
        padding-top: 4px;
        border-bottom: 1px solid #ffffff33;
        text-align: left;
        &:first-of-type {
          padding-left: 32px;
        }

        & .row {
          display: flex;
          align-items: center;
          gap: 8px;

          & .col {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
        }
      }
    }
    & tr {
      height: 60px;
    }
    & td {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      border-bottom: 1px solid #ffffff33;
      padding-block: 12px;
      &:first-of-type {
        padding-left: 32px;
      }

      &:last-of-type {
        padding-right: 10px;
      }

      & .row {
        display: flex;
        align-items: center;
        gap: 10px;

        & img {
          width: 20px;
        }
      }

      & .icon-button {
        & img {
          padding-inline: 5px;
          cursor: pointer;
        }
        & .menu {
          position: absolute;
          width: 200px;
          background: #0e0e0e;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
          transform: translate(-90%, -115%);
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
          display: none;
          & .menu-item {
            padding: 10px 5px;
            cursor: pointer;
            &:hover {
              background-color: #000000;
            }
          }
          & p {
            opacity: 0.6;
          }
          &.show {
            display: block;
          }
        }
      }
    }
    & tr:last-child {
      & td {
        border: none;
      }
    }
  }
`;
const InnerDiv = styled.div`
  color: white;
  & .revenueSplits {
    position: absolute;
    right: 2.5rem;
    margin-top: -1.4rem;
  }
`;
