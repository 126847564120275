import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const truncateString = (value: string): string => {
  const start = value.slice(0, 4);
  const end = value.slice(value.length - 4, value.length);
  return `${start}...${end}`;
};

export const showToast = (type = 'success', msg: string) => {
  if (type === 'success') {
    toast.success(msg, {
      autoClose: 5000,
      className: 'primaryColor',
      position: 'top-right',
    });
  } else if (type === 'error') {
    toast.error(msg, {
      autoClose: 5000,
      className: 'dangerColor',
      position: 'top-right',
    });
  }
};

export const wait = (time: number): Promise<any> => {
  return new Promise(resolve => {
    setTimeout(resolve, time);
  });
};

// format server timestamp

// export const getFormatedTime = (val: string): string => {
//   const date = val.split('T')[0];
//   let time: any = val.split('T')[1];
//   time = time.split(':');

//   let hours = time[0];
//   let minutes = time[1];
//   let stamp = 'am';
//   if (hours > 12) {
//     hours = hours - 12;
//     if (hours < 10) hours = `0${hours}`;
//     if (minutes < 10) minutes = `${minutes}`;
//     stamp = 'pm';
//   }
//   if (hours === 12) stamp = 'pm';
//   time = `${hours}:${minutes} ${stamp}`;

//   const formatDate = date.split('-');
//   return `${formatDate[2]}-${formatDate[1]}-${formatDate[0]} at ${time}`;
// };

export const getFormatedTime = (time: string) => {
  let minutes = dayjs().utc().diff(dayjs(time).utc(), 'm');
  let hours, days;

  hours = Math.floor(minutes / 60);
  minutes = minutes % 60;

  days = Math.floor(hours / 24);
  hours = hours % 24;

  if (days) {
    return `${days}d, ${hours}h and ${minutes}m ago`;
  }

  if (hours) {
    return `${hours}h and ${minutes}m ago`;
  }

  return `${minutes}m ago`;
};
