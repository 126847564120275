import React, { FC, useState } from 'react';

import styled from 'styled-components';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../store';

import DialogLayout from './Dialog';
import Button from '../components/Button/Button';
import NftImage from '../components/NftImage/NftImage';

import assets from '../assets';
import theme from '../theme';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onTransferAsset: () => void;
}

const TransferNFT1155: FC<IProps> = ({ open, setOpen, onTransferAsset }) => {
  const dispatch = useDispatch<Dispatch>();

  const nft = useSelector((state: RootState) => state.nfts.selectedNft);
  const metadata = JSON.parse(nft!.metadata) || '';
  const itemName = metadata?.name.split(' ').at(-1) || '';

  const [count, setCount] = useState(1);

  const handleIncrement = () => {
    if (count === 10) return;
    setCount(count + 1);
  };

  const handleDecrement = () => {
    if (count === 1) return;
    setCount(count - 1);
  };

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='icon-container'>
          <img src={assets.closeIcon} alt='' onClick={() => setOpen(false)} />
        </div>
        <Content>
          <h1 className='title'>Transfer asset</h1>
          <p className='font-size-14 subtitle'>
            Enter how many assets you want to send
          </p>

          <Nft>
            <div className='image'>
              <NftImage nftData={nft} container size={180} />
            </div>
            <div className='info'>
              <p className='font-size-10'>{nft?.name}</p>
              <div className='row'>
                <p className='font-size-14 bold'>{itemName}</p>
                <img src={assets.moreVert} alt='' />
              </div>
            </div>
          </Nft>
          <Counter>
            <div className='row'>
              <span
                role='button'
                onClick={handleDecrement}
                className={classNames(count === 1 && 'disabled')}
              >
                -
              </span>
              <p className='font-size-20'>{count}</p>
              <span
                role='button'
                onClick={handleIncrement}
                className={classNames(count === 10 && 'disabled')}
              >
                +
              </span>
            </div>
          </Counter>
          <ButtonContainer>
            <Button variant='secondary' onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                dispatch.nfts.setNftAmountToTransfer(count);
                onTransferAsset();
                setOpen(false);
              }}
            >
              Continue
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default TransferNFT1155;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 392px;
  & .icon-container {
    display: flex;
    justify-content: flex-end;
    padding-block: 6px;

    & img {
      cursor: pointer;
    }
  }
`;

const Content = styled.div`
  padding: 40px 30px;
  background: #121212;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);

  & p.subtitle {
    margin-block: 24px 40px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const Counter = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  & .row {
    display: flex;
    gap: 30px;
    align-items: center;

    & span {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: ${theme.palette.primary};
      line-height: 30px;
      text-align: center;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
      cursor: pointer;
      &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
      }
    }
  }
`;

const Nft = styled.div`
  width: 184px;
  margin-inline: auto;
  border: 1px solid #ffffff33;
  overflow: hidden;
  border-radius: 8px;
  margin-block: 30px 40px;
  & div.image {
    aspect-ratio: 1/1;
    border-bottom: 1px solid #ffffff33;
    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  & div.info {
    padding: 12px 16px;
    & .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & img {
        cursor: pointer;
      }
    }
  }
`;
