import { createModel } from '@rematch/core';
import { RootModel } from '.';

import { IIndexable } from '../../types/interfaces';

const initialState: IIndexable = {
  updateWallet: false,
  transfer: false,
  exchange: false,
};

const stateObjectKeys = Object.keys(
  initialState
) as unknown as keyof typeof initialState;

export type DialogsPayload = typeof stateObjectKeys;

export const dialogs = createModel<RootModel>()({
  name: 'dialogs',
  state: initialState,
  reducers: {
    openDialog(state, payload: DialogsPayload) {
      const tempState = { ...initialState };
      tempState[payload] = true;
      return (state = tempState);
    },
    closeDialog(state, payload: DialogsPayload) {
      const tempState = { ...state };
      tempState[payload] = false;
      return (state = tempState);
    },
  },
});
