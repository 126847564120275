import { useMemo, useEffect, useState } from 'react';

import { useTable, useSortBy } from 'react-table';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import Page from '../../layouts/Page';
import Button from '../../components/Button/Button';
import CaptainDetails from '../CaptainDetails/CaptainDetails';
import { Navigate } from 'react-router-dom';
import {
  generatePasswordsForCaptainScholars,
  getScholarById,
} from '../../http';
import { ISingleScholar } from '../../types/interfaces';
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
const tableColumns = [
  {
    Header: 'Name',
    accessor: 'username',
    disableSortBy: true,
  },
  {
    Header: 'Email Address',
    accessor: 'email',
    disableSortBy: true,
  },
  {
    Header: 'Wallet Address',
    accessor: 'wallet',
    disableSortBy: true,
  },
  // {
  //   Header: '99 Starz Address',
  //   accessor: 'address',
  //   disableSortBy: true,
  // },
  // {
  //   Header: 'Passwords',
  //   accessor: 'password',
  //   disableSortBy: true,
  // },
];

const NotificationList = () => {
  const dispatch = useDispatch<Dispatch>();
  const [captain, setCaptain] = useState<ISingleScholar | null>(null);
  const [processing, setProcessing] = useState(false);
  const [url, setUrl] = useState('');
  const selected = useSelector(
    (state: RootState) => state.notifications.selected
  );
  const loading = useSelector((state: RootState) => state.scholars.loading);

  const scholars = useSelector(
    (state: RootState) => state.scholars.captainScholars
  );

  //   const data = useMemo(() => ScholarRow, []);
  const columns = useMemo(() => tableColumns, []);
  // const [filters, setFilters] = useState({
  //   game: "",
  //   earning: "",
  //   revinueSplit: "",
  // });
  const tableInstance = useTable(
    {
      // @ts-ignore
      columns,
      data: scholars,
    },
    useSortBy
  );

  const handleGeneratePasswords = async () => {
    try {
      setProcessing(true);
      const { data } = await generatePasswordsForCaptainScholars(
        selected!.user_id
      );
      setUrl(data);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMessage = (err as any).response?.data.message;
        console.log(errorMessage);
      }
    } finally {
      setProcessing(false);
    }
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;
  useEffect(() => {
    (async () => {
      const { data: captainData } = await getScholarById(selected!.user_id);
      setCaptain(captainData);
    })();
    dispatch.scholars.getCaptainScholars(null);
    // eslint-disable-next-line
  }, []);
  if (!selected) {
    return <Navigate to='/dashboard/notifications' replace={true} />;
  }
  return (
    <Page>
      {/* <Filters noMargin>
        <FilterInner>
          <Select
            title="Select Name"
            options={[
              {
                label: "scholar 1",
                value: "30",
              },
              {
                label: "scholar 2",
                value: "40",
              },
            ]}
            name="game"
            value={filters.game}
            // onChange={handleChange}
          />
          <Select
            title="Select By Email"
            options={[
              {
                label: "scholar1@",
                value: "30",
              },
              {
                label: "scholar1@",
                value: "40",
              },
            ]}
            name="earning"
            value={filters.earning}
            // onChange={handleChange}
          />
        </FilterInner>
      </Filters> */}
      <Container>
        {loading ? (
          <Loader />
        ) : (
          <>
            <h1 className='title'>Notifications From Captain</h1>
            {captain && <CaptainDetails captain={captain} />}

            <TableContainer>
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map(headerGroup => (
                    <tr
                      className='header-row'
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map(column => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div className='row'>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? ' 🔽'
                                : ' 🔼'
                              : ''}
                            <div>{column.render('Header')}</div>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                      <tr key={index}>
                        <td>{row.original.fullname}</td>
                        <td>{row.original.email}</td>
                        <td>{row.original.wallet_address}</td>
                        {/* <td>{row.original.starz_wallet_address || ''}</td>
                    <td></td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </TableContainer>
            <BtnContainer>
              {url ? (
                <a href={url} download>
                  <Button variant='primary'>Download CSV</Button>
                </a>
              ) : (
                <Button
                  onClick={handleGeneratePasswords}
                  loading={processing}
                  disabled={processing}
                  variant='primary'
                >
                  Generate Wallets And Passwords
                </Button>
              )}
            </BtnContainer>
          </>
        )}
      </Container>
    </Page>
  );
};
export default NotificationList;

const Container = styled.div`
  &.btn-container {
    display: flex;
    justify-content: end;
    text-align: right;
  }
`;
const TableContainer = styled.div`
  overflow-x: auto;
  border: 1px solid #ffffff33;
  padding-bottom: 10px;
  width: 100%;
  margin-block: 30px 60px;
  border-radius: 4px;
  & table {
    border-collapse: collapse;
    min-width: 950px;
    width: 100%;
    & tbody {
      padding-inline: 10px;
    }
    & .header-row {
      height: 40px;
      background-color: #0e0e0e;
      & th {
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        opacity: 0.6;
        padding-top: 4px;
        border-bottom: 1px solid #ffffff33;
        text-align: left;
        &:first-of-type {
          padding-left: 32px;
        }

        & .row {
          display: flex;
          align-items: center;
          gap: 8px !important;
          justify-content: flex-start;

          & .col {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
        }
      }
    }
    & tr {
      height: 60px;
    }
    & td {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      border-bottom: 1px solid #ffffff33;
      padding-block: 12px;
      &:first-of-type {
        padding-left: 32px;
      }

      &:last-of-type {
        padding-right: 10px;
      }

      & .row {
        display: flex;
        align-items: center;
        gap: 10px;

        & img {
          width: 20px;
        }
      }

      & .icon-button {
        & img {
          padding-inline: 5px;
          cursor: pointer;
        }
        & .menu {
          position: absolute;
          width: 200px;
          background: #0e0e0e;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
          transform: translate(-90%, -115%);
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
          display: none;
          & .menu-item {
            padding: 10px 5px;
            cursor: pointer;
            &:hover {
              background-color: #000000;
            }
          }
          & p {
            opacity: 0.6;
          }
          &.show {
            display: block;
          }
        }
      }
    }
    & tr:last-child {
      & td {
        border: none;
      }
    }
  }
`;
const BtnContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 60px;
`;
// const FilterInner = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr 1fr;
//   gap: 24px;
// `;
