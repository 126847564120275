/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useMemo, useState } from 'react';

import styled from 'styled-components';
import { useTable } from 'react-table';

import Button from '../components/Button/Button';
import DialogLayout from './Dialog';
import MassPayoutRow from '../pages/Earnings/MassPayoutRow';
import Checkbox from '../components/Checkbox/Checkbox';

import { IScholarUser } from '../types/interfaces';

import assets from '../assets';
import { payout } from '../http';

import { showToast } from '../utils';

const tableColumns = [
  {
    Header: 'Checkbox',
    accessor: 'selected',
  },
  {
    Header: 'User',
    accessor: 'username',
    disableSortBy: true,
  },
  {
    Header: 'Email Address',
    accessor: 'email',
    disableSortBy: true,
  },
  {
    Header: 'Scholar Wallet Address',
    accessor: 'subWalletAddress',
    disableSortBy: true,
  },
  {
    Header: 'Revenue Split',
    accessor: 'revinueSplit',
    disableSortBy: true,
  },
  {
    Header: 'Payout amount',
    accessor: 'weeklyEarnings',
    disableSortBy: true,
  },
  {
    Header: 'Total Earnings',
    accessor: 'totalEarning',
  },
  {
    Header: '',
    accessor: 'none',
    disableSortBy: true,
  },
];

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  gameId: string;
  data: Array<IScholarUser>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  resetScholarReward: (id: string) => void;
  handleSelectAllRows: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const MassPayoutDialog: FC<IProps> = ({
  open,
  setOpen,
  gameId,
  data,
  onChange,
  resetScholarReward,
  handleSelectAllRows,
}) => {
  const columns = useMemo(() => tableColumns, []);
  const [payoutInProgress, setPayoutInProgress] = useState(false);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    //  @ts-ignore
    useTable({ data, columns });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payoutList: NodeListOf<HTMLInputElement> =
      e.currentTarget.querySelectorAll('.payout');
    payoutList.forEach(e => console.log(e.value));
    setOpen(false);
  };

  const handleMassPayout = async () => {
    try {
      setPayoutInProgress(true);
      const filteredData = data.filter(scholar => scholar.selected === true);

      for (const scholar of filteredData) {
        try {
          if (scholar.payable === 0) {
            alert('Payout amount should be greater than 0');
            continue;
          }
          await payout({
            account_id: scholar.account_id,
            game_id: gameId,
            value: String(scholar.payable),
          });
          showToast('success', `Payout successful for ${scholar.nick_name}`);
          resetScholarReward(scholar.id);
        } catch (err) {
          showToast('error', `Payout failed for ${scholar.nick_name}`);
        }
      }
      setOpen(false);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setPayoutInProgress(false);
    }
  };

  return (
    <DialogLayout open={open} width={'100%'}>
      <Container>
        <div className='close-icon-container'>
          <img src={assets.closeIcon} alt='' onClick={() => setOpen(false)} />
        </div>
        <form onSubmit={handleSubmit}>
          <Content>
            <h1 className='title'>Mass Payout</h1>

            <TableContainer>
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup, index) => (
                    <tr
                      className='header-row'
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column, index) => {
                        if (index === 0) {
                          return (
                            <th style={{ paddingInline: '10px' }} key={index}>
                              <Checkbox
                                onChange={handleSelectAllRows}
                                checked={data.every(e => e.selected === true)}
                              />
                            </th>
                          );
                        } else {
                          return <th key={index}>{column.render('Header')}</th>;
                        }
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                      <MassPayoutRow
                        row={row}
                        key={index}
                        onChange={onChange}
                      />
                    );
                  })}
                </tbody>
              </table>
            </TableContainer>
            <div className='btn-container'>
              <Button variant='secondary' onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button
                variant='primary'
                type='submit'
                onClick={handleMassPayout}
                disabled={payoutInProgress}
              >
                Confirm
              </Button>
            </div>
          </Content>
        </form>
      </Container>
    </DialogLayout>
  );
};

export default MassPayoutDialog;

const Container = styled.div`
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  & .close-icon-container {
    padding: 6px 0px;
    text-align: right;
    & img {
      cursor: pointer;
    }
  }
`;

const Content = styled.div`
  background-color: #121212;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 40px 30px;

  & .btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
  border: 1px solid #ffffff33;
  padding-inline: 30px;
  padding-bottom: 10px;
  max-height: 50vh;
  overflow-y: auto;
  & .amount-input {
    padding: 6px 10px;
    border: 1px solid #ffffff;
    outline: none;
    width: 140px;
    background-color: transparent;
    color: #ffffff;
  }
  & table {
    border-collapse: collapse;
    min-width: 950px;
    width: 100%;
    & .header-row {
      height: 40px;

      & th {
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        padding-top: 4px;
        color: #ffffff99;
        border-bottom: 1px solid #ffffff33;
        text-align: left !important;

        & img {
          width: 8px;
          opacity: 1;
        }

        & .row {
          display: flex;
          align-items: center;
          gap: 4px;
          justify-content: flex-start;
        }

        & .col {
          display: flex;
          flex-direction: column;
          gap: 3px;
        }
      }
    }
    & tr {
      height: 60px;
    }
    & td {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      border-bottom: 1px solid #ffffff33;
      &.overflow-elipsis {
        white-space: nowrap;
        max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 20px;
      }
      & .row {
        display: flex;
        align-items: center;
        gap: 4px;
        & img.user-img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          margin-right: 6px;
        }
        & img.stz-icon {
          height: 16px;
          width: 16px;
        }
        & .stz-input {
          outline: none;
          background-color: transparent;
          border: 1px solid #ffffff33;
          width: 120px;
          height: 30px;
          color: #ffffff;
          padding-inline: 10px;
          &:focus {
            border: 1px solid #ffffff;
          }
          &:not(:placeholder-shown) {
            border: 1px solid #ffffff;
          }
        }
      }
    }
    & tr:last-child {
      & td {
        border: none;
      }
    }
  }
`;
