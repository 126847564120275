import { createModel } from '@rematch/core';
import { RootModel } from '.';

export const sideBar = createModel<RootModel>()({
  name: 'sideBar',
  state: {
    open: false,
    hidden: false,
  },
  reducers: {
    toggleSideBar(state) {
      state.open = !state.open;
    },
    sideBarHidden(state, payload: boolean) {
      state.open = false;
      state.hidden = payload;
    },
    openSideBar(state) {
      state.open = true;
    },
    closeSideBar(state) {
      state.open = false;
    },
  },
});
