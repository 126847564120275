import React, { FC, SetStateAction, useState } from 'react';

import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
import ClickAwayListener from 'react-click-away-listener';

import theme from '../../theme';
import { IScholarship } from '../../types/interfaces';
import assets from '../../assets';

interface IProps {
  setApply?: React.Dispatch<SetStateAction<boolean>>;
  scholarship: IScholarship;
  editScholarShip: boolean;
  setEditScholarShip: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScholarCard: FC<IProps> = ({ scholarship, setEditScholarShip }) => {
  const dispatch = useDispatch<Dispatch>();

  const [menu, setMenu] = useState(false);

  const handleEdit = () => {
    setMenu(false);
    dispatch.scholarship.setScholarshipToEdit(scholarship);
    setEditScholarShip(true);
  };

  return (
    <Card>
      <div
        className='wrapper'
        style={{
          backgroundImage: `url(${scholarship.scholarship_logo})`,
        }}
      >
        <SpotCount
          style={{
            backgroundColor:
              +scholarship.spots > 3
                ? theme.palette.primary
                : theme.palette.secondary,
          }}
        >
          <div className='row'>
            <div className='count'>
              <p
                className='font-size-14'
                style={{ fontFamily: 'Stalinist One' }}
              >
                {scholarship.spots}
              </p>
              <p className='font-size-8'>
                {+scholarship.spots > 3
                  ? 'Spots left'
                  : +scholarship.spots === 1
                  ? 'Spot left!'
                  : 'Spots left!'}
              </p>
            </div>
            <ClickAwayListener onClickAway={() => setMenu(false)}>
              <Menu>
                <img
                  src={assets.moreVert}
                  alt=''
                  onClick={() => setMenu(!menu)}
                />
                {menu && (
                  <div className='menu-popup'>
                    <div className='menu-item' onClick={handleEdit}>
                      <p className='font-size-12'>Edit Scholarship</p>
                    </div>
                    <div className='menu-item'>
                      <p className='font-size-12'>Delete Scholarship</p>
                    </div>
                  </div>
                )}
              </Menu>
            </ClickAwayListener>
          </div>
        </SpotCount>
      </div>
    </Card>
  );
};

export default ScholarCard;

const Card = styled.div`
  width: 100%;
  aspect-ratio: 1/1.3;
  z-index: 1000;
  & .wrapper {
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    &:hover {
      & .content {
        opacity: 1;
        & .btn {
          transform: translateY(0px);
        }

        & .text {
          transform: translateY(0px);
          opacity: 1;
        }
      }
    }
  }
`;

const SpotCount = styled.div`
  position: absolute;
  top: 0px;
  right: 8px;
  padding: 10px 0px;
  min-width: 100px;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 13px;

    & .count {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    & img {
      cursor: pointer;
      opacity: 0.4;
      &:hover {
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: 420px) {
    height: 35px;
    min-width: 60px;
    & p.font-size-14 {
      font-size: 10px;
      line-height: 14px;
    }
  }
`;

const Menu = styled.div`
  position: relative;

  & .menu-popup {
    position: absolute;
    background-color: #121212;
    width: 145px;
    left: 0;
    top: 0;
    transform: translate(-95%, 0%);
    padding-block: 4px;

    & .menu-item {
      padding: 8px 16px;
      cursor: pointer;

      & p {
        opacity: 0.4;
      }
      &:hover {
        background-color: #000000;
        & p {
          opacity: 1;
        }
      }
    }
  }
`;
