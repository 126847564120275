import React, { FC, useState } from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import ClickAwayListener from 'react-click-away-listener';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';

import assets from '../../assets';
import { IFilterGame } from '../../types/interfaces';
import { showToast } from '../../utils';
import { benjiClaim } from '../../http';
import axios from 'axios';
// import theme from '../../theme';

interface IProps {
  gameData?: IFilterGame;
}

const Game: FC<IProps> = ({ gameData }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch<Dispatch>();
  const [openMenu, setOpenMenu] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCopyPublicAddress = () => {
    if (gameData) {
      navigator.clipboard.writeText(gameData.public_address);
      showToast('success', 'Game public address copied successfully!');
    }
  };

  const handleEditgame = () => {
    if (gameData) {
      dispatch.games.setEditGame(gameData);
      navigate('/dashboard/games/update-game');
    }
  };
  const handleClaimRewards = async () => {
    try {
      if (gameData) {
        setLoading(true);
        await benjiClaim(gameData.public_address);
        dispatch.feedback.open({
          title: 'Success!',
          subtitle: 'Benji reward claimed in game vault successfully!',
        });
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMessage = (err as any).response?.data.message;
        dispatch.feedback.open({
          title: 'Error!',
          subtitle: errorMessage,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  // eslint-disable-next-line
  const spots = 1;
  return (
    <GameCard>
      <img
        src={gameData?.banner || assets.benjiBanana}
        className='banner'
        alt=''
      />
      <div className='row'>
        <p className='font-size-14'>{gameData?.name || 'Riot Racers'}</p>
        <ClickAwayListener onClickAway={() => setOpenMenu(false)}>
          <Menu>
            <img
              src={assets.moreVert}
              className='menu-icon'
              onClick={() => setOpenMenu(!openMenu)}
              alt=''
            />
            {openMenu && (
              <div className='menu-popup'>
                <div className='menu-item' onClick={handleEditgame}>
                  <p className='font-size-12'>Edit Game</p>
                </div>
                <div className='menu-item'>
                  <p className='font-size-12'>Delete Game</p>
                </div>
                <div className='menu-item' onClick={handleCopyPublicAddress}>
                  <p className='font-size-12'>Copy Public Address</p>
                </div>
                {gameData?.name === 'Benji Bananas' && (
                  <div
                    className='menu-item'
                    style={{
                      cursor: loading ? 'not-allowed' : 'default',
                      pointerEvents: loading ? 'none' : 'unset',
                    }}
                    onClick={handleClaimRewards}
                  >
                    <p className='font-size-12'>Claim Rewards</p>
                  </div>
                )}
              </div>
            )}
          </Menu>
        </ClickAwayListener>
      </div>
      {/* {spots === 1 && (
        <SpotCount
          style={{
            backgroundColor:
              spots > 3 ? theme.palette.primary : theme.palette.secondary,
          }}
        >
          <p className='font-size-14' style={{ fontFamily: 'Stalinist One' }}>
            {spots}
          </p>
          <p className='font-size-8'>
            {spots > 3
              ? 'Spots left'
              : spots === 1
              ? 'Spot left!'
              : 'Spots left!'}
          </p>
        </SpotCount> */}
      {/* )} */}
    </GameCard>
  );
};

export default Game;

const GameCard = styled.div`
  width: 100%;
  position: relative;
  padding-top: 8px;
  cursor: pointer;
  & img.banner {
    aspect-ratio: 16/9;
    width: 100%;
  }

  & .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    padding-inline: 10px;
  }
  transition: 0.6s ease;
`;

// const SpotCount = styled.div`
//   position: absolute;
//   top: 0px;
//   right: 8px;
//   padding: 10px 0px;
//   min-width: 80px;
//   height: 48px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;

//   @media only screen and (max-width: 420px) {
//     height: 35px;
//     min-width: 60px;
//     & p.font-size-14 {
//       font-size: 10px;
//       line-height: 14px;
//     }
//   }
// `;

const Menu = styled.div`
  position: relative;

  & .menu-icon {
    cursor: pointer;
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }
  }

  & .menu-popup {
    position: absolute;
    width: 161px;
    background-color: #121212;
    padding-block: 4px;
    right: 0px;
    top: 0px;
    transform: translate(-5%, -102%);

    & .menu-item {
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      & p {
        opacity: 0.4;
        width: 100%;
        padding-inline: 16px;
      }
      &:hover {
        cursor: pointer;
        background-color: #000000;
        & p {
          opacity: 1;
        }
      }
    }
  }
`;
