import React, { ChangeEvent, FC, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';

import LoginLayout from '../../layouts/LoginLayout';
import Button from '../../components/Button/Button';
import InputField from '../../components/InputField/InputField';

const ForgotPassword: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const validate = () => {
    if (email.trim() === '') {
      setError('Email is required.');
      return false;
      // eslint-disable-next-line no-useless-escape
    } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      setError('Please enter a valid email.');
      return false;
    }
    return true;
  };

  const handleClick = async () => {
    setError('');
    if (validate()) {
      dispatch.auth.forgotPassword({ email, setError, setValidated });
    }
  };
  return (
    <LoginLayout>
      <Container>
        {validated ? (
          <>
            <div className='flex-1'>
              <h1 className='title'>Email sent!</h1>
              <p className='font-size-14 mb'>
                Please check your email and renew your password.
              </p>
            </div>
            <Button
              variant='primary'
              onClick={() => navigate('/reset-password')}
            >
              Continue
            </Button>
          </>
        ) : (
          <>
            <div className='flex-1'>
              <h1 className='title'>Forgot/Reset password</h1>
              <p className='font-size-14'>
                Please enter your email address and we’ll send a link to
                recover/change it.
              </p>
              <InputField
                placeholder='Email adress'
                className='email'
                type='email'
                error={error}
                value={email}
                onChange={handleChange}
              />
            </div>
            <Button variant='primary' onClick={handleClick}>
              Continue
            </Button>
          </>
        )}
        <div className='have-account'>
          <p className='font-size-14'>
            Having trouble?{' '}
            <Link to='#' className='anchor'>
              Contact customer care
            </Link>
          </p>
        </div>
      </Container>
    </LoginLayout>
  );
};

export default ForgotPassword;

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  & h1 {
    margin-bottom: 20px;
  }
  & .email {
    margin-block: 60px;
  }
  & .mb {
    margin-bottom: 60px;
  }
  & .have-account {
    text-align: center;
    margin-top: 40px;
  }
  @media only screen and (max-width: 700px) {
    & .flex-1 {
      flex-grow: 1;
    }
  }
`;
