import { createModel } from '@rematch/core';
import { RootModel } from '.';

interface IState {
  title: string;
  subtitle: string;
  open: boolean;
}

interface IOpenPayload {
  title: string;
  subtitle: string;
}

export const feedback = createModel<RootModel>()({
  name: 'feedback',
  state: {} as IState,
  reducers: {
    open(state, payload: IOpenPayload) {
      state.open = true;
      state.title = payload.title;
      state.subtitle = payload.subtitle;
    },
    close(state) {
      state.open = false;
      state.title = '';
      state.subtitle = '';
    },
  },
});
