import React, { ChangeEvent, FC, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { InferType, object, string, boolean } from "yup";
import { FormErrors, validateData } from "../../utils/validations";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Dispatch } from "../../store";
import LoginLayout from "../../layouts/LoginLayout";
import InputField from "../../components/InputField/InputField";
import Button from "../../components/Button/Button";
import Checkbox from "../../components/Checkbox/Checkbox";

import theme from "../../theme";

const loginSchema = object({
  email: string().required().label("Email"),
  password: string().required().label("Password"),
  remember_me: boolean().optional(),
});

interface LoginData extends InferType<typeof loginSchema> {}

const Login: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const [errors, setErrors] = useState<FormErrors<LoginData | null>>({
    email: "",
    password: "",
    remember_me: "",
  });

  const [formData, setFormData] = useState<LoginData>({
    email: "",
    password: "",
    remember_me: false,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((fd) => ({
      ...fd,
      [name]: e.target.type === "checkbox" ? e.target.checked : value,
    }));
  };

  const handleSubmit = async () => {
    setErrors(null);
    const { errors: validationErrors } = await validateData<LoginData>(
      loginSchema,
      formData
    );
    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }
    dispatch.auth.login({ formData, setErrors, navigate });
  };

  return (
    <LoginLayout>
      <Container>
        <h1 className="title">Welcome back!</h1>
        <p className="font-size-14">Please log in to your account.</p>

        <InputField
          placeholder="Email adress"
          type="email"
          className="email-field"
          name="email"
          value={formData.email}
          onChange={handleChange}
          error={errors?.email}
        />
        <InputField
          placeholder="Password"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          error={errors?.password}
          hidden
        />

        <div className="forget-password">
          <div>
            <Checkbox
              className="checkbox"
              onChange={handleChange}
              name="remember_me"
            />
            <p className="font-size-12">Remember password</p>
          </div>
          <Link className="font-size-12 anchor" to="/forgot-password">
            Forgot/Reset password?
          </Link>
        </div>

        <Button
          variant="primary"
          className="continue-button"
          // onClick={() => navigate('/dashboard')}
          onClick={handleSubmit}
        >
          Continue
        </Button>

        {/* <div className='divider'>
          <span>Or</span>
        </div>

        <div className='other-login'>
          <Button variant='normal' className='btn btn-google'>
            <img src={assets.google} alt='' />
          </Button>
          <Button variant='normal' className='btn btn-otp'>
            OTP
          </Button>
        </div> */}

        <div className="have-account">
          <p className="font-size-14">
            Having trouble? &nbsp;
            <Link to="#" className="anchor">
              Contact customer care
            </Link>
          </p>
        </div>
      </Container>
    </LoginLayout>
  );
};

export default Login;

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  & h1 {
    margin-bottom: 20px;
  }
  & .email-field {
    margin-block: 40px 38px;
  }

  & .forget-password {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & div {
      display: flex;
      & .checkbox {
        margin-top: 2px;
        margin-right: 8px;
      }
    }
  }

  & .continue-button {
    margin-block: 40px;
  }
  & .divider {
    height: 1px;
    background-color: #ffffff33;
    position: relative;
    & span {
      color: #ffffff33;
      font-size: 12px;
      line-height: 18px;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -45%);
      top: 0;
      background-color: ${theme.palette.black};
      width: 48px;
      text-align: center;
    }
  }
  & .other-login {
    margin-block: 45px 20px;
    display: flex;
    justify-content: center;
    & .btn {
      width: 90px;
    }
    & .btn-google {
      margin-right: 10px;
    }
    & .btn-otp {
      margin-left: 10px;
    }
  }
  & .have-account {
    text-align: center;
  }
  @media only screen and (max-width: 700px) {
    justify-content: flex-start;
  }
`;
