import React, { FC, useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { resizerUrl } from '../../app.config';
import { getNftImage } from '../../utils/nts';
import { ClipLoader } from 'react-spinners';
import { InView } from 'react-intersection-observer';

interface Props {
  nftData: any;
  size: number;
  container?: boolean;
  noCache?: boolean;
}

const NftImage: FC<Props> = ({ nftData, size = 60, container, noCache }) => {
  const inViewRef = useRef(false);

  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setImage('');
      setLoading(true);
      const nftImage = await getNftImage(nftData);

      if(!nftImage) return;

      if (noCache) {
        const randomString = Math.floor(Math.random() * 1000000000).toString();
        setImage(`${resizerUrl}/image?url=${nftImage}&size=${2 * size}?dummy=${randomString}`);
      } else {
        setImage(`${resizerUrl}/image?url=${nftImage}&size=${2 * size}`);
      }
    })();
    // eslint-disable-next-line
  }, [nftData]);

  return (
    <InView>
      {({ inView, ref }) => {
        if (inView) inViewRef.current = true;

        return (
          <div ref={ref}>
            <ImageWrapper
              data-tip={`${nftData?.name} #${nftData?.token_id}`}
              style={{
                height: container ? '100%' : size,
                width: container ? '100%' : size,
              }}
            >
              <ClipLoader color='white' size={20} speedMultiplier={0.5} loading={loading} />
              {inViewRef.current && (
                <img
                  style={{
                    display: loading ? 'none' : 'block',
                    height: container ? '100%' : '',
                    objectFit: container ? 'cover' : 'initial',
                  }}
                  src={image ? image : ''}
                  alt={`${nftData?.name} #${nftData?.token_id}`}
                  onLoad={() => setLoading(false)}
                />
              )}
            </ImageWrapper>
            <ReactTooltip effect='float' type='dark' place='top' />
          </div>
        );
      }}
    </InView>
  );
};

export default NftImage;

const ImageWrapper = styled.div`
  margin-top: 1px;
  border-radius: 6px;
  display: grid;
  place-items: center;
  & img {
    width: 100%;
  }
`;
