import React, { FC, useRef } from 'react';

import styled from 'styled-components';

import assets from '../../assets';

interface ISearch {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IProps {
  children: React.ReactNode;
  searchProps?: ISearch;
  noMargin?: boolean;
}

const Filters: FC<IProps> = ({ children, searchProps, noMargin }) => {
  const panel = useRef<HTMLDivElement>(null);
  const icon = useRef<HTMLImageElement>(null);

  const handleClick = () => {
    if (icon.current) {
      icon.current.classList.toggle('rotate');
    }
    if (panel.current) {
      if (panel.current.style.maxHeight) {
        panel.current.style.maxHeight = '';
      } else {
        panel.current.style.maxHeight = panel.current.scrollHeight + 'px';
      }
    }
  };

  return (
    <>
      <Container
        className='filters-and-search'
        style={{ marginTop: noMargin ? '0px' : '40px' }}
      >
        <div className='row'>
          <div className='filter' onClick={handleClick}>
            <p className='font-size-12'>Filters</p>
            <img src={assets.downArrow} ref={icon} alt='' />
          </div>
          <div className='search-bar'>
            <img src={assets.searchIcon} alt='' />

            <input
              type='text'
              className='font-size-12 search-field'
              placeholder='Search'
              {...searchProps}
            />
          </div>
        </div>
      </Container>
      <Panel className='collapse' ref={panel}>
        <div className='container'>{children}</div>
      </Panel>
    </>
  );
};

export default Filters;

const Container = styled.div`
  margin-block: 40px 0px;
  & .filter {
    background-color: #121212;
    padding: 10px 14px;
    display: flex;
    gap: 15px;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 2px 2px 0px 0px;
    cursor: pointer;
    img {
      transition: 0.4s ease;
    }
    img.rotate {
      transform: rotate(180deg);
    }
  }
  & .search-bar {
    background-color: #121212;
    width: 310px;
    padding: 11px 14px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    img {
      opacity: 0.6;
    }
    input {
      background-color: transparent;
      border: none;
      outline: none;
    }
  }
  & .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const Panel = styled.div`
  max-height: 0px;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
  & .container {
    border-bottom: 1px solid #ffffff33;
    margin-block: 30px 0px;
    padding-bottom: 24px;
  }
`;
