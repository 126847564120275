import { createModel } from '@rematch/core';
import { RootModel } from '.';

import { getAllNfts, getScholarsByGameId } from '../../http';
import { IScholarshipUser, IVaultNft } from '../../types/interfaces';

interface IState {
  loading: boolean;
  nfts: Array<IVaultNft>;
  scholarLoading: boolean;
  scholars: IScholarshipUser[];
  selectedScholar: IScholarshipUser | null;
}

interface IGetNftPayload {
  data: {
    contract_id: string;
  };
  cursor?: string;
  setCursor: React.Dispatch<React.SetStateAction<string | null>>;
}

export const vault = createModel<RootModel>()({
  name: 'vault',
  state: {
    loading: false,
    nfts: [],
    scholarLoading: false,
    scholars: [],
    selectedScholar: null,
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setNfts(state, payload: Array<IVaultNft>) {
      state.nfts = payload;
    },
    setScholarLoading(state, payload: boolean) {
      state.scholarLoading = payload;
    },
    setScholars(state, payload: IScholarshipUser[]) {
      state.scholars = payload;
    },
    setSelectedScholar(state, payload: IScholarshipUser) {
      state.selectedScholar = payload;
    },
  },
  effects: dispatch => ({
    async getNfts(payload: IGetNftPayload) {
      try {
        dispatch.vault.setLoading(true);
        dispatch.vault.setNfts([])
        const { data } = await getAllNfts(payload.data, payload?.cursor);
        payload.setCursor(data.cursor);
        dispatch.vault.setNfts(data.result);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.vault.setLoading(false);
      }
    },
    async getScholarShipScholars(payload: string) {
      try {
        dispatch.vault.setScholarLoading(true);
        const { data } = await getScholarsByGameId(payload);
        dispatch.vault.setScholars(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.vault.setScholarLoading(false);
      }
    },
  }),
});
