import React, { FC, useMemo } from 'react';

import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import assets from '../../assets';

interface IStyles {
  [x: string]: string;
}
interface IProps {
  image: File | string | null;
  setImage: React.Dispatch<React.SetStateAction<File | null | string>>;
  placeholder: string;
  customBaseStyles?: IStyles;
  customActiveStyles?: IStyles;
  customRejectStyles?: IStyles;
  height?: string;
  error?: string;
}

const ImagePicker: FC<IProps> = ({
  image,
  setImage,
  placeholder,
  height,
  customBaseStyles,
  customActiveStyles,
  customRejectStyles,
  error,
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    noClick: true,
    accept: {
      image: ['.png', '.jpg', '.jpeg'],
    },

    maxFiles: 1,
    maxSize: 2097152,

    onDrop: acceptedFiles => {
      const file = acceptedFiles[0];
      let extension = file.name.split('.');
      const exten = extension[extension.length - 1];
      if (exten === 'png' || exten === 'jpg' || exten === 'jpeg') {
        setImage(acceptedFiles[0]);
      }
    },
  });

  const baseStyle = {
    height: '100%',
    width: '100%',
    border: '1px dashed #ffffff66',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    paddingInline: '24px',
    borderRadius: '4px',
    ...customBaseStyles,
  };

  const activeStyle = {
    border: '1px solid #ffffff',
    ...customActiveStyles,
  };

  const acceptStyle = {
    borderColor: '#00e676',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
    customRejectStyles,
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive
        ? activeStyle
        : { border: image ? '1px solid #ffffff' : '1px dashed #ffffff' }),

      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDragActive, isDragReject, isDragAccept, image]
  );

  return (
    <Container
      // @ts-ignore
      height={height}
    >
      <div
        {...getRootProps({
          style: style,
        })}
        className='root'
      >
        <input {...getInputProps()} />
        <div className='flex-container'>
          <img src={assets.cloudUp} alt='' />
          <p className='font-size-10' style={{ opacity: '0.4' }}>
            {placeholder}
          </p>
          {image && typeof image !== 'string' ? (
            <div className='row'>
              <p className='font-size-12'>{(image as any)?.name}</p>
              <img
                src={assets.kill}
                style={{ cursor: 'pointer' }}
                alt=''
                onClick={() => setImage(null)}
              />
            </div>
          ) : (
            <button className='font-size-12 btn' onClick={open}>
              upload
            </button>
          )}
        </div>
        <div className='preview'>
          {image && typeof image === 'string' && <img src={image} alt='' />}
          {image && typeof image !== 'string' && (
            <img src={URL.createObjectURL(image)} alt='' />
          )}
        </div>
      </div>
      {error && (
        <p className='warning' style={{ marginTop: '4px' }}>
          {error}
        </p>
      )}
    </Container>
  );
};

export default ImagePicker;

const Container = styled.div`
  height: ${
    //  @ts-ignore
    props => props?.height || '160px'
  };

  & .root {
    position: relative;
    & .preview {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      z-index: -1;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  & .flex-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & p {
      margin-block: 8px 8px;
      max-width: 30ch;
      text-align: center;
    }
    & button {
      background-color: #ffffff1a;
      border: none;
      padding: 4px 20px;
      border-radius: 2px;
      cursor: pointer;
    }
  }
  & .row {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-inline: 10px;
    justify-content: center;
    flex-wrap: wrap;
  }
`;
