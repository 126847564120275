import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import classNames from 'classnames';
import ClickAwayListener from 'react-click-away-listener';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../store';

import EthAddress from '../components/EthAddress/EthAddress';
import DialogLayout from './Dialog';
import TabPanel from '../components/TabPanel/TabPanel';
import InputField from '../components/InputField/InputField';
import Select from '../components/Select/Select';
import Button from '../components/Button/Button';
import Loader from '../components/Loader/Loader';
import { toast } from 'react-toastify';
// import NftImage from '../components/NftImage/NftImage';

import assets from '../assets';
import theme from '../theme';
// import nfts from '../assets/nfts';

import { truncateString, getFormatedTime } from '../utils';
import { adminTimeline, updateScholarPercentage } from '../http';
import { IAdminTimeline } from '../types/interfaces';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PayoutHistoryDialog: FC<IProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch<Dispatch>();
  const [tab, setTab] = useState(0);

  const scholar = useSelector((state: RootState) => state.scholars.selected);
  const games = useSelector(
    (state: RootState) => state.scholars.selectedScholarGames
  );
  const nfts = useSelector(
    (state: RootState) => state.scholars.selectedScholarNfts
  );

  const [userInfo, setUserInfo] = useState({
    fullName: scholar?.fullname || '',
    revenueSplit: scholar?.scholar_percentage || 0,
    email: scholar?.email || '',
    phoneNumber: scholar?.phone_number || '',
    scholarWallet: scholar?.starz_wallet_address || '',
    payoutWallet: scholar?.wallet_address || '',
  });

  const [payoutHistory, setPayoutHistory] = useState<IAdminTimeline[]>([]);
  const [loading, setLoading] = useState(false);

  const handleTab = (val: number) => {
    setTab(val);
  };

  const handleCopyText = (val: string) => {
    navigator.clipboard.writeText(val);
    toast('Copied successfuly');
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setUserInfo(prev => ({ ...prev, [name]: value }));
  };

  const handleUpdateUserDetails = async () => {
    try {
      if (scholar && userInfo.revenueSplit) {
        console.log(userInfo.revenueSplit);
        await updateScholarPercentage(scholar.id, {
          percentage: userInfo.revenueSplit,
        });
        setOpen(false);
        dispatch.scholars.getScholars();
      }
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const getPayoutHistory = async () => {
    try {
      setLoading(true);
      const { data } = await adminTimeline(
        `action=Payout&userId=${scholar!.id}`
      );
      setPayoutHistory(data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (scholar) {
      dispatch.scholars.resetSelectedScholarGames();
      dispatch.scholars.resetSelectedScholarNfts();
      dispatch.scholars.getScholarAssets(scholar.starz_wallet_address);
      dispatch.scholars.getScholarGames(scholar.id);
      getPayoutHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DialogLayout open={open} width={'100%'}>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Container>
          <div className='close-icon-container'>
            <img src={assets.closeIcon} alt='' onClick={() => setOpen(false)} />
          </div>
          <div className='content'>
            <Grid>
              <div className='left'>
                <div className='profile'>
                  <div className='row '>
                    <img src={assets.user} className='profile-image' alt='' />
                    <div>
                      <p className='font-size-14'>{scholar?.fullname}</p>
                      <div className='row-inner'>
                        {scholar?.wallet_address && (
                          <>
                            <p className='font-size-10 tag'>
                              {truncateString(scholar.wallet_address)}
                            </p>
                            <img
                              src={assets.copyIcon}
                              alt=''
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                handleCopyText(scholar.wallet_address)
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='divider'></div>
                <div className='games'>
                  <p className='font-size-14 title'>Games</p>
                  <div className='games-grid'>
                    {games?.map(game => (
                      <div className='game-img' key={game.id}>
                        <img src={game.mini_icon || assets.noImage} alt='' />
                      </div>
                    ))}
                  </div>
                </div>
                <div className='divider'></div>
                <div className='assets'>
                  <p className='font-size-12 title'>Assets</p>
                  <div className='assets-grid'>
                    {nfts?.map((nft, index) => (
                      // <NftImage nftData={nft} size={36} key={index} />
                      <div className='asset-img' key={index}>
                        <img
                          src={
                            nft.metadata?.image ||
                            nft.metadata?.image_url ||
                            assets.noImage
                          }
                          alt=''
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className='right'>
                <div className='row tabs'>
                  <p
                    className={classNames(
                      'font-size-14 title',
                      tab === 0 && 'active'
                    )}
                    onClick={() => handleTab(0)}
                  >
                    About
                  </p>
                  <p
                    className={classNames(
                      'font-size-14 title',
                      tab === 1 && 'active'
                    )}
                    onClick={() => handleTab(1)}
                  >
                    Payout History
                  </p>
                </div>
                <div className='divider'></div>

                <TabPanel index={0} currentTab={tab}>
                  <div className='about-section'>
                    <div className='about-item'>
                      <InputField
                        placeholder='Full Name'
                        value={userInfo.fullName}
                        type='text'
                        name='fullName'
                        readOnly
                        onChange={handleChange}
                      />
                    </div>
                    <div className='about-item'>
                      <Select
                        title='Revenue Split'
                        name='revenueSplit'
                        value={userInfo.revenueSplit}
                        onChange={handleChange}
                        options={[
                          { label: '30/70', value: 30 },
                          { label: '40/70', value: 40 },
                          { label: '50/50', value: 50 },
                        ]}
                      />
                    </div>
                    <div className='about-item'>
                      <InputField
                        placeholder='Email Address'
                        value={userInfo.email}
                        type='email'
                        name='email'
                        onChange={handleChange}
                        readOnly
                      />
                    </div>
                    <div className='about-item'>
                      <InputField
                        placeholder='Phone Number'
                        value={userInfo.phoneNumber}
                        type='tel'
                        name='phoneNumber'
                        onChange={handleChange}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className='divider'></div>
                  <div className='wallet-address'>
                    <div className='item'>
                      <InputField
                        placeholder='Scholar Wallet Address'
                        type='text'
                        value={userInfo.scholarWallet}
                        readOnly
                        name='scholarWallet'
                        onChange={handleChange}
                      />
                    </div>
                    <div className='item'>
                      <InputField
                        placeholder='Payout Wallet Address'
                        type='text'
                        value={userInfo.payoutWallet}
                        readOnly
                        name='payoutWallet'
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className='text-center '>
                    <Button variant='secondary' onClick={() => setOpen(false)}>
                      Cancel
                    </Button>
                    <Button variant='primary' onClick={handleUpdateUserDetails}>
                      Confirm
                    </Button>
                    z
                  </div>
                </TabPanel>
                <TabPanel index={1} currentTab={tab}>
                  <div className='table-container'>
                    {loading ? (
                      <Loader />
                    ) : (
                      <table>
                        <thead>
                          <tr className='header-row'>
                            <th>Scholar Wallet Address</th>
                            <th>Payout Wallet Address</th>
                            <th>Payout</th>
                            <th>Payout Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payoutHistory.map((timeline, index) => (
                            <>
                              <tr>
                                <td>
                                  <EthAddress
                                    address={
                                      timeline.user.account.starz_wallet_address
                                    }
                                  />
                                </td>
                                <td>
                                  <EthAddress
                                    address={
                                      timeline.user.account.wallet_address
                                    }
                                  />
                                </td>
                                <td>
                                  <div className='row'>
                                    <img src={assets.stzIcon} alt='' />
                                    {typeof timeline.amount_distribution ===
                                      'string' && (
                                      <>
                                        {
                                          JSON.parse(
                                            timeline.amount_distribution
                                          )?.user
                                        }{' '}
                                        {
                                          JSON.parse(
                                            timeline.amount_distribution
                                          )?.symbol
                                        }
                                      </>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  {getFormatedTime(timeline.user_created_at)}
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </TabPanel>
              </div>
            </Grid>
          </div>
        </Container>
      </ClickAwayListener>
    </DialogLayout>
  );
};

export default PayoutHistoryDialog;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1080px;
  margin-inline: auto;
  & .close-icon-container {
    text-align: right;
    padding: 6px 0px;
    & img {
      cursor: pointer;
    }
  }
  & .content {
    width: 100%;
    padding: 40px 30px;
    background-color: #121212;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
  }

  @media only screen and (max-width: 756px) {
    & .content {
      padding: 20px 10px;
    }
  }
`;

const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  & .left {
    flex: 0 0 calc(28% - 30px);
    & .profile {
      padding-inline: 10px;
      margin-bottom: 30px;
      & img.profile-image {
        height: 60px;
        width: 60px;
        object-fit: cover;
      }
      & .row-inner {
        margin-top: 4px;
        display: flex;
        align-items: center;
        gap: 6px;

        & p.tag {
          padding: 2px;
          background-color: #ffffff1a;
          border-radius: 2px;
        }
      }
    }
    & p.title {
      margin-top: 20px;
    }
    & .games {
      padding-inline: 10px;
      & .games-grid {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
        padding-block: 14px 20px;

        & .game-img {
          height: 36px;
          width: 36px;
          background-color: #ffffff1a;
          border-radius: 8px;
          display: grid;
          place-content: center;
        }
      }
    }
    & .assets {
      padding-inline: 10px;
      & .assets-grid {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
        padding-block: 14px 20px;
        & .asset-img {
          height: 30px;
          width: 30px;
          border-radius: 6px;
          overflow: hidden;
          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
  & .right {
    flex: 0 0 calc(72% - 30px);
    & .tabs {
      gap: 40px;
      padding-inline: 10px;

      & p {
        display: inline-block;
        position: relative;
        margin-bottom: 14px;
        cursor: pointer;
      }

      & p::before {
        content: '';
        height: 4px;
        width: 0px;
        left: 0;
        bottom: -19px;
        position: absolute;
        background-color: ${theme.palette.primary};
        clip-path: polygon(
          100% 0,
          100% calc(100% - 4px),
          calc(100% - 4px) 100%,
          0 100%,
          0 0
        );
        transition: 0.4s ease;
      }
      & p.active::before {
        width: 100%;
      }
    }
    & .about-section {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 34px 20px;
      padding-block: 40px 50px;
      padding-inline: 30px;
    }
    & .wallet-address {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 34px 20px;
      padding-block: 30px 50px;
      padding-inline: 30px;
    }
    & .text-center {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
    & .table-container {
      padding-block: 44px;
      padding-inline: 30px 0px;
      overflow: auto;
      max-height: 440px;
      & table {
        border-collapse: collapse;
        width: 100%;
        min-width: 600px;
        & .header-row {
          height: 40px;
          & th {
            font-size: 10px;
            line-height: 16px;
            font-weight: 600;
            opacity: 0.6;
            padding-top: 4px;
            border-bottom: 1px solid #ffffff33;
            text-align: left;
            &:first-child {
              padding-left: 10px;
            }
            &:last-child {
              padding-right: 10px;
            }

            & img {
              width: 8px;
            }

            & .row {
              display: flex;
              align-items: center;
              gap: 4px;
              justify-content: flex-start;
            }

            & .col {
              display: flex;
              flex-direction: column;
              gap: 3px;
            }
          }
        }
        & tr {
          height: 60px;
          padding-inline: 10px;
          & td {
            &:first-child {
              padding-left: 10px;
            }
            &:last-child {
              padding-right: 10px;
            }
          }
        }
        & td {
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
          border-bottom: 1px solid #ffffff33;
          & .row {
            gap: 4px;
            & img {
              height: 16px;
              width: 16px;
            }
          }
        }
      }
    }
  }
  & .row {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  & .divider {
    border-top: 1px solid #ffffff33;
  }
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 30px;
    & .left {
      flex: 0 0 100%;
    }
    & .right {
      flex: 0 0 100%;
    }
  }

  @media only screen and (max-width: 756px) {
    & .right {
      & .wallet-address {
        grid-template-columns: 1fr;
        padding: 30px 15px;
        gap: 20px;
      }
      & .about-section {
        grid-template-columns: 1fr;
        padding: 30px 15px;
        gap: 20px;
      }
      & .tabs {
        gap: 20px;
        & p {
          font-size: 14px;
        }
      }
    }
  }
`;
