import React, { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../store';

import EthAddress from '../components/EthAddress/EthAddress';

import { badgeImagesByName } from '../app.config';
import { ClipLoader } from 'react-spinners';

import { IMintedBadge, IScholarshipUser } from '../types/interfaces';
import { truncateString, showToast } from '../utils';
import assets from '../assets';
import { getUserMintedBadges } from '../http';
import theme from '../theme';

interface IProps {
  scholar: IScholarshipUser;
  setSelectedUser: React.Dispatch<
    React.SetStateAction<IScholarshipUser | null>
  >;
  selectedUser: IScholarshipUser;
}

const TransferAssetRow: FC<IProps> = ({
  scholar,
  setSelectedUser,
  selectedUser,
}) => {
  const [loading, setLoading] = useState(false);
  const [highestBadge, setHighestBadge] = useState<IMintedBadge | null>(null);

  const dispatch = useDispatch<Dispatch>();

  const handleCopyAddress = (address: string) => {
    navigator.clipboard.writeText(address);
    showToast('success', 'Address copied successfuly!');
  };

  const handleGetScholarGames = async () => {
    try {
      setLoading(true);
      const { data } = await getUserMintedBadges(scholar.id);

      const mintBadges: IMintedBadge[] = data;
      let highest = mintBadges[0];
      mintBadges.forEach(b => {
        if (b.badge_type > highest.badge_type) {
          highest = b;
        }
      });
      setHighestBadge(highest);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetScholarGames();
    // eslint-disable-next-line
  }, []);

  return (
    <tr
      key={scholar?.id}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setSelectedUser(scholar);
        dispatch.vault.setSelectedScholar(scholar);
      }}
      className={classNames(selectedUser?.id === scholar?.id && 'active')}
    >
      <td>
        <div className='row'>
          <img
            src={scholar?.image_url || assets.user}
            style={{
              height: '30px',
              width: '30px',
              objectFit: 'cover',
              borderRadius: '50%',
            }}
            alt=''
          />
          <p className='font-size-12'>{scholar?.nick_name}</p>
        </div>
      </td>
      <td>
        <EthAddress
          address={scholar?.email}
          overlyStyle={{
            background:
              'linear-gradient(270deg, #121212 0%, rgba(18, 18, 180) 100%)!important',
          }}
        />
      </td>
      <td>
        {loading ? (
          <ClipLoader size={10} color={theme.palette.primary} />
        ) : (
          <div className='row'>
            {highestBadge && (
              <img
                src={badgeImagesByName[highestBadge?.name]}
                alt=''
                style={{ maxWidth: '30px' }}
              />
            )}
            <p className='font-size-12'>{highestBadge?.name}</p>
          </div>
        )}
      </td>
      <td>
        <div className='row'>
          <p className='font-size-12 address'>
            {scholar?.wallet_address && truncateString(scholar?.wallet_address)}
          </p>
          <img
            src={assets.copyIcon}
            style={{ cursor: 'pointer' }}
            alt=''
            onClick={() => handleCopyAddress(scholar?.wallet_address)}
          />
        </div>
      </td>
    </tr>
  );
};

export default TransferAssetRow;
