import React, { FC } from 'react';

import { Row } from 'react-table';

import EthAddress from '../../components/EthAddress/EthAddress';
import Checkbox from '../../components/Checkbox/Checkbox';

import { IScholarUser } from '../../types/interfaces';

import assets from '../../assets';

interface IProps {
  row: Row<IScholarUser>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
}

const MassPayoutRow: FC<IProps> = ({ row, onChange }) => {
  return (
    <tr {...row.getRowProps()}>
      <td style={{ paddingInline: '10px' }}>
        <Checkbox
          checked={row.original.selected}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(e, row.original.id)
          }
        />
      </td>
      <td>
        <div className='row flex-start'>
          <img
            src={row.original.image_url ? row.original.image_url : assets.user}
            className='user-img'
            alt=''
            style={{ borderRadius: '50%' }}
          />
          <p className='font-size-12'>{row.original.nick_name}</p>
        </div>
      </td>
      <td>{row.original.email}</td>

      <td>
        <EthAddress address={row.original.starz_wallet_address} />
      </td>
      <td>
        {row.original.scholar_percentage
          ? `${row.original.scholar_percentage}/${
              100 - row.original?.scholar_percentage
            }`
          : '-/-'}
      </td>
      <td>
        <div className='row flex-start'>
          <input
            type='number'
            value={row.original?.payable}
            onChange={e => onChange(e, row.original.id)}
            id=''
            className='amount-input'
            placeholder='Amount (STZ)'
          />
        </div>
      </td>
      <td>
        <div className='row flex-start'>
          <img src={assets.stzIcon} className='stz-icon' alt='' />
          <p className='font-size-12'>{row.original?.total}</p>
        </div>
      </td>
    </tr>
  );
};

export default MassPayoutRow;
