import { createModel } from '@rematch/core';
import { RootModel } from '.';
import {
  IWalletAccount,
  IAuthModal,
  ILoggedInUser,
  Ierror,
} from '../../types/interfaces';
import {
  signup,
  emailVerification,
  login as loginApi,
  forgotPassword as forgotPasswordApi,
  recoverPassword,
  getUserInfo,
  connectWallet as connectWalletApi,
  getWalletAccounts,
} from '../../http';
import axios from 'axios';

export const auth = createModel<RootModel>()({
  name: 'auth',
  state: {
    isLoggedIn: !!localStorage.getItem('token'),
    user: null,
    varificatonToken: null,
    loading: false,
    walletAddress: '',
    walletAccounts: [],
  } as IAuthModal,
  reducers: {
    setIsLoggedIn(state, payload: boolean) {
      state.isLoggedIn = payload;
    },
    setVerificationToken(state, payload: string) {
      state.varificatonToken = payload;
    },

    setLoading(state, payload: boolean) {
      state.loading = payload;
    },

    setUser(state, payload: ILoggedInUser) {
      state.user = payload;
    },
    logOut(state) {
      state.isLoggedIn = false;
      state.user = null;
      state.varificatonToken = null;
      state.walletAccounts = [];
      state.walletAddress = '';
      localStorage.clear();
    },
    setWalletAddress(state, payload: string) {
      state.walletAddress = payload;
    },
    setAccounts(state, payload: Array<IWalletAccount>) {
      state.walletAccounts = payload;
    },
  },
  effects: dispatch => ({
    async register(payload) {
      dispatch.auth.setLoading(true);
      try {
        const { data } = await signup(payload.data);
        dispatch.auth.setVerificationToken(data.token);
        payload.navigate('/account-confirmation');
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage: string = (err as any).response?.data.message;
          //   setting errors
          payload.setErrors({
            ...payload.errors,
            email: '',
            confirm_passoword: '',
          });
          if (errorMessage === 'Email already taken.') {
            payload.setErrors({
              ...payload.errors,
              email: 'Email already taken.',
            });
          }
          if (errorMessage.includes('confirm_password')) {
            payload.setErrors({
              ...payload.errors,
              confirm_password: 'Repeat password should be same as password',
            });
          }
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },

    async confirmEmail({ code, token, setErrors, setValidated }) {
      dispatch.auth.setLoading(true);
      try {
        await emailVerification(code, token);
        setValidated(true);
      } catch (err) {
        setErrors({ code: '' });
        if (axios.isAxiosError(err)) {
          setErrors({ code: 'Invalid code.' });
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },

    async login({ formData, setErrors }) {
      dispatch.auth.setLoading(true);
      try {
        const { data } = await loginApi({
          email: formData.email,
          password: formData.password,
        });
        const { accessToken, ...user } = data;

        if (user?.role.toLowerCase() !== 'admin') {
          setErrors({
            email: 'Invalid credentails.',
            password: 'Invalid credentails.',
          });
          return;
        }
        dispatch.auth.setUser(user);

        dispatch.auth.setIsLoggedIn(true);
        localStorage.setItem('token', accessToken);

        const { data: response }: { data: Array<IWalletAccount> } =
          await getWalletAccounts();
        dispatch.auth.setAccounts(response);

        if (response.length) {
          dispatch.auth.setWalletAddress(response[0].wallet_address);
        }
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage: string = (err as any).response?.data.message;
          setErrors({ email: errorMessage, password: errorMessage });
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },

    async forgotPassword({ email, setError, setValidated }) {
      dispatch.auth.setLoading(true);
      try {
        const { data } = await forgotPasswordApi(email);
        dispatch.auth.setVerificationToken(data.token);
        setValidated(true);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage: string = (err as any).response?.data.message;
          setError(errorMessage);
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },

    async resetPassowrd({ token, data: formData, setValidated, setErrors }) {
      dispatch.auth.setLoading(true);
      try {
        await recoverPassword(formData, token);
        setValidated(true);
      } catch (err) {
        const errors: Ierror = {};
        if (axios.isAxiosError(err)) {
          const errorMessage: string = (err as any).response?.data.message;
          if (errorMessage === 'Invalid code.') {
            errors.cod = errorMessage;
          }
          console.log(errorMessage);
          if (errorMessage.includes('confirm_password')) {
            errors.confirm_passoword =
              'Repeat password is not same as new password';
          }
          setErrors(errors);
        }
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async getUser() {
      dispatch.auth.setLoading(true);
      try {
        const { data } = await getUserInfo();
        dispatch.auth.setUser(data);
        const { data: response }: { data: Array<IWalletAccount> } =
          await getWalletAccounts();
        dispatch.auth.setAccounts(response);

        if (response.length) {
          dispatch.auth.setWalletAddress(response[0].wallet_address);
        }
      } catch (err) {
      } finally {
        dispatch.auth.setLoading(false);
      }
    },
    async connectWallet({ public_address }) {
      try {
        dispatch.auth.setWalletAddress(public_address);
        await connectWalletApi(public_address);
        const { data } = await getWalletAccounts();
        dispatch.auth.setAccounts(data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage: string = (err as any).response?.data.message;
          if (errorMessage === 'Wallet already connected.') {
            try {
              const { data } = await getWalletAccounts();
              dispatch.auth.setAccounts(data);
            } catch (err) {
              console.log((err as any).message);
            }
          }
        }
      }
    },
  }),
});
