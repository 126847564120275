import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { getGamesWithContracts, getGames } from '../../http';
import {
  IGameModal,
  IGame,
  IGameWithContracts,
  IFilterGame,
} from '../../types/interfaces';

import axios from 'axios';

export const games = createModel<RootModel>()({
  name: 'games',
  state: {
    selected: null,
    games: [],
    loading: false,
    error: '',
    gamesWithContracts: [],
    goldBadgeGames: [],
    silverBadgeGames: [],
    bronzeBadgeGames: [],
    newGames: [],
    editGame: null,
  } as IGameModal,
  reducers: {
    setGames(state, payload: Array<IGame>) {
      state.games = payload;
    },
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setError(state, payload: string) {
      state.error = payload;
    },
    setSelectedGame(state, payload: IGame) {
      state.selected = payload;
    },
    setGamesWithContracts(state, payload: Array<IGameWithContracts>) {
      state.gamesWithContracts = payload;
    },
    setGoldBadgeGames(state, payload: Array<IFilterGame>) {
      state.goldBadgeGames = payload;
    },
    setSilverBadgeGames(state, payload: Array<IFilterGame>) {
      state.silverBadgeGames = payload;
    },
    setBronzeBadgeGames(state, payload: Array<IFilterGame>) {
      state.bronzeBadgeGames = payload;
    },
    setNewGames(state, payload: Array<IFilterGame>) {
      state.newGames = payload;
    },
    setEditGame(state, payload: IFilterGame | null) {
      state.editGame = payload;
    },
  },
  effects: dispatch => ({
    async getAllGames() {
      try {
        dispatch.games.setLoading(true);
        const { data } = await getGames();
        dispatch.games.setGames(data);
      } catch (err) {
        dispatch.games.setError('Something went wrong!');
      } finally {
        dispatch.games.setLoading(false);
      }
    },

    async gamesWithContracts() {
      try {
        dispatch.games.setLoading(true);
        const { data } = await getGamesWithContracts();
        dispatch.games.setGamesWithContracts(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.games.setLoading(false);
      }
    },

    async getAllGamesCategory() {
      try {
        dispatch.games.setLoading(true);
        axios
          .all([getGames('2'), getGames('1'), getGames('0'), getGames('new')])
          .then(
            axios.spread((res1, res2, res3, res4) => {
              dispatch.games.setGoldBadgeGames(res1.data);
              dispatch.games.setSilverBadgeGames(res2.data);
              dispatch.games.setBronzeBadgeGames(res3.data);
              dispatch.games.setNewGames(res4.data);
            })
          )
          .catch(err => console.log(err.message));
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.games.setLoading(false);
      }
    },
    async editGame(payload: string) {
      try {
        dispatch.games.setLoading(true);
        // const {data} = await game
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.games.setLoading(false);
      }
    },
  }),
});
