/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useMemo, useEffect } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';

import { useTable, useSortBy } from 'react-table';
import Filters from '../../components/Filters/Filters';
import Page from '../../layouts/Page';
import Button from '../../components/Button/Button';
import Select from '../../components/Select/Select';
import MassPayoutDialog from '../../dialogs/MassPayoutDialog';
import SendFungibleTokens from '../../dialogs/SendFungibleTokens';
import Loader from '../../components/Loader/Loader';
import EarningsRow from './EarningsRow';

import assets from '../../assets';
import { IScholarUser } from '../../types/interfaces';
import { benjiPerformance, getBalanceInGame } from '../../http';
import Checkbox from '../../components/Checkbox/Checkbox';
import Captains from './Captains/Captains';

const tableColumns = [
  {
    Header: 'User',
    accessor: 'username',
    disableSortBy: true,
  },
  {
    Header: 'Email Address',
    accessor: 'email',
    disableSortBy: true,
  },
  {
    Header: 'Wallet Address',
    accessor: 'walletAddress',
    disableSortBy: true,
  },
  {
    Header: 'Scholar Wallet Address',
    accessor: 'subWalletAddress',
    disableSortBy: true,
  },
  {
    Header: 'Revenue Split',
    accessor: 'revinueSplit',
    disableSortBy: true,
  },
  {
    Header: 'Payout amount',
    accessor: 'weeklyEarnings',
    disableSortBy: true,
  },
  {
    Header: 'Total Earnings',
    accessor: 'totalEarning',
  },
  {
    Header: '',
    accessor: 'none',
    disableSortBy: true,
  },
];

const Earnings: FC = () => {
  const dispatch = useDispatch<Dispatch>();

  const loading = useSelector((state: RootState) => state.scholars.loading);
  const games = useSelector((state: RootState) => state.games.games);
  const columns = useMemo(() => tableColumns, []);
  const [data, setData] = useState<Array<IScholarUser>>([]);
  const [filteredData, setFilteredData] = useState<Array<IScholarUser>>([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [showCaptains, setShowCaptains] = useState(false);

  const [balanceLoading, setBalanceLoadig] = useState(false);
  const [sendToken, setSendToken] = useState(false);
  const [lastGameId, setLastGameId] = useState('');

  const tableInstance = useTable(
    {
      // @ts-ignore
      columns,
      data: filteredData,
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } =
    tableInstance;

  const [filters, setFilters] = useState({
    game: games.length ? games[0].id : '',
    revenueSplit: '',
    // minWeeklyEarnings: '',
    // maxWeeklyEarnings: '',
  });

  const [massPayout, setMassPayout] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const loadData = async (users: IScholarUser[]) => {
    const _data: IScholarUser[] = [];
    try {
      setBalanceLoadig(true);
      const res = users.map(scholar => {
        return getBalanceInGame(filters.game, scholar.starz_wallet_address);
      });
      const result = await Promise.all(res);
      if (
        filters.game === games.find(game => game.name === 'Benji Bananas')?.id
      ) {
        const benjiPerformanceResult = users.map(scholar => {
          return benjiPerformance(scholar.starz_wallet_address);
        });

        const benjiPeformanceResponse = await Promise.all(
          benjiPerformanceResult
        );

        users.forEach((scholar, index) => {
          const balance = benjiPeformanceResponse[index].data.isPayout
            ? 0
            : +benjiPeformanceResponse[index].data.performanceStats[0]
                ?.amount || 0;
          const payable = (balance / 100) * (scholar.scholar_percentage || 30);
          const symbol = result[index].data?.symbol;
          const season =
            benjiPeformanceResponse[index].data.performanceStats[0]?.season ||
            '';
          _data.push({
            ...scholar,
            payable: payable,
            total: balance,
            symbol,
            season,
          });
        });
      } else {
        users.forEach((scholar, index) => {
          const balance = result[index].data?.balance;
          const payable = (balance / 100) * (scholar.scholar_percentage || 30);
          const symbol = result[index].data?.symbol;
          _data.push({ ...scholar, payable: payable, total: balance, symbol });
        });
      }
      console.log(_data);
      setData(_data);
      setFilteredData(_data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setBalanceLoadig(false);
    }
  };

  const handleUpdateValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const type = e.target.type;
    if (type === 'checkbox') {
      const { checked } = e.target;
      const users = [...data];
      const user = users.find(e => e.id === id)!;
      const index = users.indexOf(user);
      users[index].selected = checked;
      setData([...users]);
    } else {
      const users = [...data];
      const user = users.find(e => e.id === id)!;
      const index = users.indexOf(user);
      users[index].payable = e.currentTarget.value;
      setData([...users]);
    }
  };

  const handleResetReward = (id: string) => {
    const users = [...data];
    const user = users.find(e => e.id === id)!;
    const index = users.indexOf(user);
    users[index].payable = 0;
    users[index].total = 0;
    setData([...users]);
  };

  const handleSelectAllRows = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const users = [...data];
    users.forEach(user => (user.selected = checked));
    setData([...users]);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);

    const users = [...data];
    if (e.target.value.length > 2) {
      const filteredUsers = users.filter(user => {
        const value = e.target.value.toLowerCase();

        return (
          user.id?.toLowerCase().includes(value) ||
          user.nick_name?.toLowerCase().includes(value) ||
          user.email?.toLowerCase().includes(value) ||
          user.wallet_address?.toLowerCase().includes(value) ||
          user.starz_wallet_address?.toLowerCase().includes(value)
        );
      });
      setFilteredData([...filteredUsers]);
    } else {
      setFilteredData([...data]);
    }
  };

  useEffect(() => {
    if (!games.length) {
      dispatch.games.getAllGames();
    }
  }, []);

  useEffect(() => {
    if (games.length) {
      setFilters(f => ({
        ...f,
        game: games[0].id,
      }));
    }
  }, [games]);

  useEffect(() => {
    if (!filters.game || filters.game === lastGameId) return;
    setLastGameId(filters.game);

    if (
      filters.game ===
      games.find(game => game.name === 'Benji Bananas' && showCaptains)?.id
    ) {
      dispatch.captain.getFilteredCaptains({ gameId: filters.game });
    } else {
      dispatch.scholars.getScholars({
        query: `game_id=${filters.game}&${
          filters.revenueSplit ? `revenue_split=${filters.revenueSplit}` : ''
        }`,
        action: loadData,
      });
    }
  }, [filters]);

  return (
    <Page>
      <Container>
        <div
          className='row flex-start'
          style={{ flexWrap: 'wrap', gap: '25px' }}
        >
          <h1 className='title'>Earnings</h1>
          <div className='btns-container'>
            <Button variant='transparent' onClick={() => setMassPayout(true)}>
              Mass Payout
            </Button>
            {/* DO NOT REMOVE THIS */}
            {/* <Button variant='primary' onClick={() => setMassPayout(true)}>
              Mass Payout
            </Button> */}
          </div>
        </div>
        {/* Filters Component takes searchProps for search bar currently it is optional */}
        <Filters
          searchProps={{
            value: searchTerm,
            onChange: handleSearch,
          }}
        >
          <div className='grid'>
            <div className='item'>
              {/* Temporary values added for now*/}
              <Select
                options={games.map(game => ({
                  label: game.name,
                  value: game.id,
                }))}
                value={filters.game}
                title='Game'
                name='game'
                onChange={handleChange}
              />
            </div>
            <div
              className='item'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div
                className='row'
                style={{ justifyContent: 'flex-start', gap: '10px' }}
              >
                <Checkbox
                  checked={showCaptains}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setShowCaptains(e.target.checked)
                  }
                />
                <p className='font-size-14'>Show Captains </p>
              </div>
            </div>
            {/* <div className='item'>
              <div className='inner-flex'>
                <div className='inner-flex-item'>
                  <InputField
                    placeholder='Weekly Earnings (Min)'
                    type='number'
                    value={filters.minWeeklyEarnings}
                    onChange={handleChange}
                    name='minWeeklyEarnings'
                  />
                </div>
                <div className='inner-flex-item'>
                  <InputField
                    placeholder='Weekly Earnings (Max)'
                    type='number'
                    value={filters.maxWeeklyEarnings}
                    onChange={handleChange}
                    name='maxWeeklyEarnings'
                  />
                </div>
              </div>
            </div> */}
          </div>
        </Filters>
        {showCaptains && <Captains gameId={filters.game} />}

        {!showCaptains && (
          <>
            {loading || (balanceLoading && !data.length && !showCaptains) ? (
              <Loader />
            ) : (
              <>
                {data.length ? (
                  <TableContainer>
                    <table {...getTableProps()}>
                      <thead>
                        {headerGroups.map(headerGroup => (
                          <tr
                            className='header-row'
                            {...headerGroup.getHeaderGroupProps()}
                          >
                            {headerGroup.headers.map(column => (
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                <div className='row'>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <>
                                        <div className='col'>
                                          <img
                                            src={assets.sortArrowUp}
                                            style={{ opacity: 0.6 }}
                                            alt=''
                                          />
                                          <img
                                            src={assets.sortArrowUp}
                                            style={{
                                              transform: 'rotate(180deg)',
                                            }}
                                            alt=''
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <div className='col'>
                                        <img src={assets.sortArrowUp} alt='' />
                                        <img
                                          src={assets.sortArrowUp}
                                          style={{
                                            transform: 'rotate(180deg)',
                                            opacity: 0.6,
                                          }}
                                          alt=''
                                        />
                                      </div>
                                    )
                                  ) : (
                                    <>
                                      {column.canSort && (
                                        <div className='col'>
                                          <img
                                            src={assets.sortArrowUp}
                                            style={{ opacity: 0.6 }}
                                            alt=''
                                          />
                                          <img
                                            src={assets.sortArrowUp}
                                            style={{
                                              transform: 'rotate(180deg)',
                                              opacity: 0.6,
                                            }}
                                            alt=''
                                          />
                                        </div>
                                      )}
                                    </>
                                  )}
                                  <div>{column.render('Header')}</div>
                                </div>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                          prepareRow(row);
                          return (
                            <EarningsRow
                              row={row}
                              setSendToken={setSendToken}
                              key={row.original.id}
                              gameId={filters.game}
                              onChange={handleUpdateValue}
                              resetScholarReward={handleResetReward}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </TableContainer>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingBlock: '50px',
                    }}
                  >
                    <p className='font-size-18'>No user found</p>
                  </div>
                )}
              </>
            )}
          </>
        )}

        <div className='margin'></div>
      </Container>
      {massPayout && (
        <MassPayoutDialog
          gameId={filters.game}
          open={massPayout}
          setOpen={setMassPayout}
          data={data}
          onChange={handleUpdateValue}
          resetScholarReward={handleResetReward}
          handleSelectAllRows={handleSelectAllRows}
        />
      )}
      {sendToken && (
        <SendFungibleTokens
          open={sendToken}
          setOpen={setSendToken}
          gameId={filters.game}
        />
      )}
    </Page>
  );
};

export default Earnings;

const Container = styled.div`
  & .margin {
    height: 80px;
  }

  & .btns-container {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .grid {
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 10px;

    & .inner-flex {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  }
  & .row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.flex-start {
      justify-content: flex-start;
      gap: 10px;
    }

    & .amount-input {
      padding: 6px 10px;
      border: 1px solid #ffffff;
      outline: none;
      width: 140px;
      background-color: transparent;
      color: #ffffff;
    }

    & .user-img {
      height: 30px;
      width: 30px;
      object-fit: cover;
    }
    & .stz-icon {
      width: 16px;
      height: 16px;
    }
  }

  & .icon-button {
    & img {
      padding-inline: 5px;
      cursor: pointer;
    }
    & .menu {
      position: absolute;
      width: 260px;
      background-color: #0e0e0e;
      transform: translate(-95%, -110%);
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
      display: none;
      z-index: 20;
      & p {
        opacity: 0.6;
      }
      &.show {
        display: block;
      }
      & .menu-popup {
        & .profile {
          display: flex;
          align-items: center;
          gap: 11px;

          padding: 14px 20px;
          & .user-image {
            height: 48px;
            width: 48px;
            padding: 0;
          }

          & .font-size-14 {
            color: #ffffff;
            opacity: 1;
          }

          & .address-container {
            display: flex;
            align-items: center;
            margin-top: 2px;
            position: relative;
            & img {
              cursor: pointer;
            }
            & p {
              background-color: #ffffff1a;
              padding: 2px;
              border-radius: 2px;
              margin-right: 4px;
            }
            & .tooltip {
              position: absolute;
              left: 105%;
              top: calc(50% - 10px);
              background-color: #ffffff33;
              padding-inline: 5px;
              border-radius: 2px;
              font-size: 10px;
              height: 20px;
              line-height: 16px;
              transform: scale(0.1);
              opacity: 0;
              pointer-events: none;
              transition: 0.2s ease;
              &.show {
                transform: scale(1);
                opacity: 1;
              }
            }
          }
        }
        & .menu-item {
          padding: 14px 22px;
          border-top: 1px solid #ffffff33;
          cursor: pointer;
          & p {
            opacity: 0.4;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    & .collapse {
      .grid {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  @media only screen and (max-width: 420px) {
    & .filters-and-search {
      margin-block: 40px 30px;
      & .row {
        gap: 20px;
        & .search-bar {
          flex-grow: 1;
          width: 100%;
        }
      }
    }
    & .collapse {
      .grid {
        grid-template-columns: 1fr;
      }
    }
  }
`;
const TableContainer = styled.div`
  overflow-x: auto;
  border: 1px solid #ffffff33;
  padding-bottom: 10px;
  width: 100%;
  margin-block: 30px 60px;
  border-radius: 4px;
  & table {
    border-collapse: collapse;
    min-width: 950px;
    width: 100%;
    & tbody {
      padding-inline: 10px;
    }
    & .header-row {
      height: 40px;
      background-color: #0e0e0e;
      & th {
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        opacity: 0.6;
        padding-top: 4px;
        border-bottom: 1px solid #ffffff33;
        text-align: left;
        &:first-of-type {
          padding-left: 32px;
        }

        & .row {
          display: flex;
          align-items: center;
          gap: 8px !important;
          justify-content: flex-start;

          & .col {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
        }
      }
    }
    & tr {
      height: 60px;
    }
    & td {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      border-bottom: 1px solid #ffffff33;
      padding-block: 12px;
      &:first-of-type {
        padding-left: 32px;
      }

      &:last-of-type {
        padding-right: 10px;
      }

      & .row {
        display: flex;
        align-items: center;
        gap: 10px;

        & img {
          width: 20px;
        }
      }

      & .icon-button {
        & img {
          padding-inline: 5px;
          cursor: pointer;
        }
        & .menu {
          position: absolute;
          width: 200px;
          background: #0e0e0e;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
          transform: translate(-90%, -115%);
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
          display: none;
          & .menu-item {
            padding: 10px 5px;
            cursor: pointer;
            &:hover {
              background-color: #000000;
            }
          }
          & p {
            opacity: 0.6;
          }
          &.show {
            display: block;
          }
        }
      }
    }
    & tr:last-child {
      & td {
        border: none;
      }
    }
  }
`;
