import React, { FC } from 'react';
import styled from 'styled-components';
import theme from '../../theme';

interface IProps {
  noPadding?: boolean;
  style?: any;
}

const Loader: FC<IProps> = ({ noPadding, style }) => {
  return (
    <Parent style={{ paddingBlock: noPadding ? '0px' : '100px', ...style }}>
      <Container>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Container>
    </Parent>
  );
};

export default Loader;

const Parent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 100px;
`;

const Container = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid ${theme.palette.primary};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${theme.palette.primary} transparent transparent transparent;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
