import React, { FC, useRef, useState, useEffect } from 'react';

import styled from 'styled-components';

import CaptainScholarRow from './CaptainScholarRow';
import EthAddress from '../../../components/EthAddress/EthAddress';
import { Link } from 'react-router-dom';

import { getCaptainScholars } from '../../../http';

import { IScholarUser } from '../../../types/interfaces';
import assets from '../../../assets';

interface IProps {
  captain: IScholarUser;
  gameId: string;
}

const CapRow: FC<IProps> = ({ captain, gameId }) => {
  const collapse = useRef<HTMLDivElement>(null);
  const [showScholar, setShowScholar] = useState(false);
  const [totalEarnings, setTotalEarnings] = useState(0);

  const [scholars, setScholars] = useState<IScholarUser[]>();

  const handleToggleCollapse = () => {
    if (collapse.current) {
      if (collapse.current.style.maxHeight !== '0px') {
        collapse.current.style.maxHeight = '0px';
        setShowScholar(false);
        setTotalEarnings(0);
      } else {
        collapse.current.style.maxHeight = collapse.current.scrollHeight + 'px';
        setShowScholar(true);
      }
    }
  };

  const handleCaptainScholars = async () => {
    try {
      const { data } = await getCaptainScholars(captain.id, gameId);
      setScholars(data);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    handleCaptainScholars();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <tr key={captain.id} onClick={handleToggleCollapse}>
        <td>
          <Link to={`/dashboard/user/${captain.id}`} className='link'>
            <div className='row' style={{ justifyContent: 'flex-start' }}>
              <img
                src={captain.image_url || assets.noImage}
                style={{
                  height: '30px',
                  width: '30px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
                alt=''
              />
              <p className='font-size-14'>{captain.nick_name || '------'}</p>
            </div>
          </Link>
        </td>
        <td>
          <EthAddress address={captain.wallet_address} />
        </td>
        <td>
          <EthAddress address={captain.starz_wallet_address} />
        </td>
        <td>{captain.email}</td>
        <td>
          {100 - captain.scholar_percentage}% / {captain.scholar_percentage}%
        </td>
        <td>{totalEarnings} PRIMATE</td>
      </tr>
      <tr style={{ height: 'unset' }}>
        <td colSpan={6} style={{ padding: '0px' }}>
          <Collapse ref={collapse}>
            <div className='wrapper'>
              <div style={{ border: '1px solid #ffffff1a' }}>
                <table>
                  <thead>
                    <tr className='header-row'>
                      <th>Name</th>
                      <th>Wallet address</th>
                      <th>Starz wallet address</th>
                      <th>Revenue split (captain/scholar)</th>
                      <th>Earnings</th>
                      <th>Payout amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {scholars?.map(scholar => (
                      <CaptainScholarRow
                        showScholar={showScholar}
                        setShowScholar={setShowScholar}
                        captain={captain}
                        key={scholar.id}
                        scholar={scholar}
                        gameId={gameId}
                        setTotalEarnings={setTotalEarnings}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Collapse>
        </td>
      </tr>
    </>
  );
};

export default CapRow;

const Collapse = styled.div`
  overflow: hidden;
  transition: max-height 0.6s ease;
  max-height: 0px;
  & .wrapper {
    padding: 15px 30px;
  }
`;
