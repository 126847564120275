import { createModel } from '@rematch/core';
import axios from 'axios';
import React from 'react';
import { RootModel } from '.';
import {
  addScholarshipImage,
  createScholarship as scholarshipApi,
  getScholarship,
  getAllScholarships,
  updateScholarship as updateScholarshipApi,
} from '../../http';

import { ICreateScholarship, IScholarship } from '../../types/interfaces';

interface ICSPayload {
  data: ICreateScholarship;
  image: File;
  setScholarshipCreated: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IUSPayload {
  data: ICreateScholarship;
  image?: File;
  setScholarshipCreated: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IState {
  loading: boolean;
  createdScholarship: IScholarship | null;
  scholarships: Array<IScholarship>;
  scholarshipToEdit: IScholarship | null;
}

export const scholarship = createModel<RootModel>()({
  name: 'scholarship',
  state: {
    loading: false,
    createdScholarship: null,
    scholarships: [],
    scholarshipToEdit: null,
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setCreatedScholarship(state, payload: IScholarship) {
      state.createdScholarship = payload;
    },
    setScholarships(state, payload: IScholarship[]) {
      state.scholarships = payload;
    },
    setScholarshipToEdit(state, payload: IScholarship | null) {
      state.scholarshipToEdit = payload;
    },
  },
  effects: dispatch => ({
    async createScholarship(payload: ICSPayload) {
      try {
        dispatch.scholarship.setLoading(true);
        const { data } = await scholarshipApi(payload.data);
        const image = new FormData();
        image.append('image', payload.image);
        await addScholarshipImage(image, data?.id);
        const res = await getScholarship(data.id);
        dispatch.scholarship.setCreatedScholarship(res.data);
        payload.setScholarshipCreated(true);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage = (err as any).response?.data.message;
          dispatch.feedback.open({ title: 'Error', subtitle: errorMessage });
        }
      } finally {
        dispatch.scholarship.setLoading(false);
      }
    },

    async updateScholarship(payload: IUSPayload, state) {
      try {
        dispatch.scholarship.setLoading(true);
        const id = state.scholarship.scholarshipToEdit?.scholarship_id;
        if (id) {
          // TODO : fix spots conversion issue (string to number)
          await updateScholarshipApi(
            { ...payload.data, spots: String(payload.data.spots) },
            id
          );

          if (payload.image) {
            const image = new FormData();
            image.append('image', payload.image);
            await addScholarshipImage(image, id);
          }
          const { data } = await getScholarship(id);
          dispatch.scholarship.setCreatedScholarship(data);
        }

        payload.setScholarshipCreated(true);
        dispatch.scholarship.getScholarships();
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          const errorMessage = (err as any).response?.data.message;
          dispatch.feedback.open({ title: 'Error', subtitle: errorMessage });
        }
      } finally {
        dispatch.scholarship.setLoading(false);
      }
    },

    async getScholarships() {
      try {
        dispatch.scholarship.setLoading(true);
        let { data } = await getAllScholarships();
        dispatch.scholarship.setScholarships(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.scholarship.setLoading(false);
      }
    },
  }),
});
