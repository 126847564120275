import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const RedirectOutlet = () => {
  // redirect user to dashboard if user is loggedin
  // and public routes are accessed
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  if (isLoggedIn) return <Navigate to='/dashboard' replace={true} />;
  return <Outlet />;
};

export default RedirectOutlet;
