import React, { FC, useState } from "react";

import styled from "styled-components";
import classNames from "classnames";

import theme from "../../theme";
import assets from "../../assets";

interface IProps {
  placeholder: string;
  type: string;
  disableTitle?: boolean;
  hidden?: boolean;
  [x: string]: any;
}

const InputField: FC<IProps> = ({
  placeholder,
  type,
  error,
  className,
  disableTitle,
  hidden,

  ...rest
}) => {
  const [isVisible, setVisible] = useState(true);

  const ToggleType = () => {
    setVisible(!isVisible);
  };
  return (
    <FormGroup className={className}>
      <Input
        placeholder={placeholder}
        className={classNames(
          error && "is-invalid",
          disableTitle && "p-visible"
        )}
        type={isVisible ? type : "text"}
        {...rest}
        {...rest}
      />
      {!disableTitle && <FormLabel>{placeholder}</FormLabel>}
      <p className="invalid-feedback">{error}</p>
      <span onClick={ToggleType}>
        {hidden && <Img src={isVisible ? assets.show : assets.hide} />}
      </span>
    </FormGroup>
  );
};

export default InputField;

const FormGroup = styled.div`
  position: relative;
  margin-bottom: 20px;
  width: 100%;
`;

const FormLabel = styled.label`
  font-size: 10px;
  line-height: 16px;
  font-weight: 300;
  display: block;
  font-weight: 400;
  position: absolute;
  top: -20px;
  left: 8px;
  opacity: 0.4;
  transition: 0.2s;
  color: #ffffff;
`;

const Input = styled.input`
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  width: 100%;
  border: 0;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 2px;
  height: 48px;
  padding-inline: 16px;
  outline: 0;
  font-weight: 600;
  background: transparent;
  transition: 0.2s;
  cursor: pointer;
  &::placeholder {
    color: transparent;
  }
  &:disabled {
    border: 1px solid #ffffff33;
    color: #ffffff4d;
    cursor: default;
  }
  &:placeholder-shown {
    border: 1px solid #ffffff66;
  }
  &:placeholder-shown ~ label {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    cursor: text;
    top: 14px;
    left: 16px;
    opacity: 0.6;
    font-weight: 400;
    pointer-events: none;
  }
  &:focus {
    opacity: 1;
    border-color: ${theme.palette.primary};
  }
  &:focus ~ label {
    font-size: 10px;
    line-height: 16px;
    font-weight: 300;
    top: -20px;
    left: 8px;
    color: #ffffff;
    opacity: 0.4;
    font-weight: 400;
  }
  & ~ .invalid-feedback {
    font-size: 10px;
    line-height: 16px;
    font-weight: 300;
    color: ${theme.palette.secondary};
    display: none;
    margin-top: 4px;
  }
  &.is-invalid {
    border-color: ${theme.palette.secondary};
  }
  & ~ .invalid-feedback {
    display: block;
  }
`;
const Img = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;
