import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../store';
import { InferType, object, string } from 'yup';
import { FormErrors, validateData } from '../utils/validations';

import DialogLayout from './Dialog';
import Select from '../components/Select/Select';
import InputField from '../components/InputField/InputField';
import Button from '../components/Button/Button';

import ImagePicker from '../components/ImagePicker/ImagePicker';

import assets from '../assets';
import theme from '../theme';

const schema = object({
  game_id: string().required().label('Game'),
  badge_id: string().required().label('Badge'),
  spots: string().required().label('Spots'),
});

interface ScholarshipData extends InferType<typeof schema> {}

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScholarshipDialog: FC<IProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch<Dispatch>();

  // to do : change ui and funcationality for update conditionally if scholarshipToEdit exist

  const scholarshipToEdit = useSelector(
    (state: RootState) => state.scholarship.scholarshipToEdit
  );
  const games = useSelector((state: RootState) => state.games.games);
  const badges = useSelector((state: RootState) => state.badges.badges);
  const loading = useSelector((state: RootState) => state.scholarship.loading);
  const scholarship = useSelector(
    (state: RootState) => state.scholarship.createdScholarship
  );

  const [scholarshipCreated, setScholarshipCreated] = useState(false);

  const [data, setData] = useState<ScholarshipData>({
    game_id: scholarshipToEdit?.game_id || '',
    badge_id: scholarshipToEdit?.badge_id || '',
    spots: scholarshipToEdit?.spots || '',
  });

  const [errors, setErrors] =
    useState<FormErrors<ScholarshipData | null>>(null);

  const [image, setImage] = useState<File | null>(null);
  const [imageError, setImageError] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleCreateScholarship = async () => {
    setErrors(null);
    setImageError(false);
    const { errors: validationErrors } = await validateData<ScholarshipData>(
      schema,
      data
    );
    if (validationErrors) {
      return setErrors(validationErrors);
    }
    if (!image) {
      return setImageError(true);
    }
    if (image) {
      dispatch.scholarship.createScholarship({
        data,
        image,
        setScholarshipCreated,
      });
    }
  };

  const handleUpdateScholarship = async () => {
    setErrors(null);
    const { errors: validationErrors } = await validateData<ScholarshipData>(
      schema,
      data
    );
    if (validationErrors) {
      return setErrors(validationErrors);
    }

    if (image) {
      dispatch.scholarship.updateScholarship({
        data,
        image,
        setScholarshipCreated,
      });
    } else {
      dispatch.scholarship.updateScholarship({ data, setScholarshipCreated });
    }
  };

  useEffect(() => {
    dispatch.games.getAllGames();
    if (!badges.length) {
      dispatch.badges.getAllBadges();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <DialogLayout open={open}>
      <Container className={scholarshipCreated ? 'small' : ''}>
        <div className='close-icon'>
          <img
            src={assets.closeIcon}
            style={{ cursor: 'pointer' }}
            onClick={() => setOpen(false)}
            alt=''
          />
        </div>
        <Content>
          {scholarshipCreated ? (
            <>
              <h1 className='title'>Scholarship created!</h1>
              <div className='banner'>
                <img src={scholarship?.scholarship_logo} alt='' />
                <div className='spot-count'>
                  <p
                    className='font-size-14'
                    style={{ fontFamily: 'Stalinist One' }}
                  >
                    {scholarship?.spots}
                  </p>
                  <p className='font-size-8'>spots left</p>
                </div>
              </div>
              <ButtonContainer>
                <Button
                  variant='primary'
                  onClick={() => {
                    dispatch.scholarship.getScholarships();
                    setOpen(false);
                  }}
                >
                  Continue
                </Button>
              </ButtonContainer>
            </>
          ) : (
            <>
              <h1 className='title'>
                {scholarshipToEdit
                  ? 'Edit Scholarship Information'
                  : 'Issue a Scholarship'}
              </h1>
              <Grid>
                <Select
                  options={games.map(game => ({
                    label: game.name,
                    value: game.id,
                  }))}
                  title={scholarshipToEdit ? 'Game' : 'Select Game'}
                  name='game_id'
                  value={data.game_id}
                  onChange={handleChange}
                  disabled={!!scholarshipToEdit}
                  error={errors?.game_id}
                />
                <Select
                  options={badges.map(badge => ({
                    label: badge.name,
                    value: badge.id,
                  }))}
                  title='Badge'
                  name='badge_id'
                  value={data.badge_id}
                  onChange={handleChange}
                  disabled={!!scholarshipToEdit}
                  error={errors?.badge_id}
                />

                <InputField
                  placeholder='Spots left'
                  type='number'
                  value={data.spots}
                  name='spots'
                  onChange={handleChange}
                  error={errors?.spots}
                />
              </Grid>
              <div
                className='picker'
                style={{
                  width: '164px',
                  marginInline: 'auto',
                  marginBlock: '4px 39px',
                }}
              >
                <ImagePicker
                  image={image}
                  // @ts-ignore
                  setImage={setImage}
                  placeholder='Upload or drag and drop Game Logo (centered)'
                  height='139px'
                  // customBaseStyles={{
                  //   borderColor: '#ffffff66',
                  //   paddingInline: '14px',
                  // }}
                />
                {imageError && (
                  <p
                    className='font-size-12 error'
                    style={{
                      color: theme.palette.secondary,
                      marginTop: '8px',
                      textAlign: 'center',
                    }}
                  >
                    Image is requied
                  </p>
                )}
              </div>
              <ButtonContainer>
                <Button variant='secondary' onClick={() => setOpen(false)}>
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  disabled={loading}
                  onClick={
                    scholarshipToEdit
                      ? handleUpdateScholarship
                      : handleCreateScholarship
                  }
                >
                  Confirm
                </Button>
              </ButtonContainer>
            </>
          )}
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default ScholarshipDialog;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;

  &.small {
    width: 392px;
  }

  & .close-icon {
    display: flex;
    justify-content: flex-end;
    padding-block: 6px;
  }
`;

const Content = styled.div`
  background: #121212;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
  padding: 40px 30px;

  & .banner {
    width: 100%;
    height: 384px;
    position: relative;
    margin-block: 30px 40px;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & .spot-count {
      background-color: ${theme.palette.primary};
      position: absolute;
      top: -8px;
      right: 8px;
      text-align: center;
      padding: 8px 10px;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 38px 24px;
  margin-top: 52px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
