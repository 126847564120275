import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import Page from '../../layouts/Page';
import Loader from '../../components/Loader/Loader';
import Select from '../../components/Select/Select';
import VaultItem from '../../components/VaultItem/VaultItem';
import Filters from '../../components/Filters/Filters';
import TransferAsset from '../../dialogs/TransferAsset';
import AssetTransfered from '../../dialogs/AssetTransfered';
import TransferNFT1155 from '../../dialogs/TransferNFT1155';
import { IVaultNft } from '../../types/interfaces';

const Vault: FC = () => {
  const dispatch = useDispatch<Dispatch>();

  const [transferAsset, setTransferAsset] = useState(false);
  const [transfer1155, setTransfer1155] = useState(false);
  const [isAssetTransfered, setIsAssetTransfered] = useState(false);

  const games = useSelector((state: RootState) => state.games.gamesWithContracts);

  const selectedNotification = useSelector((state: RootState) => state.notifications.selected);

  const nfts = useSelector((state: RootState) => state.vault.nfts);
  const loading = useSelector((state: RootState) => state.vault.loading);

  const [showCategoryFilter, setShowCategoryFilter] = useState(false);
  // send this cursor in api call for pagination
  const [cursor, setCursor] = useState<null | string>(null);

  const [game, setGame] = useState(selectedNotification?.game_id || games[0]?.game_id || '');
  const [category, setCategory] = useState('');
  const [cateogryOptions, setCategoryOptions] = useState<Array<{ label: string; value: string }>>(
    []
  );
  // eslint-disable-next-line
  const [filters, setFilters] = useState({
    earning: '',
    revenueSplit: '',
  });

  const [search, setSearch] = useState('');
  const [filteredNfts, setFilteredNfts] = useState<IVaultNft[]>(nfts);

  useEffect(() => {
    setFilteredNfts(nfts);
  }, [nfts]);

  const handleGameChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setGame(e.currentTarget.value);
    const selectedGame = games.find(game => game.game_id === e.currentTarget.value);
    const _categoryOptions = selectedGame?.contracts.map(e => ({
      label: e.contract_name,
      value: e.contract_id,
    }));

    if (_categoryOptions) {
      if (_categoryOptions.length > 1) {
        setShowCategoryFilter(true);
      } else {
        setShowCategoryFilter(false);
      }

      setCategory(_categoryOptions[0]?.value);

      setCategoryOptions(_categoryOptions);
    }
  };
  // eslint-disable-next-line
  const HandleFilters = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleGetNfts = () => {
    if (showCategoryFilter) {
      const payload = {
        data: { contract_id: category },
        cursor: cursor || '',
        setCursor: setCursor,
      };
      dispatch.vault.getNfts(payload);
    } else {
      const selectedGame = games.find(e => e.game_id === game);
      const contract_id = selectedGame?.contracts[0]?.contract_id;

      if (contract_id) {
        const payload = {
          data: { contract_id },
          cursor: cursor || '',
          setCursor: setCursor,
        };
        dispatch.vault.getNfts(payload);
      } else {
        dispatch.vault.setNfts([]);
      }
    }
  };

  useEffect(() => {
    dispatch.games.gamesWithContracts();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let selectedGame;
    if (!game) {
      selectedGame = games[0];
      setGame(selectedGame?.game_id);
    } else {
      selectedGame = games.find(e => e.game_id === game);
    }
    const _categoryOptions = selectedGame?.contracts.map(e => ({
      label: e.contract_name,
      value: e.contract_id,
    }));
    if (_categoryOptions) {
      if (_categoryOptions.length > 1) {
        setShowCategoryFilter(true);
      } else {
        setShowCategoryFilter(false);
      }

      setCategory(_categoryOptions[0]?.value);

      setCategoryOptions(_categoryOptions);
    }
    // eslint-disable-next-line
  }, [games]);

  useEffect(() => {
    if (game) {
      dispatch.vault.getScholarShipScholars(game);
    }
    // eslint-disable-next-line
  }, [game]);

  useEffect(() => {
    if (game) {
      handleGetNfts();
    }
    // eslint-disable-next-line
  }, [game, category]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);

    const value = e.currentTarget.value.trim().toLowerCase();

    if (value.length) {
      const _filteredNfts = nfts.filter(nft => {
        return (
          nft.metadata?.toLowerCase().includes(value) ||
          nft.name?.toLowerCase().includes(value) ||
          nft.token_id?.toLowerCase().includes(value) ||
          nft.token_uri?.toLowerCase().includes(value)
        );
      });
      console.log({ _filteredNfts });
      setFilteredNfts(_filteredNfts);
    } else {
      setFilteredNfts(nfts);
    }
  };

  return (
    <Page>
      <Container>
        <div className='row'>
          <h1 className='title'>Vault</h1>
          {/* <Button
            variant='transparent'
            onClick={() => setOpenBundleDialog(true)}
          >
            Create Bundle
          </Button> */}
          <div />
        </div>
        {/* Filters Component takes searchProps for search bar currently it is optional */}
        <Filters
          searchProps={{
            value: search,
            onChange: handleSearch,
          }}
        >
          <div className='grid'>
            <div className='item'>
              <Select
                name='game'
                title='Select game'
                onChange={handleGameChange}
                options={games.map(game => ({
                  label: game.name,
                  value: game.game_id,
                }))}
                value={game}
              />
            </div>

            {showCategoryFilter && (
              <div className='item'>
                <Select
                  name='category'
                  title='Category'
                  onChange={e => {
                    setCursor(null);
                    setCategory(e.target.value);
                  }}
                  options={cateogryOptions}
                  value={category}
                />
              </div>
            )}
          </div>
        </Filters>
        {loading ? (
          <Loader />
        ) : (
          <Grid>
            {filteredNfts?.map((nft, index) => {
              return (
                <VaultItem
                  key={index}
                  nftData={nft}
                  onTransferAsset={() => setTransferAsset(true)}
                  setTransfer1155={setTransfer1155}
                  // onAssignBadge={() => {
                  //   handleSelectedNftInfo(nft.token_id);
                  //   setAssignBadge(true);
                  // }}
                />
              );
            })}
          </Grid>
        )}
        <div className='margin'></div>
      </Container>
      {transferAsset && (
        <TransferAsset
          open={transferAsset}
          setOpen={setTransferAsset}
          onAssetTransfer={() => setIsAssetTransfered(true)}
          category={category}
          gameId={game}
        />
      )}
      {isAssetTransfered && (
        <AssetTransfered
          open={isAssetTransfered}
          setOpen={setIsAssetTransfered}
          gameId={game}
          onContinue={handleGetNfts}
        />
      )}
      {transfer1155 && (
        <TransferNFT1155
          open={transfer1155}
          setOpen={setTransfer1155}
          onTransferAsset={() => setTransferAsset(true)}
        />
      )}
    </Page>
  );
};

export default Vault;

const Container = styled.div`
  .grid {
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 10px;
  }
  & .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .margin {
    height: 80px;
  }

  @media only screen and (max-width: 900px) {
    & .collapse {
      .grid {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    & .collapse {
      .grid {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  @media only screen and (max-width: 420px) {
    & .filters-and-search {
      margin-block: 40px 30px;
      & .row {
        gap: 20px;
        & .search-bar {
          flex-grow: 1;
          width: 100%;
        }
      }
    }
    & .collapse {
      .grid {
        grid-template-columns: 1fr;
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(184px, 1fr));
  gap: 20px;
  margin-top: 30px;

  @media only screen and (max-width: 420px) {
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
`;
