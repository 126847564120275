/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useRef, useEffect, useState } from 'react';

import { ethers } from 'ethers';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import classNames from 'classnames';

import InputField from '../../components/InputField/InputField';
import Select from '../../components/Select/Select';
import Accordion from '../../components/Accordion/Accordion';
import Loader from '../../components/Loader/Loader';
import NftImage from '../../components/NftImage/NftImage';

import assets from '../../assets';
import theme from '../../theme';

import { adminTimeline } from '../../http';
import { IAdminTimeline } from '../../types/interfaces';
import { getFormatedTime } from '../../utils';

const AdminTimeline: FC = () => {
  const panel = useRef<HTMLDivElement>(null);
  const icon = useRef<HTMLImageElement>(null);

  const [timelineData, setTimeLineData] = useState<Array<IAdminTimeline>>();
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({
    name: '',
    timeFrom: '',
    timeTo: '',
    dateFrom: '',
    dateTo: '',
    badge: '',
  });

  const dispatch = useDispatch<Dispatch>();

  const badges = useSelector((state: RootState) => state.badges.badges);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleToggleFilters = () => {
    if (panel.current && icon.current) {
      if (panel.current.style.maxHeight) {
        panel.current.style.maxHeight = '';
        icon.current.classList.remove('rotate');
      } else {
        panel.current.style.maxHeight = panel.current.scrollHeight + 'px';
        icon.current.classList.add('rotate');
      }
    }
  };

  useEffect(() => {
    dispatch.badges.getAllBadges();
    handleToggleFilters(); // in order to show filter box open by default
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await adminTimeline();
        // console.log(data);
        setTimeLineData(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [filters]);
  return (
    <Container>
      <div className='filters'>
        <button className='filter__btn' onClick={handleToggleFilters}>
          <div className='row'>
            <p className='font-size-12'>Filters</p>
            <img src={assets.downArrow} ref={icon} alt='' />
          </div>
        </button>
        <div className='filter__panel' ref={panel}>
          <div className='filter__panel-wrapper'>
            <div>
              <InputField placeholder='Scholar name' type='text' />
            </div>
            <div>
              <InputField placeholder='Time from' type='time' />
            </div>
            <div>
              <InputField placeholder='Time to' type='time' />
            </div>
            <div>
              <InputField placeholder='Date From' type='date' />
            </div>
            <div>
              <InputField placeholder='Date to' type='date' />
            </div>

            <div>
              <Select
                options={badges.map(badge => ({
                  label: badge.name,
                  value: badge.id,
                }))}
                name='badge'
                title='Select badge'
                value={filters.badge}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='timeline'>
        <h1 className='title'>Admin Timeline</h1>
        {loading ? (
          <Loader />
        ) : (
          <div className='timeline__container'>
            {timelineData?.map((timeline, index) => (
              <div className='timeline__container-item' key={index}>
                <div className='img'>
                  <img src={timeline.user.image_url || assets.user} alt='' />
                </div>
                <div className='divider'></div>
                <div className='content'>
                  <div className='row space-between'>
                    <p className='font-size-12'>
                      <b>{timeline.user.fullname}'s </b> transaction{' '}
                      <span
                        className={classNames(
                          timeline.is_completed ? 'success' : 'fail'
                        )}
                      >
                        {timeline.is_completed ? 'Success' : 'Failed'}
                      </span>
                    </p>
                    <p className='font-size-12'>
                      {timeline.user_created_at &&
                        getFormatedTime(timeline.user_created_at)}
                    </p>
                  </div>
                  <div className='accordion' style={{ marginTop: '12px' }}>
                    <Accordion title='Details'>
                      <div className='accordion_content'>
                        <div className='wrapper' style={{ padding: '11px' }}>
                          <p className='font-size-12'>
                            Action: {timeline.action.split('_').join(' ')}
                          </p>
                          <p className='font-size-12'>
                            Scholar wallet:{' '}
                            {timeline.user.account.starz_wallet_address}
                          </p>
                          <p className='font-size-12'>
                            Payout wallet:{' '}
                            {timeline.user.account.wallet_address}
                          </p>
                          {timeline.response.map((res, i) => (
                            <React.Fragment key={i}>
                              <p className='font-size-12'>
                                TX Hash:{' '}
                                {JSON.parse(res).hash ||
                                  JSON.parse(res).transactionHash}
                              </p>
                              {!JSON.parse(res).gasUsed && (
                                <p className='font-size-12'>
                                  Gas fee:{' '}
                                  {ethers.utils.formatUnits(
                                    JSON.parse(res)?.maxFeePerGas?.hex *
                                      JSON.parse(res)?.gasLimit?.hex
                                  )}{' '}
                                  ETH
                                </p>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </Accordion>
                  </div>
                  <div className='accordion'>
                    <Accordion title='Assets'>
                      <div className='accordion_content'>
                        <div className='image_grid' style={{ padding: '11px' }}>
                          {typeof timeline.amount_distribution === 'string' && (
                            <div className='accordion_content'>
                              <p className='font-size-12'>
                                Total:{' '}
                                {
                                  JSON.parse(timeline.amount_distribution)
                                    ?.total
                                }{' '}
                                {
                                  JSON.parse(timeline.amount_distribution)
                                    ?.symbol
                                }
                              </p>
                              <p className='font-size-12'>
                                User distribution:{' '}
                                {JSON.parse(timeline.amount_distribution)?.user}{' '}
                                {
                                  JSON.parse(timeline.amount_distribution)
                                    ?.symbol
                                }
                              </p>
                              <p className='font-size-12'>
                                Platform distribution:{' '}
                                {
                                  JSON.parse(timeline.amount_distribution)
                                    ?.platform
                                }{' '}
                                {
                                  JSON.parse(timeline.amount_distribution)
                                    ?.symbol
                                }
                              </p>
                            </div>
                          )}
                          {timeline.assets?.map((asset, _i) => (
                            <NftImage nftData={asset} size={50} key={_i} />
                          ))}
                        </div>
                      </div>
                    </Accordion>
                  </div>
                  <div className='actions'>
                    <div className='row'>
                      <img src={assets.comment} alt='' />
                      <p className='font-size-10 title'>Comment</p>
                    </div>
                    <div className='row'>
                      <img src={assets.edit} alt='' />
                      <p className='font-size-10 title'>Edit Details</p>
                    </div>
                    <div className='row'>
                      <img src={assets.minus} alt='' />
                      <p className='font-size-10 title'>Remove</p>
                    </div>
                  </div>

                  <div className='comment_input'>
                    <div className='row'>
                      <img src={assets.user} className='user_img' alt='' />
                      <InputField
                        disableTitle
                        type='text'
                        placeholder='Your comment here.'
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Container>
  );
};

export default AdminTimeline;

const Container = styled.div`
  display: flex;
  gap: 40px;
  padding-top: 33px;
  align-items: flex-start;
  & .user_img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: cover;
  }
  & .filters {
    width: 270px;
    padding-block: 40px 29px;
    padding-inline: 40px;
    border: 1px solid #ffffff33;
    border-top: none;
    & .filter__btn {
      padding: 10px 14px;
      background-color: #121212;
      border: none;
      cursor: pointer;
      & .row {
        display: flex;
        align-items: center;
        gap: 15px;
        & img {
          transition: 0.4s ease;
          &.rotate {
            transform: rotate(-180deg);
          }
        }
      }
    }
    & .filter__panel {
      overflow: hidden;
      max-height: 0px;
      transition: max-height 0.4s ease;
      & .filter__panel-wrapper {
        padding-top: 60px;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
  & .timeline {
    flex: 1;
    padding-top: 40px;

    padding-right: 40px;

    & .timeline__container {
      margin-top: 40px;
      border: 1px solid #ffffff33;
      padding-inline: 20px;
      & .timeline__container-item {
        padding-block: 30px;
        display: flex;
        align-items: stretch;
        gap: 20px;
        border-bottom: 1px solid #ffffff33;
        &:last-child {
          border-bottom: none;
        }
        & .img {
          & img {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        & .divider {
          border-left: 1px solid #ffffffcc;
        }
        & .content {
          flex-grow: 1;
          & .accordion_content {
            & .image_grid {
              display: flex;
              align-items: center;
              gap: 8px;
              flex-wrap: wrap;
              & .image {
                height: 30px;
                width: 30px;
                & img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }
        & .actions {
          margin-top: 10px;
          display: flex;
          align-items: center;
          gap: 15px;
        }
      }
    }
  }
  & .row {
    display: flex;
    align-items: center;
    gap: 10px;
    &.space-between {
      justify-content: space-between;
    }
    & span.success {
      color: ${theme.palette.primary};
    }
    & span.fail {
      color: ${theme.palette.secondary};
    }
  }
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    gap: 20px;
    padding-inline: 30px;
    & .filters {
      width: 100%;
      padding-block: 10px 0px;
      border: none;
      padding-inline: 0px;
      & .filter__panel {
        & .filter__panel-wrapper {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          padding-top: 20px;
        }
      }
    }
    & .timeline {
      padding-right: 0px;
    }
  }

  @media only screen and (max-width: 700px) {
    & .filters {
      width: 100%;
      & .filter__panel {
        & .filter__panel-wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      }
    }
    & .timeline {
      width: 100%;
      overflow: hidden;
      & .timeline__container {
        width: 100%;
        overflow-x: auto;
        & .timeline__container-item {
          min-width: 500px;
        }
      }
    }
  }
`;
