import { createModel } from '@rematch/core';
import axios from 'axios';
import { RootModel } from '.';
import { getCaptains } from '../../http';
import { IScholarUser } from '../../types/interfaces';

interface IState {
  loading: boolean;
  captains: IScholarUser[];
  selected: IScholarUser | null;
}

export const captain = createModel<RootModel>()({
  name: 'captain',
  state: {
    loading: false,
    captains: [],
    selected: null,
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setCaptains(state, payload: IScholarUser[]) {
      state.captains = payload;
    },
    setSelected(state, payload: IScholarUser) {
      state.selected = payload;
    },
  },
  effects: dispatch => ({
    async getFilteredCaptains(payload: { gameId: string }) {
      const { gameId } = payload;
      try {
        dispatch.captain.setLoading(true);
        const { data } = await getCaptains(gameId);
        dispatch.captain.setCaptains(data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const errorMessage: string = (err as any).response?.data?.message;
          dispatch.feedback.open({ title: 'Error', subtitle: errorMessage });
        }
      } finally {
        dispatch.captain.setLoading(false);
      }
    },
  }),
});
