import { useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { useParams } from "react-router-dom";

import ChartComponent from "react-apexcharts";
import { ApexOptions } from "apexcharts";

import styled from "styled-components";
import assets from "../../assets";
import Page from "../../layouts/Page";
import theme from "../../theme";
const IndividualPerformance = () => {
  const params = useParams();
  const userId = params.id;
  console.log(userId);
  // eslint-disable-next-line
  // const [filters, setFilters] = useState({
  //   game: "",
  //   earning: "",
  //   revinueSplit: "",
  // });

  const [menuOpen, setMenuOpen] = useState(false);
  // const [openDialog, setOpenDialog] = useState(false);
  const [earningFilter, setEarningFilter] = useState("Daily");

  // const [search, setSearch] = useState("");

  const options: ApexOptions = {
    chart: {
      type: "area",
      toolbar: {
        show: false,
      },
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      animations: {
        easing: "linear",
      },
      events: {
        mounted: (chart) => chart.windowResizeHandler(),
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: [theme.palette.secondary],
        shadeIntensity: 1,
        type: "horizontal",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    colors: [theme.palette.primary, theme.palette.secondary],
    legend: {
      show: false,
    },
    grid: {
      borderColor: "#ffffff33",
      xaxis: {
        lines: {},
      },
    },
    xaxis: {
      type: "category",
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      tickAmount: 10,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  const series = [
    {
      name: "series1",
      data: [],
    },
  ];
  return (
    <>
      <Page>
        <h1 className="title">Scholar Performance</h1>
        <InnerGrid>
          {/* <Filters noMargin>
            <FilterInner>
              <Select
                title='Select Name'
                options={[
                  {
                    label: 'scholar 1',
                    value: '30',
                  },
                  {
                    label: 'scholar 2',
                    value: '40',
                  },
                ]}
                name='game'
                value={filters.game}
                // onChange={handleChange}
              />
              <Select
                title='Select By Email'
                options={[
                  {
                    label: 'scholar1@',
                    value: '30',
                  },
                  {
                    label: 'scholar1@',
                    value: '40',
                  },
                ]}
                name='earning'
                value={filters.earning}
                // onChange={handleChange}
              />
            </FilterInner>
          </Filters> */}
        </InnerGrid>
        <Grid>
          <Earnings className="earnings">
            <div className="row space-between">
              <h2 className="title">Top Earnings</h2>
              <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                <Menu className="menu">
                  <button onClick={() => setMenuOpen(!menuOpen)}>
                    <p className="font-size-12">{earningFilter}</p>
                    <img src={assets.downArrow} alt="" />
                  </button>
                  {menuOpen && (
                    <MenuPopup>
                      <div
                        className="menu-item"
                        onClick={() => {
                          setEarningFilter("Daily");
                          setMenuOpen(false);
                        }}
                      >
                        <p className="font-size-14">Daily</p>
                      </div>
                      <div
                        className="menu-item"
                        onClick={() => {
                          setEarningFilter("Weekly");
                          setMenuOpen(false);
                        }}
                      >
                        <p className="font-size-14">Weekly</p>
                      </div>
                      <div
                        className="menu-item"
                        onClick={() => {
                          setEarningFilter("Monthly");
                          setMenuOpen(false);
                        }}
                      >
                        <p className="font-size-14">Monthly</p>
                      </div>
                    </MenuPopup>
                  )}
                </Menu>
              </ClickAwayListener>
            </div>
            {/* <div className="row">
              <img src={assets.riot} alt="" />
              <div>
                <p className="font-size-20">
                  0 <span className="font-size-12">RIOT</span>
                </p>
                <p className="font-size-10" style={{ opacity: "0.6" }}>
                  ≈ 0 USD
                </p>
              </div>
            </div> */}
            <div className="row">
              <img src={assets.stzIcon} alt="" />
              <div>
                <p className="font-size-20">
                  0 <span className="font-size-12">STZ</span>
                </p>
                <p className="font-size-10" style={{ opacity: "0.6" }}>
                  ≈ 0 USD
                </p>
              </div>
            </div>
            {/* <div className='row'>
              <img src={assets.stzIcon} alt='' />
              <div>
                <p className='font-size-20'>
                  0 <span className='font-size-12'>THC</span>
                </p>
                <p className='font-size-10' style={{ opacity: '0.6' }}>
                  ≈ 0 USD
                </p>
              </div>
            </div> */}
          </Earnings>
          <Graph className="graph">
            <div className="row">
              <div className="row-item">
                <p
                  className="font-size-18"
                  style={{ fontFamily: "Stalinist One" }}
                >
                  0%
                </p>
                <p className="font-size-10">this week</p>
              </div>
              <div className="row-item">
                <p
                  className="font-size-18"
                  style={{ fontFamily: "Stalinist One" }}
                >
                  0%
                </p>
                <p className="font-size-10">last week</p>
              </div>
            </div>

            <div>
              <ChartComponent
                options={options}
                series={series}
                type="line"
                height={195}
                width="100%"
              />
            </div>
          </Graph>
        </Grid>
        {/* <TableContainer>
          <table>
             Table Header 
            <thead>
              <tr className="header-row">
                <th>Game Rank</th>
                <th>Game</th>
                <th>Earning</th>
                <th>Period</th>
                <th>Revenue Split</th>
                <th>W/L Ratio</th>
                <th>Time spent today</th>
                <th>Time Spent This Month</th>
                <th></th>
              </tr>
            </thead>

            Table body 
            <tbody>
              {Array.from({ length: 1 }, (_, i) => i).map((i, index) => (
                <tr key={i}>
                  <td>
                     {getRank(i + 1)} 
                    -----
                  </td>
                  <td>
                    <img src={assets.riotRacers} alt="" />
                  </td>
                  <td>
                    <div className="row">
                      <img src={assets.riot} alt="" />
                      <div>
                        <p className="font-size-14">0 RIOT</p>
                        <p className="font-size-10">≈ 0 USD</p>
                      </div>
                    </div>
                  </td>
                  <td>---------</td>
                  <td>30/70</td>
                  <td>0/0</td>

                  <td>0 hrs</td>
                  <td>0 hrs</td>

                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer> */}
      </Page>
    </>
  );
};
export default IndividualPerformance;

// const FilterInner = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr 1fr;
//   gap: 24px;
//   /* padding-bottom: 20px; */
//   padding-top: 20px;
// `;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 24px;
  margin-top: 30px;
  @media only screen and (max-width: 756px) {
    grid-template-columns: 1fr;
  }
`;
const InnerGrid = styled.div`
  padding-top: 20px;
`;
const Earnings = styled.div`
  padding: 8px 20px;
  background-color: #121212;
  & .row {
    display: flex;
    padding: 20px 20px;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #ffffff33;
    padding-bottom: 20px;
    padding-top: 20px;

    &:last-of-type {
      border-bottom: none;
    }

    &.space-between {
      justify-content: space-between;
    }

    & img {
      max-width: 30px;
    }
  }
`;
const Menu = styled.div`
  position: relative;
  & button {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: #000000;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    height: 26px;
    padding: 0px 12px;
  }
`;

const MenuPopup = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(calc(100% + 10px));
  background-color: #000000;
  width: 120px;
  padding: 5px 0px;
  & .menu-item {
    padding: 5px 10px;
    cursor: pointer;
    &:hover {
      background-color: #121212;
    }
  }
`;
const Graph = styled.div`
  background-color: #121212;
  padding: 24px 30px;

  & .row {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: flex-end;

    & .row-item {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      & p:last-of-type {
        opacity: 0.6;
      }
    }
  }

  @media only screen and (min-width: 1000px) {
    & .row {
      padding-right: 100px;
      & .row-item {
        min-width: 184px;
      }
    }
  }
`;
// const TableContainer = styled.div`
//   overflow-x: auto;
//   border: 1px solid #ffffff33;
//   padding-bottom: 10px;
//   width: 100%;
//   margin-block: 60px;
//   border-radius: 4px;

//   & table {
//     border-collapse: collapse;
//     min-width: 950px;
//     width: 100%;
//     & tbody {
//       padding-inline: 10px;
//     }
//     & .header-row {
//       height: 40px;
//       background-color: #0e0e0e;
//       & th {
//         font-size: 10px;
//         line-height: 16px;
//         font-weight: 600;
//         opacity: 0.6;
//         padding-top: 4px;
//         border-bottom: 1px solid #ffffff33;
//         text-align: left;
//         &:first-of-type {
//           padding-left: 32px;
//         }
//       }
//     }
//     & tr {
//       height: 60px;
//     }
//     & td {
//       font-size: 12px;
//       line-height: 18px;
//       font-weight: 400;
//       border-bottom: 1px solid #ffffff33;
//       padding-block: 12px;
//       &:first-of-type {
//         padding-left: 32px;
//       }

//       & p.font-size-24 {
//         font-family: "Stalinist One";

//         & span {
//           font-family: inherit;
//         }
//         &.first {
//           background: linear-gradient(180deg, #ffe99d 0%, #f7ae3f 100%);
//           -webkit-background-clip: text;
//           -webkit-text-fill-color: transparent;
//           background-clip: text;
//         }

//         &.second {
//           background: linear-gradient(180deg, #bcb8dd 0%, #6667a8 100%);
//           -webkit-background-clip: text;
//           -webkit-text-fill-color: transparent;
//           background-clip: text;
//         }

//         &.third {
//           background: linear-gradient(180deg, #f3b785 0%, #d37e47 100%);
//           -webkit-background-clip: text;
//           -webkit-text-fill-color: transparent;
//           background-clip: text;
//         }
//       }
//       & p.second {
//         font-family: "Stalinist One";
//         background: linear-gradient(180deg, #bcb8dd 0%, #6667a8 100%);
//         -webkit-background-clip: text;
//         -webkit-text-fill-color: transparent;
//         background-clip: text;

//         & span {
//           font-family: inherit;
//         }
//       }
//       & p.third {
//         font-family: "Stalinist One";
//         background: linear-gradient(180deg, #f3b785 0%, #d37e47 100%);
//         -webkit-background-clip: text;
//         -webkit-text-fill-color: transparent;
//         background-clip: text;

//         & span {
//           font-family: inherit;
//         }
//       }
//       & p.nth {
//         font-family: "Stalinist One";

//         & span {
//           font-family: inherit;
//         }
//       }

//       & img {
//         max-height: 40px;
//         max-width: 105px;
//         /* margin-left: 10px; */
//       }

//       & .row {
//         display: flex;
//         align-items: center;
//         gap: 10px;

//         & img {
//           width: 20px;
//         }
//       }

//       & .icon-button {
//         & img {
//           padding-inline: 5px;
//           opacity: 0.4;
//           cursor: pointer;
//           &:hover {
//             opacity: 1;
//           }
//         }
//         & .menu {
//           position: absolute;
//           width: 200px;
//           background: #0e0e0e;
//           box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
//           transform: translate(-90%, -115%);
//           box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
//           display: none;
//           & .menu-item {
//             padding: 10px 5px;
//             cursor: pointer;
//             &:hover {
//               background-color: #000000;
//             }
//           }
//           & p {
//             opacity: 0.6;
//           }
//           &.show {
//             display: block;
//           }
//         }
//       }
//     }
//     & tr:last-child {
//       & td {
//         border: none;
//       }
//     }
//   }
// `;
