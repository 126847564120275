import React, { FC } from 'react';

import styled from 'styled-components';
import classNames from 'classnames';

import assets from '../../assets';
import { IGameTokenBalance } from '../../types/interfaces';

interface IProps {
  fullWidth?: boolean;
  tokenBalance: IGameTokenBalance | null;
  [x: string]: any;
}

const TokenAmountSelector: FC<IProps> = ({
  fullWidth,
  tokenBalance,
  ...rest
}) => {
  // const [menu, setMenu] = useState(false);

  // const handleToggleMenu = () => {
  //   setMenu(!menu);
  // };

  return (
    <StyledSelector className={classNames(fullWidth && 'full-width')}>
      <p className='font-size-10 label'>Enter the Amount</p>
      <div className='container'>
        <label htmlFor='input' className='left'>
          <input
            type='number'
            id='input'
            className='font-size-18'
            placeholder='Amount'
            {...rest}
          />
          <span className='font-size-10' style={{ opacity: '0.4' }}>
            ≈ 0,00 USD
          </span>
        </label>
        <div className='right'>
          <div className='row' style={{ justifyContent: 'center' }}>
            <div className='token'>
              <img src={assets.stzIcon} alt='' />
              <span className='font-size-16'>{tokenBalance?.symbol}</span>
            </div>
          </div>
        </div>
      </div>
      {/* {menu && (
          <Menu>
            <div className='menu-item' onClick={() => setMenu(false)}>
              <div className='row'>
                <img src={assets.stzIcon} alt='' />{' '}
                <p className='font-size-14'>STZ</p>
              </div>
            </div>
            <div className='menu-item' onClick={() => setMenu(false)}>
              <div className='row'>
                <img src={assets.THC} alt='' />{' '}
                <p className='font-size-14'>THC</p>
              </div>
            </div>
          </Menu>
        )} */}
    </StyledSelector>
  );
};

export default TokenAmountSelector;

const StyledSelector = styled.div`
  width: 288px;
  position: relative;
  &.full-width {
    width: 100%;
  }
  & .label {
    margin-left: 8px;
    opacity: 0.6;
    margin-bottom: 6px;
  }

  & .container {
    height: 70px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    & .left {
      border: 1px solid #ffffff;
      border-radius: 4px 0px 0px 4px;
      display: flex;
      align-items: center;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      cursor: pointer;

      & input {
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
      }
    }

    & .right {
      border: 1px solid #ffffff33;
      border-radius: 0px 4px 4px 0px;
      display: flex;
      align-items: center;
      padding: 16px;
      cursor: pointer;
      & .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        & .token {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }
`;
// eslint-disable-next-line
const Menu = styled.div`
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  background-color: #000000;
  transform: translateY(100%);
  z-index: 10;

  & .menu-item {
    padding: 10px 20px;
    cursor: pointer;
    &:hover {
      background-color: #ffffff1a;
    }
    & .row {
      display: flex;
      align-items: center;
      gap: 8px;
      & img {
        width: 30px;
      }
    }
  }
`;
