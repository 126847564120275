import { FC, InputHTMLAttributes } from 'react';
import styled, { CSSProperties } from 'styled-components';
import assets from '../../assets';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  wrapperStyles?: CSSProperties;
}

const Search: FC<IProps> = ({ wrapperStyles, ...rest }) => {
  return (
    <StyledSearch className='search-bar' style={{ ...wrapperStyles }}>
      <img src={assets.searchIcon} alt='' />

      <input type='text' className='font-size-12 search-field' placeholder='Search' {...rest} />
    </StyledSearch>
  );
};

export default Search;

const StyledSearch = styled.div<IProps>`
  background-color: #121212;
  width: 310px;
  padding: 11px 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  img {
    opacity: 0.6;
  }
  input {
    background-color: transparent;
    border: none;
    outline: none;
  }
`;
