import React, { FC, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import DashboardLayout from '../layouts/DashboardLayout';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import Login from '../pages/Login/Login';
import PasswordReset from '../pages/PasswordReset/PasswordReset';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../store';

import PrivateOutlet from './PrivateOutlet/PrivateOutlet';
import RedirectOutlet from './RedirectOutlet/RedirectOutlet';
import Vault from '../pages/Vault/Vault';
import Earnings from '../pages/Earnings/Earnings';
import PayoutHistory from '../pages/PayoutHistory/PayoutHistory';
// import Performance from '../pages/Performance/Performance';
// import GameSelection from '../pages/GameSelection/GameSelection';
import Pullbacks from '../pages/Pullbacks/Pullbacks';
import AdminTimeline from '../pages/AdminTimeline/AdminTimeline';
import Notification from '../pages/Notification/Notification';
import Games from '../pages/Games/Games';
import AddNewGame from '../pages/AddNewGame/AddNewGame';
import Home from '../pages/Home/Home';
import UserDetails from '../pages/UserDetails/UserDetails';
import IndividualPerformance from '../pages/IndividualPerformance/IndividualPerformance';
import NotificationList from '../pages/NotificationList/NotificationList';
import Captains from '../pages/Captains/Captains';
import CaptainProfile from '../pages/CaptainProfile/CaptainProfile';

const Index: FC = () => {
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch.auth.getUser();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Routes>
      {/* Public Routes */}
      <Route path='/' element={<RedirectOutlet />}>
        <Route path='' element={<Navigate to='/login' replace={true} />} />
        <Route path='login' element={<Login />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='reset-password' element={<PasswordReset />} />
      </Route>

      {/* Private Routes */}
      <Route path='/' element={<PrivateOutlet />}>
        <Route path='/dashboard' element={<DashboardLayout />}>
          <Route
            path=''
            element={<Navigate to='/dashboard/performance' replace={true} />}
          />
          <Route path='games' element={<Games />} />
          <Route path='games/add-new-game' element={<AddNewGame />} />
          <Route path='games/update-game' element={<AddNewGame />} />
          <Route path='vault' element={<Vault />} />
          <Route path='earnings' element={<Earnings />} />
          <Route path='payout-management' element={<Earnings />} />
          <Route path='payout-history' element={<PayoutHistory />} />
          <Route path='performance' element={<Home />} />
          <Route path='pullbacks' element={<Pullbacks />} />
          <Route path='timeline' element={<AdminTimeline />} />
          <Route path='notifications' element={<Notification />} />
          <Route path='notifications/:id' element={<NotificationList />} />
          <Route path='user/:id' element={<UserDetails />} />
          <Route path='captain-scholars' element={<NotificationList />} />
          <Route path='captains' element={<Captains />} />
          <Route path='captain-details' element={<CaptainProfile />} />
          <Route
            path='user-performance/:id'
            element={<IndividualPerformance />}
          />
        </Route>
      </Route>
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default Index;
