import React, { FC, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import styled from 'styled-components';
import classNames from 'classnames';

import assets from '../assets';
import theme from '../theme';

import { pullBackNftHistory } from '../http'; // add this api to withdraw method
import { INfts, IScholarSet } from '../types/interfaces';

import { showToast, getFormatedTime } from '../utils';
import NftImage from '../components/NftImage/NftImage';
// import Loader from '../components/Loader/Loader';
dayjs.extend(utc);

interface IProps {
  set: IScholarSet;
  scholarId: string;
}

const AssetsPullRow: FC<IProps> = ({ set, scholarId }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [pulled, setPulled] = useState(false);

  const [history, setHistory] = useState({
    date: '',
    txHash: [],
  });

  const handleRenderAssets = (
    assetsList: Array<{ tokenIdMetadata: INfts; set_number: number }>
  ): any => {
    let newAssets;
    let remaining;
    if (assetsList.length > 5) {
      newAssets = assetsList.slice(0, 5);
      remaining = assetsList.length - 5;
    } else {
      newAssets = assetsList;
    }

    return (
      <div className='assets-container'>
        {newAssets.map((set, index) => (
          <NftImage nftData={set.tokenIdMetadata} size={30} key={index} />
        ))}
        {remaining && (
          <p className='font-size-8 more-text'>+ {remaining} more</p>
        )}
      </div>
    );
  };

  const handlePullBack = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    try {
      setLoading(true);
      // const { data } = await pullbackNfts({
      //   game_id: set.game_id,
      //   set_id: set.Sets[0].set_number.toString(),
      //   user_id: scholarId,
      // });
      // console.log(data);
      // const hashes: any = [];
      // const date = data.transaction[0].created_at;
      // data.transaction.forEach((tx: any) => {
      //   const hash = JSON.parse(tx.response).transactionHash;
      //   hashes.push(hash);
      // });
      // setHistory({ date: date, txHash: hashes });
      // showToast('success', 'Nfts pulled back successfuly!');
      // setPulled(true);
    } catch (err: any) {
      console.log(err.message);
      showToast('error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const getPullbackHistory = async () => {
    try {
      const { data } = await pullBackNftHistory({
        game_id: set.game_id,
        set_id: set.Sets[0].set_number.toString(),
        user_id: scholarId,
      });

      if (data.transaction.length) {
        const hashes: any = [];
        const date = data.transaction[0].created_at;
        data.transaction.forEach((tx: any) => {
          const hash = JSON.parse(tx.response).hash;
          hashes.push(hash);
        });
        setHistory({ date: date, txHash: hashes });
      } else {
        setHistory({ txHash: [], date: '' });
      }
    } catch (err) {}
  };

  useEffect(() => {
    getPullbackHistory();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Row
        onClick={() => setOpen(!open)}
        style={{ background: open ? '#0d0d0d' : '' }}
      >
        <div className='row-item'>
          <img src={set.game_logo} alt='' className='game-image' />
        </div>
        <div className='row-item'>
          <img src={set.badge_logo} alt='' className='badge-image' />
        </div>
        <div className='row-item assets'>{handleRenderAssets(set.Sets)}</div>
        <div className='row-item flex'>
          {loading ? (
            <div className='spinner'>
              <div></div>
            </div>
          ) : (
            <button
              className='font-size-12'
              onClick={handlePullBack}
              disabled={pulled}
            >
              {pulled ? 'Completed' : 'Withdraw'}
            </button>
          )}
          <img
            src={assets.downArrow}
            style={{
              cursor: 'pointer',
              transform: open ? 'rotate(-180deg)' : '',
              transition: '0.4s ease',
            }}
            alt=''
          />
        </div>
      </Row>
      <div className={classNames('dropdown', open && 'open')}>
        {loading ? (
          <div className='spinner' style={{ marginInline: 'auto' }}>
            <div></div>
          </div>
        ) : (
          <div className='wrapper'>
            <div className='exchange'>
              <img src={assets.exchangeIcon} alt='' />
            </div>
            <div className='column'>
              <p className='font-size-10'>
                Excahnged <span>0.3322 STZ</span> ($1.22) with{' '}
                <span>BITCOIN</span>
              </p>
              <div className='date'>
                <p className='font-size-10'>Date</p>
                <p className='font-size-10'>
                  <>{history.date && getFormatedTime(history.date)}</>
                </p>
              </div>
              <div className='transaction-ids'>
                <p className='font-size-10'>TRANSATION ID</p>
                {history.txHash.map((hash, index) => (
                  <p key={index} className='font-size-10'>
                    {hash}
                  </p>
                ))}
              </div>
            </div>
            <div className='column'>
              <p className='font-size-10'>
                <b>Status</b>: Confirmed
              </p>
              <div className='status'>
                <p className='font-size-10'>Exchange details</p>
                <p className='font-size-10'>
                  <span>0.32265656 STZ</span> for <span>0.000002 BTC</span>{' '}
                </p>
              </div>
            </div>
            <div className='column'>
              <p className='font-size-10'>2 minute ago </p>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default AssetsPullRow;

const Container = styled.div`
  border-bottom: 1px solid #ffffff33;
  &:last-child {
    border-bottom: none;
  }
  & .dropdown {
    max-height: 0px;
    background-color: #0d0d0d;
    overflow: hidden;
    transition: max-height 0.4s ease;

    & .wrapper {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      & .date,
      & .status {
        margin-block: 20px;
      }
    }
    &.open {
      max-height: 180px;
      overflow-y: auto;
    }
    & p {
      opacity: 0.6;
      & span {
        color: ${theme.palette.secondary};
      }
    }
  }
  & .spinner {
    height: 20px;
    width: 20px;
    border-top: 2px solid ${theme.palette.primary};
    border-bottom: 2px solid ${theme.palette.primary};
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
`;

const Row = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  padding-inline: 10px;
  & .row-item {
    flex: 1;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #ffffff;
    min-width: 100px;
    &.assets {
      min-width: 250px;
    }

    &.flex {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    & .game-image {
      height: 40px;
      max-width: 95px;
    }

    & .badge-image {
      height: 30px;
      width: 30px;
    }

    & button {
      border: none;
      outline: none;
      cursor: pointer;
      background-color: #ffffff33;
      padding: 4px 12px;
      z-index: 1;
    }
  }
  &:last-child {
    border-bottom: none;
  }
  & .assets-container {
    display: flex;
    gap: 8px;
    align-items: center;
    & .asset {
      height: 30px;
      width: 30px;
      border-radius: 6px;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    & .more-text {
      font-size: 8px;
      line-height: 14px;
      opacity: 0.6;
    }
  }
`;
