import React, { FC, useState, useEffect } from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../store';

import classNames from 'classnames';
import styled from 'styled-components';
import assets from '../assets';
import theme from '../theme';

import ClickAwayListener from 'react-click-away-listener';

import { INavItem } from '../types/interfaces';
import ErrorDialog from '../dialogs/ErrorDialog';
import FeedBack from '../dialogs/FeedBack';
import Footer from '../components/Footer/Footer';

// import Dialogs from '../dialogs';
// import Button from '../components/Button/Button';

const navItems: Array<INavItem> = [
  {
    title: 'Performance',
    img: assets.performance,
    to: '/dashboard/performance',
  },
  {
    title: 'Games',
    img: assets.games,
    to: '/dashboard/games',
  },

  {
    title: 'Earnings',
    img: assets.earnings,
    to: '/dashboard/earnings',
  },
  // {
  //   title: 'Voting',
  //   img: assets.voting,
  //   to: '/dashboard/voting',
  // },
  {
    title: 'Vault',
    img: assets.vault,
    to: '/dashboard/vault',
  },
  {
    title: 'captains',
    img: assets.vault,
    to: '/dashboard/captains',
  },
  // {
  //   title: 'Assets pullback',
  //   img: assets.pullBack,
  //   to: '/dashboard/pullbacks',
  // },
  // {
  //   title: 'Admin Timeline',
  //   img: assets.timeLine,
  //   to: '/dashboard/timeline',
  // },
];

const DashboardLayout: FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const sideBar = useSelector((state: RootState) => state.sideBar.open);
  // const walletAddress = useSelector(
  //   (state: RootState) => state.auth.walletAddress
  // );

  const user = useSelector((state: RootState) => state.auth.user);

  const [showToolip, setShowTooltip] = useState(false);
  const [menuTooltip, setMenuTooltip] = useState(false);

  // const handleCopyAddress = () => {
  //   navigator.clipboard.writeText(walletAddress);
  // };

  const handleLogout = () => {
    dispatch.auth.logOut();
    navigate('/', { replace: true });
  };

  // const truncateString = (value: string): string => {
  //   const start = value.slice(0, 4);
  //   const end = value.slice(value.length - 4, value.length);
  //   return `${start}...${end}`;
  // };

  useEffect(() => {
    setTimeout(() => {
      setShowTooltip(false);
      setMenuTooltip(false);
    }, 700);
  }, [showToolip, menuTooltip]);

  useEffect(() => {
    dispatch.auth.getUser();
    window.addEventListener('resize', () => {
      if (window.innerWidth < 700) {
        dispatch.sideBar.sideBarHidden(true);
      }
      if (window.innerWidth >= 700) {
        dispatch.sideBar.sideBarHidden(false);
      }
    });
    // console.log('render');
    // dispatch.scholars.getScholars();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Topbar className={classNames(sideBar ? 'm-lg' : 'm-sm')}>
        <div className='top'>
          <div>
            <img src={assets.brandLogo} className='logo' alt='' />
          </div>
          {/* TODO: Fix centering this later */}
          <div className='mid' style={{ marginLeft: '120px' }}>
            {/* {walletAddress ? (
              <>
                <img src={assets.walllet} alt='' />
                <p className='wallet-connection bg-grey'>
                  {truncateString(walletAddress)}
                </p>
                <img
                  src={assets.copyIcon}
                  onClick={() => {
                    handleCopyAddress();
                    setShowTooltip(true);
                  }}
                  className='copy-icon'
                  alt=''
                />
                <p className={classNames('tooltip', showToolip && 'show')}>
                  Copied!
                </p>
              </>
            ) : (
              <>
                <img src={assets.walllet} alt='' />
                <p className='wallet-connection'>Not connected</p>
              </>
            )} */}
          </div>
          <div className='right'>
            {/* <Button
              variant='primary'
              style={{ marginRight: '40px', padding: '8px 35px' }}
            >
              Buy STZ
            </Button> */}
            <div className='row'>
              <img
                src={assets.notification}
                className='notification-icon'
                onClick={() => navigate('/dashboard/notifications')}
                alt=''
                style={{ cursor: 'pointer' }}
              />
              <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                <Menu>
                  <div
                    onClick={() => setMenuOpen(!menuOpen)}
                    style={{ cursor: 'pointer' }}
                    className='menu-opener'
                  >
                    <img
                      src={user?.image_url || assets.user}
                      className='user-image'
                      alt=''
                    />
                    <p className='font-size-14'>{user?.fullname}</p>
                    <img src={assets.downArrow} alt='' />
                  </div>
                  {menuOpen && (
                    <div className='menu-popup'>
                      <div
                        className='menu-item'
                        onClick={() => setMenuOpen(false)}
                      >
                        <p className='font-size-14'>My profile</p>
                      </div>
                      <div className='menu-item logout' onClick={handleLogout}>
                        <p className='font-size-12'>Logout from 99Starz</p>
                      </div>
                    </div>
                  )}
                </Menu>
              </ClickAwayListener>
            </div>
          </div>
        </div>
      </Topbar>
      <SideBar
        className={classNames(sideBar ? 'open' : 'close')}
        onMouseLeave={() => dispatch.sideBar.closeSideBar()}
      >
        <div className='top-section'>
          <img
            src={assets.brandLogo}
            style={{ marginLeft: sideBar ? '24px' : '' }}
            className='brand-logo'
            alt=''
          />
        </div>

        <div className='middle-section'>
          <ul className='nav-links'>
            {navItems.map((item, index) => (
              <NavLink
                end={item.to === '/dashboard/games' ? false : true}
                key={index}
                to={item.to}
                className={navData => (navData.isActive ? 'active' : 'link')}
              >
                <li className={classNames(sideBar ? 'open' : 'close')}>
                  <img src={item.img} alt='' />
                  <p className='font-size-14'>{item.title}</p>
                </li>
              </NavLink>
            ))}
          </ul>
        </div>
        <div className='bottom-section'>
          <ul className='nav-links'>
            {/* <NavLink
              to='/help-and-support'
              className={navData => (navData.isActive ? 'active' : 'link')}
            >
              <li className={classNames(sideBar ? 'open' : 'close')}>
                <img src={assets.msg} alt='' />
                <p className='font-size-14'>Help & Support</p>
              </li>
            </NavLink> */}
          </ul>
          <div className='divider' />
          <p
            className='font-size-12'
            style={{
              minWidth: '10px',
              width: '100%',
              textAlign: 'center',
              opacity: 0.4,
            }}
          >
            v1.04
          </p>
        </div>
      </SideBar>
      <Content className={classNames(sideBar ? 'open' : 'close')}>
        {/* <BreadCrums>
          <p
            className={classNames("font-size-10", sideBar ? "m-lg" : "m-sm")}
            style={{ opacity: 0.4 }}
          >
            Scholarship Panel | Become a scholar | Fill the Scholarship form
          </p>
        </BreadCrums> */}
        <Outlet />
        <Footer />
      </Content>
      <BottomBar>
        {navItems.map((item, index) => (
          <NavLink
            end
            key={index}
            to={item.to}
            className={navData => (navData.isActive ? 'active' : 'link')}
          >
            <div className='tab'>
              <img src={item.img} alt='' />
              <p className='tab-label'>{item.title}</p>
            </div>
          </NavLink>
        ))}
      </BottomBar>

      <ToastContainer />
      <ErrorDialog />
      <FeedBack />
    </Container>
  );
};

export default DashboardLayout;

const Container = styled.div`
  min-height: 100vh;
  height: 100vh;
  max-height: auto;
`;

const Topbar = styled.div`
  height: 112px;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  transition: 0.3s ease;
  & .logo {
    display: none;
  }
  padding-left: 104px;
  /* &.m-sm {
    padding-left: 8px;
  }
  &.m-lg {
    padding-left: 260px;
  } */
  & .top {
    height: 80px;
    background-color: ${theme.palette.black};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 32px;
    & .mid {
      display: flex;
      align-items: center;
      position: relative;
      margin-left: 120px;
      & p {
        background-color: #ff000266;
        padding: 4px;
        border-radius: 2px;
        margin-left: 10px;
      }
      & p.bg-grey {
        background-color: #ffffff1a;
        margin-right: 6px;
      }
      & .copy-icon {
        cursor: pointer;
      }
      & .tooltip {
        position: absolute;
        left: 105%;
        top: calc(50% - 13px);
        background-color: #ffffff33;
        padding-inline: 5px;
        border-radius: 2px;
        font-size: 12px;
        height: 25px;
        transform: scale(0.1);
        opacity: 0;
        pointer-events: none;
        transition: 0.2s ease;
        &.show {
          transform: scale(1);
          opacity: 1;
        }
      }
    }
    & .right {
      display: flex;
      align-items: center;
      & .notification-icon {
        margin-right: 32px;
      }
      & .user-image {
        height: 48px;
        width: 48px;
        object-fit: cover;
        border-radius: 50%;
      }
      & .row {
        display: flex;
        align-items: center;
        gap: 37px;
      }
    }
  }
  & .bottom {
    flex-grow: 1;
    display: flex;
    align-items: center;
    border-top: 1px solid #ffffff1a;
    border-bottom: 1px solid #ffffff1a;
    background-color: ${theme.palette.black};
    & p {
      margin-left: 30px;
    }
  }
  @media only screen and (max-width: 700px) {
    &.m-sm {
      padding-left: 0px;
    }
    &.m-lg {
      padding-left: 0px;
    }

    & .top {
      height: 60px;
      padding-inline: 25px;
      justify-content: space-between;
      & .mid {
        margin-left: 0px;
      }
      & .logo {
        display: inline-block;
      }
      & .right {
        display: flex;
        align-items: center;

        & .user-image {
          height: 36px;
          width: 36px;
          object-fit: cover;
          border-radius: 50%;
          margin-top: 4px;
        }
      }
    }
    & .bottom {
      display: none;
    }
  }
`;

const SideBar = styled.div`
  min-height: 100%;
  background-color: #121212;
  z-index: 102;
  position: fixed;
  transition: 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-block: 20px;
  justify-content: space-between;
  width: 104px;
  /* &.open {
    width: 260px;
  }
  &.close {
    width: 80px;
  } */
  & .link {
    text-decoration: none;
    text-transform: none;
  }
  & .nav-links {
    list-style-type: none;
    width: 100%;

    & .active {
      & li::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 2px;
        background-color: ${theme.palette.primary};
      }
      & li {
        background-color: #ffffff1a;
        height: 60px;
        & img {
          opacity: 1;
        }
        & p {
          opacity: 1;
        }
      }
    }
    & li {
      position: relative;
      height: 52px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-block: 5px;
      gap: 7px;
      & img {
        height: 16px;
        width: 16px;
        opacity: 0.4;
      }
      & p {
        line-height: 1;
        left: 48px;
        font-size: 6px;
        transition: 0.3s ease;
        opacity: 0.4;
        color: #ffffff;
      }
    }
    /* & li.close {
      padding-inline: 0px;
      text-align: center;
      justify-content: center;
      & p {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 100%;
      }
    } */
  }
  & .top-section {
    display: flex;
    & img {
      transition: 0.3s ease;
    }
    justify-content: center;
    .brand-logo {
      height: 44px;
      width: 56px;
      /* margin-left: 14px; */
    }
    .menu-icon {
      margin-top: 28px;
      height: 11px;
      width: 19px;
      margin-left: 30px;
      cursor: pointer;
    }
  }
  & .bottom-section {
    & .divider {
      border-top: 1px solid #ffffff33;
    }
    & .font-size-12 {
      padding-inline: 24px;
      margin-block: 14px 20px;
      min-width: 250px;
    }
  }
  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

const Content = styled.div`
  height: 100%;
  transition: 0.3s ease;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  /* &.open {
    padding-inline: 260px 0px;
  }
  &.close {
    padding-inline: 80px 0px;
  } */
  padding-inline: 104px 0px;
  @media only screen and (max-width: 700px) {
    padding-block: 60px;
    &.open {
      padding-inline: 0px;
    }
    &.close {
      padding-inline: 0px;
    }
  }
`;

const Menu = styled.div`
  position: relative;

  & .menu-opener {
    display: flex;
    align-items: center;

    & p.font-size-14 {
      margin-inline: 16px 6px;
    }
  }
  & .menu-popup {
    position: absolute;
    top: 85%;
    right: 0;
    width: 184px;
    background-color: #0e0e0e;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    padding-block: 4px;

    & .menu-item {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      cursor: pointer;
      & p {
        opacity: 0.4;
      }
      &:last-child {
        border-bottom: none;
      }

      &:hover {
        & p {
          opacity: 1;
        }
        background-color: #000000;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    & .menu-popup {
      width: 240px;
      & .profile {
        display: none;
      }
      & .menu-item {
        &.hidden-mobile {
          display: flex;
        }
        &.logout {
          border-bottom: 1px solid #ffffff33;
        }
      }
    }
  }
`;

const BottomBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #0e0e0e;
  display: none;
  justify-content: center;

  & .tab {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 14px 15px;
    & .tab-label {
      font-size: 8px;
      line-height: 14px;
      color: #ffffff;
      opacity: 0.4;
    }

    & img {
      opacity: 0.4;
      margin-bottom: 2px;
      height: 16px;
      width: 16px;
    }
  }
  & .active {
    & .tab {
      background-color: #ffffff1a;
      & .tab-label {
        opacity: 1;
      }

      & img {
        opacity: 1;
      }
    }
  }
  @media only screen and (max-width: 700px) {
    display: flex;
  }
  @media only screen and (max-width: 357px) {
    & .tab {
      padding: 10px;
      & .tab-label {
        font-size: 6px;
      }
    }
  }
`;

// const BreadCrums = styled.div`
//   min-height: 32px;
//   display: flex;
//   align-items: center;
//   border-top: 1px solid #ffffff1a;
//   border-bottom: 1px solid #ffffff1a;
//   background-color: ${theme.palette.black};
//   & p {
//     margin-left: 30px;
//   }
// `;
