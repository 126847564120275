import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useNavigate } from 'react-router-dom';

import DialogLayout from './Dialog';
import Button from '../components/Button/Button';
import InputField from '../components/InputField/InputField';
import { badgeImagesByName } from '../app.config';

import assets from '../assets';

import { showToast, truncateString } from '../utils';
import { getUserScholarshipById, updateUserScholarship } from '../http';

interface IUserScholarship {
  about: string;
  badge_id: string;
  country: string;
  created_at: string;
  discord: string;
  email: string;
  game_id: string;
  id: string;
  image_url: string;
  is_accepted: number;
  logo: string;
  name: string;
  scholarship_id: string;
  starz_wallet_address: string;
  title: string;
  updated_at: string;
  user_id: string;
  nick_name: string;
}

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotificationDetails: FC<IProps> = ({ open, setOpen }) => {
  const navigate = useNavigate();

  const notification = useSelector(
    (state: RootState) => state.notifications.selected
  );
  const handleCopyAddress = (address: string) => {
    navigator.clipboard.writeText(address);
    showToast('success', 'Address copied successfully!');
  };

  const [loading, setLoading] = useState(false);

  const [scholarshipData, setScholarshipData] =
    useState<IUserScholarship | null>(null);

  const handleRejectScholarship = async () => {
    try {
      setLoading(true);
      if (notification) {
        await updateUserScholarship(notification?.scholarship_id, {
          status: 2,
        });
      }
      setOpen(false);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAcceptScholarship = async () => {
    try {
      setLoading(true);
      if (notification) {
        await updateUserScholarship(notification?.scholarship_id, {
          status: 1,
        });
        navigate('/dashboard/vault');
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (notification) {
          const { data: scholarship } = await getUserScholarshipById(
            notification.scholarship_id
          );
          setScholarshipData(scholarship);
        }
      } catch (err: any) {
        console.log(err.message);
      }
    })();
    // eslint-disable-next-line
  }, []);
  return (
    <DialogLayout open={open}>
      <Container>
        <div className='close-icon-container'>
          <img
            src={assets.closeIcon}
            style={{ cursor: 'pointer' }}
            onClick={() => setOpen(false)}
            alt=''
          />
        </div>
        <Content>
          <div className='game-logo'>
            <img src={scholarshipData?.logo} alt='' />
          </div>
          <div className='badge'>
            <div className='row'>
              <img
                src={
                  (scholarshipData?.name &&
                    badgeImagesByName[scholarshipData?.name]) ||
                  assets.goldBadge
                }
                alt=''
              />
              <p className='font-size-14 title'>required</p>
            </div>
          </div>
          <Message>
            <h1 className='title'>{scholarshipData?.title}</h1>
            <p className='font-size-14' style={{ opacity: '0.4' }}>
              {scholarshipData?.about}
            </p>
            <div className='overly'></div>
          </Message>
          <p className='font-size-18 title additional-info'>
            Additional Information
          </p>
          <UserInfo>
            <img
              src={scholarshipData?.image_url || assets.user}
              className='user-image'
              alt=''
            />
            <div>
              <p className='font-size-18'>{scholarshipData?.nick_name}</p>
              <div className='row'>
                <p className='font-size-10'>
                  {scholarshipData?.starz_wallet_address &&
                    truncateString(scholarshipData.starz_wallet_address)}
                </p>
                <img
                  src={assets.copyIcon}
                  style={{ cursor: 'pointer' }}
                  alt=''
                  onClick={() =>
                    handleCopyAddress(
                      scholarshipData?.starz_wallet_address || ''
                    )
                  }
                />
              </div>
            </div>
          </UserInfo>
          <Grid>
            <InputField
              type='text'
              value={scholarshipData?.name}
              placeholder='Badge'
              disabled
            />
            <InputField
              type='email'
              value={scholarshipData?.email}
              placeholder='Email Address '
              disabled
            />
            <InputField
              type='text'
              placeholder='Country*'
              value={scholarshipData?.country}
              disabled
            />
            <InputField
              type='text'
              placeholder='Discord Handle'
              value={scholarshipData?.discord}
              disabled
            />
          </Grid>

          <ButtonContainer>
            <Button
              variant='secondary'
              onClick={handleRejectScholarship}
              disabled={loading}
            >
              Reject
            </Button>
            <Button
              variant='primary'
              onClick={handleAcceptScholarship}
              disabled={loading}
            >
              Confirm Scholarship
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default NotificationDetails;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  & .close-icon-container {
    display: flex;
    justify-content: flex-end;
    padding-block: 6px;
  }
`;

const Content = styled.div`
  width: 100%;
  padding: 40px 30px;
  background: #121212;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;

  & .badge {
    margin-block: 21px 30px;
    & .row {
      display: flex;
      align-items: center;
      gap: 4px;
      & img {
        width: 32px;
      }
    }
  }

  & .additional-info {
    margin-block: 40px 24px;
  }
`;

const Message = styled.div`
  width: 100%;
  max-height: 291px;
  position: relative;
  overflow-y: auto;
  padding: 30px 40px;
  background-color: #000000;
  border-radius: 4px;

  & p {
    margin-top: 20px;
  }

  & .overly {
    /* position: absolute;
    left: 0;
    bottom: 0px;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    height: 30px; */
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 42px;
  & img.user-image {
    height: 60px;
    width: 60px;
    object-fit: cover;
    border-radius: 50%;
  }

  & div.row {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 4px;
    & p {
      background-color: #ffffff1a;
      padding: 2px;
      border-radius: 2px;
    }
  }
`;

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px 38px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 36px;
`;
