import { FC } from 'react';
import styled from 'styled-components';
import InputField from '../../components/InputField/InputField';
import { ISingleScholar } from '../../types/interfaces';

interface IProps {
  captain: ISingleScholar;
}

const CaptainDetails: FC<IProps> = ({ captain }) => {
  return (
    <Container>
      <TabPanel>
        <Grid className='grid'>
          <InputField
            placeholder='Captain Name'
            type='text'
            value={captain.fullname}
            readOnly
            disabled
          />
          <InputField
            placeholder='Emails Address'
            type='text'
            value={captain.email}
            disabled
            readOnly
          />
          <InputField
            placeholder='Wallet Address'
            type='text'
            value={captain.accounts[0].wallet_address}
            disabled
            readOnly
          />
          <InputField
            placeholder='Country'
            type='text'
            value={captain.country}
            disabled
            readOnly
          />
          <InputField
            placeholder='Discord Handle'
            type='text'
            value={captain.discord}
            disabled
            readOnly
          />
        </Grid>
      </TabPanel>
    </Container>
  );
};
export default CaptainDetails;

const Container = styled.div``;
const TabPanel = styled.div`
  margin-top: 50px;
  &.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px 38px;
    margin-top: 52px;
  }
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px 38px;
  margin-top: 52px;
  @media only screen and (max-width: 420px) {
    & .grid {
      grid-template-columns: 1fr;
    }
  }
`;
