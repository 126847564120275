import React, { FC, useState, useRef } from 'react';

import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';

import assets from '../../assets';
import theme from '../../theme';

import NftImage from '../NftImage/NftImage';

import ClickAwayListener from 'react-click-away-listener';
import { INfts, IVaultNft } from '../../types/interfaces';

interface IProps {
  nftData: INfts | IVaultNft;
  onTransferAsset: () => void;
  setTransfer1155: React.Dispatch<React.SetStateAction<boolean>>;
  // onAssignBadge: () => void;
}

const VaultItem: FC<IProps> = ({
  nftData,
  onTransferAsset,
  setTransfer1155,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const [menuOpen, setMenuOpen] = useState(false);

  const metadata = JSON.parse(nftData.metadata);
  const itemName = metadata?.name.split(' ').at(-1);

  const popup = useRef<HTMLDivElement>(null);
  return (
    <Item>
      <div className='container'>
        {+nftData.amount > 1 && (
          <>
            <div
              className='item-count'
              onMouseEnter={() => {
                if (popup.current) {
                  popup.current.classList.add('show');
                }
              }}
            >
              {nftData.amount}
            </div>
            <ClickAwayListener
              onClickAway={() => {
                if (popup.current) popup.current.classList.remove('show');
              }}
            >
              <div
                className='hover-pop-up'
                ref={popup}
                onMouseLeave={() => {
                  if (popup.current) {
                    popup.current.classList.remove('show');
                  }
                }}
              >
                <p className='font-size-10'>RR Car #6794 War Monger</p>
              </div>
            </ClickAwayListener>
          </>
        )}
        {/* {videoSrc ? (
          <div className='video-container'>
            <video src={videoSrc} muted autoPlay loop></video>
          </div>
        ) : (
          <div className='image-container'>
            <img src={imgSrc} alt='' />
          </div>
        )} */}

        <div className='image-container'>
          <NftImage nftData={nftData} container={true} size={170} />
        </div>

        <div className='info'>
          <div className='row'>
            <div>
              <p className='font-size-10'>{nftData.name} #{nftData?.token_id}</p>
              <div className='info-title'>
                <img src={assets.dotImg} alt='' />
                <p className='font-size-12' style={{ fontWeight: '600' }}>
                  {itemName}
                </p>
              </div>
            </div>
            <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
              <div className='menu-container'>
                <img
                  src={assets.moreVert}
                  alt=''
                  className='more-vert'
                  onClick={() => setMenuOpen(!menuOpen)}
                />

                {menuOpen && (
                  <div className='menu'>
                    <div
                      className='menu-item'
                      onClick={() => {
                        dispatch.nfts.setSelectedNft(nftData);
                        if (nftData.contract_type === 'ERC1155') {
                          setTransfer1155(true);
                        } else {
                          onTransferAsset();
                        }
                        setMenuOpen(false);
                      }}
                    >
                      <p className='font-size-12'>Transfer Asset</p>
                    </div>
                    <div
                      className='menu-item'
                      onClick={() => {
                        setMenuOpen(false);
                      }}
                    >
                      <p className='font-size-12'>Delete Asset</p>
                    </div>
                  </div>
                )}
              </div>
            </ClickAwayListener>
          </div>
        </div>
      </div>
    </Item>
  );
};

export default VaultItem;

const Item = styled.div`
  aspect-ratio: 0.77/1;
  & .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    border: 1px solid #ffffff33;
    border-radius: 8px;
    position: relative;

    & .item-count {
      position: absolute;
      top: 10px;
      right: 10px;
      height: 20px;
      width: 20px;
      line-height: 20px;
      text-align: center;
      border: 1px solid ${theme.palette.primary};
      background-color: ${theme.palette.primary + 'cc'};
      border-radius: 50%;
      color: #ffffff;
      font-size: 10px;
      font-weight: 400;
      font-family: 'Stalinist One';
    }

    & .hover-pop-up {
      position: absolute;
      top: 22px;
      right: 22px;
      padding: 4px 8px;
      border-radius: 2px;
      background-color: #0e0e0e;
      transform: scale(0.1);
      z-index: 5;
      opacity: 0;
      pointer-events: none;
      transition: 400ms ease;
      transform-origin: 100% 0%;

      &.show {
        opacity: 1;
        transform: scale(1);
        pointer-events: initial;
      }
    }

    & .image-container {
      height: calc(100% - 58px);
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 8px 8px 0px 0px;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    & .video-container {
      height: calc(100% - 58px);
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px 8px 0px 0px;
      overflow: hidden;
      & video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    & .info {
      padding-block: 10px 14px;
      padding-inline: 14px 14px;
      flex-shrink: 0;
      border-top: 1px solid #ffffff33;
      .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .info-title {
        display: flex;
        align-items: center;
        gap: 4px;
      }
      & .menu-container {
        position: relative;
        .more-vert {
          opacity: 0.4;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
        }
        .menu {
          position: absolute;
          width: 120px;
          background-color: #000000;
          transform: translateX(-95%) translateY(calc(-100% - 30px));
          z-index: 5;
          border-radius: 2px;

          .menu-item {
            padding: 8px 16px;
            cursor: pointer;
            &:hover {
              p {
                opacity: 1;
              }
              background-color: #121212;
              cursor: pointer;
            }
            &:last-child {
              border-bottom: none;
            }
            p {
              opacity: 0.4;
            }
          }
          @media only screen and (max-width: 519px) {
            width: 160px;
            transform: translateX(-80%);
          }
        }
      }
    }
  }
`;
