import React, { FC, useRef } from 'react';

import styled from 'styled-components';

import assets from '../../assets';

interface IProps {
  title: string;
  img?: string;
  children: React.ReactNode;
}

const Accordion: FC<IProps> = ({ title, img, children }) => {
  const panel = useRef<HTMLDivElement>(null);
  const icon = useRef<HTMLImageElement>(null);

  const handleClick = () => {
    if (icon.current) {
      icon.current.classList.toggle('rotate');
    }
    if (panel.current) {
      if (panel.current.style.maxHeight) {
        panel.current.style.maxHeight = '';
      } else {
        panel.current.style.maxHeight = panel.current.scrollHeight + 'px';
      }
    }
  };

  return (
    <AccordionContainer>
      <div className='header' onClick={handleClick}>
        <div className='title'>
          <img src={assets.downArrow} className='icon' alt='' ref={icon} />
          <p className='font-size-14'>{title}</p>
        </div>
      </div>
      <div className='panel' ref={panel}>
        {children}
      </div>
    </AccordionContainer>
  );
};

export default Accordion;

const AccordionContainer = styled.div`
  display: inline-block;
  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .title {
      display: flex;
      grid-gap: 10px;
      align-items: center;
      cursor: pointer;
      & .img-container {
        height: 30px;
        width: 30px;
        display: grid;
        place-items: center;
        background-color: #ffffff1a;
        border-radius: 6px;
      }
    }
    & img.icon {
      transition: 0.4s ease;
      width: 10px;
      &.rotate {
        transform: rotate(180deg);
      }
    }
  }

  & .panel {
    padding: 0px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
  }
`;
