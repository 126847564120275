import React, { FC } from 'react';

interface IProps {
  index: number;
  currentTab: number;
  children: React.ReactNode;
}

const TabPanel: FC<IProps> = ({ index, currentTab, children }) => {
  return <>{index === currentTab && children}</>;
};

export default TabPanel;
