import { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import GameSlider from '../../components/GameSlider/GameSlider';
import Button from '../../components/Button/Button';
import ScholarshipDialog from '../../dialogs/ScholarshipDialog';

import assets from '../../assets';
import Loader from '../../components/Loader/Loader';
import ScholarshipSlider from '../../components/ScholarshipSlider/ScholarshipSlider';

const Games: FC = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<Dispatch>();

  const goldBadgeGames = useSelector(
    (state: RootState) => state.games.goldBadgeGames
  );
  const silverBadgeGames = useSelector(
    (state: RootState) => state.games.silverBadgeGames
  );
  const bronzeBadgeGames = useSelector(
    (state: RootState) => state.games.bronzeBadgeGames
  );
  // const newGames = useSelector((state: RootState) => state.games.newGames);

  const loading = useSelector((state: RootState) => state.games.loading);

  const scholarshipLoading = useSelector(
    (state: RootState) => state.scholarship.loading
  );

  const scholarships = useSelector(
    (state: RootState) => state.scholarship.scholarships
  );

  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    dispatch.scholarship.getScholarships();
    dispatch.chains.getChains();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    dispatch.games.getAllGamesCategory();
    // eslint-disable-next-line
  }, [scholarships]);
  return (
    <Container>
      <Padding>
        <div className='row-title'>
          <h1 className='title'>Games</h1>
          <Button
            variant='transparent'
            onClick={() => {
              dispatch.games.setEditGame(null);
              navigate('/dashboard/games/add-new-game');
            }}
          >
            Add New Game
          </Button>
        </div>
        <h2 className='font-size-20 title' style={{ marginTop: '0px' }}>
          Games Looking for Scholars!
        </h2>

        <ScholarCardsContainer>
          <div className='wrapper'>
            <LookScholar>
              <p className='font-size-20 title'>
                Maybe it is time to form a new team?
              </p>
              <Button
                variant='primary'
                onClick={() => {
                  dispatch.scholarship.setScholarshipToEdit(null);
                  setOpenDialog(true);
                }}
              >
                Create A Scholarship
              </Button>
            </LookScholar>
            {scholarshipLoading && !scholarships.length ? (
              <Loader noPadding />
            ) : (
              <div
                style={{
                  gridColumn: 'span 3',
                }}
              >
                {scholarships.length > 0 && (
                  <ScholarshipSlider
                    editScholarShip={openDialog}
                    setEditScholarShip={setOpenDialog}
                  />
                )}

                {/* {scholarships.map((scholarship: IScholarship, index) => (
                  <ScholarCard
                    scholarship={scholarship}
                    key={index}
                    editScholarShip={openDialog}
                    setEditScholarShip={setOpenDialog}
                    //   setApply={setApply}
                  />
                ))} */}
              </div>
            )}
          </div>
        </ScholarCardsContainer>

        {/* Free to play games */}
        {/* <h2 className='font-size-20 title'>Free to Play!</h2>
        <SliderContainer>
          <GameSlider />
        </SliderContainer> */}
      </Padding>

      {/* New Games */}
      {/* 
      {newGames.length && (
        <SliderBackground
          style={{ backgroundImage: `url(${assets.sliderBg})` }}
        >
          <h2 className='font-size-20 title'>New Games</h2>
          <SliderContainer>
            <GameSlider games={newGames} />
          </SliderContainer>
        </SliderBackground>
      )} */}

      {loading ? (
        <Loader />
      ) : (
        <Padding>
          {/* Gold badge games */}
          {goldBadgeGames.length && (
            <BadgeSlider>
              <div className='row title'>
                <img src={assets.goldBadge} alt='' />
                <p className='font-size-20 title'>
                  Gold Badge Required to Access
                </p>
              </div>
              <GameSlider games={goldBadgeGames} />
            </BadgeSlider>
          )}

          {/* Silver badge games */}
          {silverBadgeGames.length && (
            <BadgeSlider>
              <div className='row title'>
                <img src={assets.silverBadge} alt='' />
                <p className='font-size-20 title'>
                  Silver Badge Required to Access
                </p>
              </div>
              <GameSlider games={silverBadgeGames} />
            </BadgeSlider>
          )}

          {/* Bronze badge games */}

          {bronzeBadgeGames.length && (
            <BadgeSlider>
              <div className='row title'>
                <img src={assets.bronzeBadge} alt='' />
                <p className='font-size-20 title'>
                  Bronze Badge Required to Access
                </p>
              </div>
              <GameSlider games={bronzeBadgeGames} />
            </BadgeSlider>
          )}
        </Padding>
      )}

      {openDialog && (
        <ScholarshipDialog open={openDialog} setOpen={setOpenDialog} />
      )}
    </Container>
  );
};

export default Games;

const Container = styled.div`
  flex-grow: 1;
  & .title {
    margin-bottom: 30px;
  }

  & h2.font-size-20 {
    margin-block: 60px 24px;
  }
`;

const Padding = styled.div`
  padding-inline: 56px;

  & div.row-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* gap: 20px; */
    margin-top: 31px;
    margin-bottom: 31px;
    & h1.title {
      margin-bottom: 0px;
    }
  }

  @media only screen and (max-width: 700px) {
    padding-inline: 25px;
  }
`;

const ScholarCardsContainer = styled.div`
  & .wrapper {
    display: grid;
    /* grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); */
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
  }

  @media only screen and (max-width: 1000px) {
    & .wrapper {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media only screen and (max-width: 800px) {
    & .wrapper {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media only screen and (max-width: 420px) {
    & .wrapper {
      grid-template-columns: 1fr 1fr;
      gap: 20px 16px;
    }
  }
`;

// eslint-disable-next-line
const SliderContainer = styled.div``;
// eslint-disable-next-line
const SliderBackground = styled.div`
  background-size: cover;
  background-position: center;
  padding-block: 40px 60px;
  margin-block: 60px;
  padding-inline: 56px;

  & h2.font-size-20 {
    margin-block: 0px 24px;
  }

  @media only screen and (max-width: 700px) {
    padding-inline: 25px;
  }
`;

const BadgeSlider = styled.div`
  margin-block: 60px;

  & div.title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    & p {
      margin: 0px;
    }

    & img {
      width: 36px;
    }
  }
`;

const LookScholar = styled.div`
  background-color: #121212;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px;
  aspect-ratio: 1/1.3;
  & p {
    text-align: center;
  }
`;
