import React, { FC, useEffect } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch, RootState } from '../../../store';

import CapRow from './CapRow';
import Loader from '../../../components/Loader/Loader';

interface IProps {
  gameId: string;
}

const Captains: FC<IProps> = ({ gameId }) => {
  const dispatch = useDispatch<Dispatch>();

  const captains = useSelector((state: RootState) => state.captain.captains);
  const loading = useSelector((state: RootState) => state.captain.loading);

  useEffect(() => {
    if (gameId) {
      dispatch.captain.getFilteredCaptains({ gameId });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading && !captains.length ? (
        <Loader />
      ) : (
        <TableContainer>
          <table>
            <thead>
              <tr className='header-row'>
                <th>Captain Name</th>
                <th>Wallet address</th>
                <th>Starz wallet address</th>
                <th>Email</th>
                <th>Revenue Split (Admin / Captain)</th>
                <th>Total earnings</th>
              </tr>
            </thead>
            <tbody>
              {captains.map(captain => (
                <CapRow key={captain.id} captain={captain} gameId={gameId} />
              ))}
            </tbody>
          </table>
        </TableContainer>
      )}
    </>
  );
};

export default Captains;

const TableContainer = styled.div`
  overflow-x: auto;
  border: 1px solid #ffffff33;
  width: 100%;
  margin-block: 30px 60px;
  border-radius: 4px;
  & table {
    border-collapse: collapse;
    min-width: 950px;
    width: 100%;
    & tbody {
      padding-inline: 10px;
    }
    & .header-row {
      height: 40px;
      background-color: #0e0e0e;
      & th {
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        opacity: 0.6;
        padding-top: 4px;
        border-bottom: 1px solid #ffffff33;
        text-align: left;
        &:first-of-type {
          padding-left: 32px;
        }

        & .row {
          display: flex;
          align-items: center;
          gap: 8px !important;
          justify-content: flex-start;

          & .col {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
        }
      }
    }
    & tr {
      height: 60px;
    }
    & td {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      border-bottom: 1px solid #ffffff33;
      padding-block: 12px;
      &:first-of-type {
        padding-left: 32px;
      }

      &:last-of-type {
        padding-right: 10px;
      }

      & .row {
        display: flex;
        align-items: center;
        gap: 10px;

        & img {
          width: 20px;
        }
      }

      & .icon-button {
        & img {
          padding-inline: 5px;
          cursor: pointer;
        }
        & .menu {
          position: absolute;
          width: 200px;
          background: #0e0e0e;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
          transform: translate(-90%, -115%);
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
          display: none;
          & .menu-item {
            padding: 10px 5px;
            cursor: pointer;
            &:hover {
              background-color: #000000;
            }
          }
          & p {
            opacity: 0.6;
          }
          &.show {
            display: block;
          }
        }
      }
    }
    & tr:last-child {
      & td {
        border: none;
      }
    }
  }
`;
