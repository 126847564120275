import React, { FC, useState, useEffect, useMemo } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import { useTable } from 'react-table';
import { useNavigate } from 'react-router-dom';

import Page from '../../layouts/Page';
import Filters from '../../components/Filters/Filters';
import Select from '../../components/Select/Select';
import Loader from '../../components/Loader/Loader';
import AssetsPullDialog from '../../dialogs/AssetsPullDialog';
import Switch from '../../components/Switch/Switch';
import PullbacksRow from './PullbacksRow';
import Button from '../../components/Button/Button';

import theme from '../../theme';
import { IScholarUser } from '../../types/interfaces';

const tableColumns = [
  {
    Header: 'User',
    accessor: 'username',
  },
  {
    Header: 'Wallet Address',
    accessor: 'starz_wallet_address',
  },
  {
    Header: 'Payout Wallet Address',
    accessor: 'wallet_address',
  },
  {
    Header: 'Number of badges',
    accessor: 'badges_mint',
  },
  {
    Header: 'Status',
    accessor: '',
  },
  {
    Header: 'Action',
    accessor: '',
  },
];

const Pullbacks: FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<IScholarUser | null>(null);

  const [openDialog, setOpenDialog] = useState(false);

  const [filters, setFilters] = useState({
    order: '',
    split: '',
    sortBy: '',
    badgeType: '',
    checkbox: false,
  });

  const loading = useSelector((state: RootState) => state.scholars.loading);
  const scholarUsers = useSelector(
    (state: RootState) => state.scholars.allScholars
  );

  const columns = useMemo(() => tableColumns, []);
  const data = scholarUsers;

  const tableInstance = useTable({
    // @ts-ignore
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } =
    tableInstance;

  const handleFilterUpdate = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const { name, value } = e.currentTarget;
    setFilters(prev => ({
      ...prev,
      [name]: e.target.type === 'checkbox' ? (e.target as any).checked : value,
    }));
  };

  useEffect(() => {
    dispatch.scholars.getScholars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      <Container>
        <div className='row'>
          <h1 className='title'>Assets Pulling</h1>
          <Button
            variant='transparent'
            onClick={() => navigate('/dashboard/timeline')}
          >
            Timeline
          </Button>
        </div>
        <Filters>
          <div className='grid'>
            <div className='item'>
              <Select
                options={[
                  { label: 'Newest', value: 'newest' },
                  { label: 'Oldest', value: 'oldest' },
                ]}
                title='Order'
                name='order'
                value={filters.order}
                onChange={handleFilterUpdate}
              />
            </div>
            <div className='item'>
              <div className='inner-flex'>
                <div>
                  <Select
                    options={[
                      { label: '30/70', value: 30 },
                      { label: '40/70', value: 40 },
                      { label: '50/50', value: 50 },
                    ]}
                    title='Revenue Split'
                    name='split'
                    value={filters.split}
                    onChange={handleFilterUpdate}
                  />
                </div>
                <div>
                  <Select
                    options={[
                      { label: 'Newest', value: 'newest' },
                      { label: 'Oldest', value: 'oldest' },
                    ]}
                    title='Sort by'
                    name='sortBy'
                    value={filters.sortBy}
                    onChange={handleFilterUpdate}
                  />
                </div>
              </div>
            </div>
            <div className='item'>
              <div className='inner-flex'>
                <div>
                  <Select
                    options={[
                      { label: 'Newest', value: 'newest' },
                      { label: 'Oldest', value: 'oldest' },
                    ]}
                    title='Sort by'
                    name='sortBy'
                    value={filters.sortBy}
                    onChange={handleFilterUpdate}
                  />
                </div>
                <div className='checkbox-field'>
                  <p className='font-size-14'>
                    {filters.checkbox ? 'Fungable Token' : 'NFT'}
                  </p>
                  <Switch
                    name='checkbox'
                    checked={filters.checkbox}
                    onChange={handleFilterUpdate}
                  />
                </div>
              </div>
            </div>
          </div>
        </Filters>
        {loading && !scholarUsers.length ? (
          <Loader />
        ) : (
          <TableContainer>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr
                    className='header-row'
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>
                        <div className='row'>
                          <div>{column.render('Header')}</div>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, index) => {
                  prepareRow(row);
                  return (
                    <PullbacksRow
                      key={index}
                      row={row}
                      setOpenDialog={setOpenDialog}
                      setScholar={setSelected}
                    />
                  );
                })}
              </tbody>
            </table>
          </TableContainer>
        )}
        {openDialog && selected && (
          <AssetsPullDialog
            scholar={selected!}
            open={openDialog}
            setOpen={setOpenDialog}
            nfts={filters.checkbox}
          />
        )}
      </Container>
    </Page>
  );
};

export default Pullbacks;

const Container = styled.div`
  & .row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.flex-start {
      justify-content: flex-start;
      gap: 10px;
    }
  }
  .grid {
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 10px;

    & .inner-flex {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
    & .checkbox-field {
      display: flex;
      align-items: center;
      align-items: flex-end;
      height: 100%;
      justify-content: space-between;
      & p {
        opacity: 0.4;
      }
    }
  }
  & .row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.flex-start {
      justify-content: flex-start;
      gap: 10px;
    }

    & .user-img {
      height: 30px;
      width: 30px;
      object-fit: cover;
    }
    & .stz-icon {
      width: 16px;
      height: 16px;
    }

    & .badge-image {
      height: 30px;
      width: 30px;

      /* &:last-child {
        margin-right: 0px;
      } */
    }
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
  border: 1px solid #ffffff33;
  padding-inline: 30px;
  padding-bottom: 10px;
  & table {
    border-collapse: collapse;
    min-width: 950px;
    width: 100%;
    & .header-row {
      height: 40px;
      & th {
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        padding-top: 4px;
        color: #ffffff99;
        border-bottom: 1px solid #ffffff33;
        text-align: left !important;

        & img {
          width: 8px;
          opacity: 1;
        }

        & .row {
          display: flex;
          align-items: center;
          gap: 4px;
          justify-content: flex-start;
        }

        & .col {
          display: flex;
          flex-direction: column;
          gap: 3px;
        }
      }
    }
    & tr {
      height: 60px;
    }
    & td {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      border-bottom: 1px solid #ffffff33;
      & button {
        width: 160px;
      }
      & .done-btn {
        padding-block: 7px;
        &:disabled {
          background-color: #ffffff1a;
        }
        &:disabled:hover {
          background-color: #ffffff1a;
        }
      }
      & .retry-btn {
        padding-block: 7px;
        &::after {
          background-color: ${theme.palette.secondary};
        }
        &:disabled {
          background-color: #ffffff1a;
        }
        &:disabled:hover {
          background-color: #ffffff1a;
        }
      }
    }
    & tr:last-child {
      & td {
        border: none;
      }
    }
  }
`;
