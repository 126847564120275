import React, { FC, useState, useEffect, useMemo } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';
import ChartComponent from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import ClickAwayListener from 'react-click-away-listener';
import { useTable, useSortBy } from 'react-table';

import Page from '../../layouts/Page';
import TableRow from './TableRow';

import theme from '../../theme';
import assets from '../../assets';
import Loader from '../../components/Loader/Loader';
import Search from '../../components/Search/Search';

const tableColumns = [
  {
    Header: 'User',
    accessor: 'username',
    disableSortBy: true,
  },
  {
    Header: 'Starz Wallet Address',
    accessor: 'wallet',
    disableSortBy: true,
  },
  {
    Header: 'Email Address',
    accessor: 'email',
    disableSortBy: true,
  },
  {
    Header: 'Role',
    accessor: 'role',
    disableSortBy: true,
  },
  {
    Header: 'Games',
    accessor: 'games',
    disableSortBy: true,
  },
  {
    Header: 'Revenue Split',
    accessor: 'revenueSplit',
    disableSortBy: true,
  },

  {
    Header: '',
    accessor: 'none',
    disableSortBy: true,
  },
];

const Home: FC = () => {
  const dispatch = useDispatch<Dispatch>();

  const games = useSelector((state: RootState) => state.games.games);
  const scholars = useSelector((state: RootState) => state.scholars.allScholars);

  const loading = useSelector((state: RootState) => state.scholars.loading);

  const [filteredScholars, setFilteredScholars] = useState(scholars);

  const columns = useMemo(() => tableColumns, []);
  const tableInstance = useTable(
    {
      // @ts-ignore
      columns,
      data: filteredScholars,
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } = tableInstance;

  const [menuOpen, setMenuOpen] = useState(false);
  const [earningFilter, setEarningFilter] = useState('Daily');
  const [searchTerm, setSearchTerm] = useState('');

  // const [filters, setFilters] = useState({
  //   game: "",
  //   earning: "",
  //   revinueSplit: "",
  // });

  // const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   const { name, value } = e.currentTarget;
  //   setFilters((prev) => ({ ...prev, [name]: value }));
  // };

  const options: ApexOptions = {
    chart: {
      type: 'area',
      toolbar: {
        show: false,
      },
      redrawOnParentResize: true,
      redrawOnWindowResize: true,
      animations: {
        easing: 'linear',
      },
      events: {
        mounted: chart => chart.windowResizeHandler(),
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 1,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: [theme.palette.secondary],
        shadeIntensity: 1,
        type: 'horizontal',
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    colors: [theme.palette.primary, theme.palette.secondary],
    legend: {
      show: false,
    },
    grid: {
      borderColor: '#ffffff33',
      xaxis: {
        lines: {},
      },
    },
    xaxis: {
      type: 'category',
      categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      tickAmount: 10,
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
  };

  const series = [
    {
      name: 'series1',
      data: [],
    },
  ];

  useEffect(() => {
    if (!games.length) {
      dispatch.games.getAllGames();
    }
    dispatch.scholars.getScholars();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFilteredScholars(scholars);
  }, [scholars]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);

    const users = [...scholars];
    if (e.target.value.length > 2) {
      const filteredUsers = users.filter(user => {
        const value = e.target.value.toLowerCase();

        return (
          user.id?.toLowerCase().includes(value) ||
          user.nick_name?.toLowerCase().includes(value) ||
          user.email?.toLowerCase().includes(value) ||
          user.wallet_address?.toLowerCase().includes(value) ||
          user.starz_wallet_address?.toLowerCase().includes(value)
        );
      });
      setFilteredScholars([...filteredUsers]);
    } else {
      setFilteredScholars([...scholars]);
    }
  };

  return (
    <Page>
      {/* <Filters
        noMargin
        searchProps={{
          value: search,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            setSearch(e.currentTarget.value),
        }}
      >
        <FilterInner>
          <Select
            title='Select Game'
            options={games?.map(game => ({ label: game.name, value: game.id }))}
            name='game'
            value={filters.game}
            onChange={handleChange}
          />
          <Select
            title='Sort by Earnings'
            options={games?.map(game => ({ label: game.name, value: game.id }))}
            name='earning'
            value={filters.earning}
            onChange={handleChange}
          />
          <Select
            title='Sort by Revenue Split'
            options={[
              {
                label: '30/70',
                value: '30',
              },
              {
                label: '40/60',
                value: '40',
              },
              {
                label: '50/50',
                value: '50',
              },
            ]}
            name='revinueSplit'
            value={filters.revinueSplit}
            onChange={handleChange}
          />
        </FilterInner>
      </Filters> */}

      <Grid>
        <Earnings className='earnings'>
          <div className='row space-between'>
            <h2 className='title'>Top Earnings</h2>
            <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
              <Menu className='menu'>
                <button onClick={() => setMenuOpen(!menuOpen)}>
                  <p className='font-size-12'>{earningFilter}</p>
                  <img src={assets.downArrow} alt='' />
                </button>
                {menuOpen && (
                  <MenuPopup>
                    <div
                      className='menu-item'
                      onClick={() => {
                        setEarningFilter('Daily');
                        setMenuOpen(false);
                      }}
                    >
                      <p className='font-size-14'>Daily</p>
                    </div>
                    <div
                      className='menu-item'
                      onClick={() => {
                        setEarningFilter('Weekly');
                        setMenuOpen(false);
                      }}
                    >
                      <p className='font-size-14'>Weekly</p>
                    </div>
                    <div
                      className='menu-item'
                      onClick={() => {
                        setEarningFilter('Monthly');
                        setMenuOpen(false);
                      }}
                    >
                      <p className='font-size-14'>Monthly</p>
                    </div>
                  </MenuPopup>
                )}
              </Menu>
            </ClickAwayListener>
          </div>
          {/* <div className="row">
            <img src={assets.riot} alt="" />
            <div>
              <p className="font-size-20">
                0 <span className="font-size-12">RIOT</span>
              </p>
              <p className="font-size-10" style={{ opacity: "0.6" }}>
                ≈ 0 USD
              </p>
            </div>
          </div> */}
          <div className='row'>
            <img src={assets.stzIcon} alt='' />
            <div>
              <p className='font-size-20'>
                0 <span className='font-size-12'>STZ</span>
              </p>
              <p className='font-size-10' style={{ opacity: '0.6' }}>
                ≈ 0 USD
              </p>
            </div>
          </div>
          {/* <div className='row'>
            <img src={assets.stzIcon} alt='' />
            <div>
              <p className='font-size-20'>
                567.23 <span className='font-size-12'>THC</span>
              </p>
              <p className='font-size-10' style={{ opacity: '0.6' }}>
                ≈ 16.69 USD
              </p>
            </div>
          </div> */}
        </Earnings>
        <Graph className='graph'>
          <div className='row'>
            <div className='row-item'>
              <p className='font-size-18' style={{ fontFamily: 'Stalinist One' }}>
                0%
              </p>
              <p className='font-size-10'>this week</p>
            </div>
            <div className='row-item'>
              <p className='font-size-18' style={{ fontFamily: 'Stalinist One' }}>
                0%
              </p>
              <p className='font-size-10'>last week</p>
            </div>
          </div>

          <div>
            <ChartComponent
              options={options}
              series={series}
              type='line'
              height={195}
              width='100%'
            />
          </div>
        </Graph>
      </Grid>

      {loading && !scholars.length ? (
        <Loader />
      ) : (
        <>
          {scholars.length ? (
            <div style={{ marginBlock: '60px' }}>
              <Search
                wrapperStyles={{ float: 'right', marginBottom: '20px' }}
                value={searchTerm}
                onChange={handleSearch}
              />
              <TableContainer>
                <table {...getTableProps()}>
                  <thead>
                    {headerGroups.map(headerGroup => (
                      <tr className='header-row' {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            <div className='row'>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <>
                                    <div className='col'>
                                      <img
                                        src={assets.sortArrowUp}
                                        style={{ opacity: 0.6 }}
                                        alt=''
                                      />
                                      <img
                                        src={assets.sortArrowUp}
                                        style={{
                                          transform: 'rotate(180deg)',
                                        }}
                                        alt=''
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <div className='col'>
                                    <img src={assets.sortArrowUp} alt='' />
                                    <img
                                      src={assets.sortArrowUp}
                                      style={{
                                        transform: 'rotate(180deg)',
                                        opacity: 0.6,
                                      }}
                                      alt=''
                                    />
                                  </div>
                                )
                              ) : (
                                <>
                                  {column.canSort && (
                                    <div className='col'>
                                      <img
                                        src={assets.sortArrowUp}
                                        style={{ opacity: 0.6 }}
                                        alt=''
                                      />
                                      <img
                                        src={assets.sortArrowUp}
                                        style={{
                                          transform: 'rotate(180deg)',
                                          opacity: 0.6,
                                        }}
                                        alt=''
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                              <div>{column.render('Header')}</div>
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                      prepareRow(row);
                      return <TableRow row={row} key={index} />;
                    })}
                  </tbody>
                </table>
              </TableContainer>
            </div>
          ) : (
            <NotFound>
              <p className='font-size-20'>No user found</p>
            </NotFound>
          )}
        </>
      )}
    </Page>
  );
};

export default Home;

// const FilterInner = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr 1fr 1fr;
//   gap: 24px;
// `;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 24px;
  margin-top: 30px;
  @media only screen and (max-width: 756px) {
    grid-template-columns: 1fr;
  }
`;

const Earnings = styled.div`
  padding: 8px 20px;
  background-color: #121212;
  & .row {
    display: flex;
    padding: 16px 20px;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #ffffff33;
    &:last-of-type {
      border-bottom: none;
    }

    &.space-between {
      justify-content: space-between;
    }

    & img {
      max-width: 30px;
    }
  }
`;

const Graph = styled.div`
  background-color: #121212;
  padding: 24px 30px;

  & .row {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: flex-end;

    & .row-item {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      & p:last-of-type {
        opacity: 0.6;
      }
    }
  }

  @media only screen and (min-width: 1000px) {
    & .row {
      padding-right: 100px;
      & .row-item {
        min-width: 184px;
      }
    }
  }
`;

const Menu = styled.div`
  position: relative;
  & button {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: #000000;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    height: 26px;
    padding: 0px 12px;
  }
`;

const MenuPopup = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(calc(100% + 10px));
  background-color: #000000;
  width: 120px;
  padding: 5px 0px;
  & .menu-item {
    padding: 5px 10px;
    cursor: pointer;
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
  padding-bottom: 10px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ffffff33;
  & table {
    border-collapse: collapse;
    min-width: 950px;
    width: 100%;
    & tbody {
      padding-inline: 10px;
    }
    & .header-row {
      height: 40px;
      background-color: #0e0e0e;
      & th {
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        opacity: 0.6;
        padding-top: 4px;
        border-bottom: 1px solid #ffffff33;
        text-align: left;
        &:first-of-type {
          padding-left: 32px;
        }

        & .row {
          display: flex;
          align-items: center;
          gap: 8px;

          & .col {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
        }
      }
    }
    & tr {
      height: 60px;
    }
    & td {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      border-bottom: 1px solid #ffffff33;
      padding-block: 12px;
      &:first-of-type {
        padding-left: 32px;
      }

      &:last-of-type {
        padding-right: 10px;
      }

      & .assets-container {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      & .games-container {
        display: flex;
        align-items: center;
        gap: 12px;

        & .game {
          height: 24px;
          width: 24px;
          border-radius: 6px;
          background-color: #ffffff1a;
          display: grid;
          place-items: center;
          padding: 2px;
          & img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
      & img {
        max-height: 40px;
        max-width: 105px;
        /* margin-left: 10px; */
      }

      & .row {
        display: flex;
        align-items: center;
        gap: 10px;

        & img {
          width: 20px;
        }
      }

      & .icon-button {
        & img {
          padding-inline: 5px;
          cursor: pointer;
        }
        & .menu {
          position: absolute;
          width: 200px;
          background: #0e0e0e;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
          transform: translate(-90%, -115%);
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
          display: none;
          & .menu-item {
            padding: 10px 5px;
            cursor: pointer;
            &:hover {
              background-color: #000000;
            }
          }
          & p {
            opacity: 0.6;
          }
          &.show {
            display: block;
          }
        }
      }
    }
    & tr:last-child {
      & td {
        border: none;
      }
    }
  }
`;

const NotFound = styled.div`
  padding-top: 100px;
  display: flex;
  justify-content: center;
`;
