import React, { FC, useState } from 'react';

import styled from 'styled-components';
import classNames from 'classnames';

import theme from '../theme';

import assets from '../assets';

const TokenPullRow: FC = () => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);

  const handleWithdraw = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    console.log('withdraw');
  };
  return (
    <Container>
      <Row
        onClick={() => setOpen(!open)}
        style={{ background: open ? '#0d0d0d' : '' }}
      >
        <div className="row-item">
          <img src={assets.arena} alt="" className="game-image" />
        </div>
        <div className="row-item">
          <img src={assets.goldBadge} alt="" className="badge-image" />
        </div>
        <div className="row-item">$22.5</div>
        <div className="row-item">
          <input
            type="number"
            onClick={e => e.stopPropagation()}
            value={value}
            onChange={e => setValue(Number(e.currentTarget.value))}
            className="font-size-12 input-field"
          />
        </div>
        <div className="row-item flex">
          <button className="font-size-12" onClick={handleWithdraw}>
            Withdraw
          </button>
          <img
            src={assets.downArrow}
            style={{
              cursor: 'pointer',
              transform: open ? 'rotate(-180deg)' : '',
              transition: '0.4s ease',
            }}
            alt=""
          />
        </div>
      </Row>
      <div className={classNames('dropdown', open && 'open')}>
        <div className="wrapper">
          <div className="exchange">
            <img src={assets.exchangeIcon} alt="" />
          </div>
          <div className="column">
            <p className="font-size-10">
              Excahnged <span>0.3322 STZ</span> ($1.22) with{' '}
              <span>BITCOIN</span>
            </p>
            <div className="date">
              <p className="font-size-10">Date</p>
              <p className="font-size-10">01-01-2022 at 12:00 AM EST</p>
            </div>
            <div className="transaction-ids">
              <p className="font-size-10">TRANSATION ID</p>
              <p className="font-size-10">mxtUDoYbsR3r8hAvdCfVFgAXJF3RXwTpFw</p>
            </div>
          </div>
          <div className="column">
            <p className="font-size-10">
              <b>Status</b>: Confirmed
            </p>
            <div className="status">
              <p className="font-size-10">Exchange details</p>
              <p className="font-size-10">
                <span>0.32265656 STZ</span> for <span>0.000002 BTC</span>{' '}
              </p>
            </div>
          </div>
          <div className="column">
            <p className="font-size-10">2 minute ago </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TokenPullRow;

const Container = styled.div`
  border-bottom: 1px solid #ffffff33;
  &:last-child {
    border-bottom: none;
  }
  & .dropdown {
    max-height: 0px;
    background-color: #0d0d0d;
    overflow: hidden;
    transition: max-height 0.4s ease;

    & .wrapper {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      & .date,
      & .status {
        margin-block: 20px;
      }
    }
    &.open {
      max-height: 180px;
    }
    & p {
      opacity: 0.6;
      & span {
        color: ${theme.palette.secondary};
      }
    }
  }
`;

const Row = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  padding-inline: 10px;
  & .row-item {
    flex: 1;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #ffffff;
    min-width: 120px;
    & .input-field {
      padding: 6px 10px;
      border: 1px solid #ffffff;
      outline: none;
      width: 120px;
      background-color: transparent;
      color: #ffffff;
    }

    &.flex {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    & .game-image {
      height: 40px;
      max-width: 95px;
    }

    & .badge-image {
      height: 30px;
      width: 30px;
    }

    & button {
      border: none;
      outline: none;
      cursor: pointer;
      background-color: #ffffff33;
      padding: 4px 12px;
      z-index: 1;
    }
  }
  &:last-child {
    border-bottom: none;
  }
  & .assets-container {
    display: flex;
    gap: 8px;
    align-items: center;
    & .asset {
      height: 30px;
      width: 30px;
      border-radius: 6px;
      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    & .more-text {
      font-size: 8px;
      line-height: 14px;
      opacity: 0.6;
    }
  }
`;
