import { FC } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../store';

import DialogLayout from './Dialog';
import Button from '../components/Button/Button';

import assets from '../assets';

const FeedBack: FC = () => {
  const dispatch = useDispatch<Dispatch>();

  const open = useSelector((state: RootState) => state.feedback.open);
  const title = useSelector((state: RootState) => state.feedback.title);
  const subtitle = useSelector((state: RootState) => state.feedback.subtitle);

  const handleClose = () => {
    dispatch.feedback.close();
  };

  return (
    <DialogLayout open={open}>
      <Container>
        <div className='close-btn'>
          <img src={assets.closeIcon} alt='' onClick={handleClose} />
        </div>
        <Content>
          <h1 className='title'>{title}</h1>
          <p className='font-size-14'>{subtitle}</p>
          <div className='btn-container'>
            <Button variant='primary' onClick={handleClose}>
              Continue
            </Button>
          </div>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default FeedBack;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 392px;

  & .close-btn {
    display: flex;
    justify-content: flex-end;
    padding-block: 6px;
    & img {
      cursor: pointer;
    }
  }
`;

const Content = styled.div`
  padding: 40px 30px;
  background: #121212;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);

  & p {
    margin-block: 24px 40px;
  }

  & .btn-container {
    text-align: center;
  }
`;
