import React, { FC, useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { useTable, useSortBy } from 'react-table';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../store';
// import ClickAwayListener from 'react-click-away-listener';
// import classNames from 'classnames';
import Select from '../../components/Select/Select';
import InputField from '../../components/InputField/InputField';
import Page from '../../layouts/Page';
import PayoutHistoryDialog from '../../dialogs/PayoutHistoryDialog';
import Filters from '../../components/Filters/Filters';
import EthAddress from '../../components/EthAddress/EthAddress';
import Loader from '../../components/Loader/Loader';

import { getFormatedTime } from '../../utils';

import { adminTimeline, getScholarById } from '../../http';

import assets from '../../assets';
import { IAdminTimeline } from '../../types/interfaces';

const tableColumns = [
  {
    Header: 'User',
    acceccor: 'username',
    disableSortBy: true,
  },
  {
    Header: 'Wallet Address',
    accessor: 'walletAddress',
    disableSortBy: true,
  },
  {
    Header: 'Payout Wallet Address',
    accessor: 'payoutWalletAddress',
    disableSortBy: true,
  },
  {
    Header: 'Payout Amount',
    accessor: 'amount',
    disableSortBy: true,
  },
  {
    Header: 'Payout Date',
    accessor: 'payoutDate',
    disableSortBy: true,
  },
  {
    Header: 'Tx Hash',
    accessor: 'hash',
    disableSortBy: true,
  },
  {
    Header: '',
    accessor: 'none',
    disableSortBy: true,
  },
];

const PayoutHistory: FC = () => {
  const dispatch = useDispatch<Dispatch>();

  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState<IAdminTimeline[]>([]);
  const columns = useMemo(() => tableColumns, []);

  // @ts-ignore
  const tableInstance = useTable({ columns, data }, useSortBy);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    tableInstance;

  const [filters, setFilters] = useState({
    order: '',
    payoutAmountFrom: '',
    payoutAmountTo: '',
    dateFrom: '',
    dateTo: '',
  });

  const [loading, setLoading] = useState(false);

  // const tableMenus = useRef<any>([]);

  // const addToRef = (e: any) => {
  //   if (e && !tableMenus.current.includes(e)) tableMenus.current.push(e);
  // };

  // const handleMenuClick = (index: number) => {
  //   const menu: HTMLDivElement = tableMenus.current[index];
  //   if (menu) {
  //     if (menu.classList.contains('show')) {
  //       menu.classList.remove('show');
  //       return;
  //     }
  //     tableMenus.current.forEach((e: HTMLDivElement) =>
  //       e.classList.remove('show')
  //     );
  //     menu.classList.add('show');
  //   }
  // };

  // const handleCloseMenu = (index: number) => {
  //   const menu: HTMLDivElement = tableMenus.current[index];
  //   menu.classList.remove('show');
  // };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const getPayoutHistory = async () => {
    try {
      setLoading(true);
      const { data } = await adminTimeline(`action=Payout`);
      console.log(data);
      setData(data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPayoutHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleCopyAddress = (address: string) => {
  //   navigator.clipboard.writeText(address);
  // };
  return (
    <Page>
      <Container>
        <div className='row'>
          <h1 className='title'>Payout History</h1>
        </div>
        {/* Filters Component takes searchProps for search bar currently it is optional */}
        <Filters>
          <div className='grid'>
            <div className='item'>
              {/* Temporary values added for now*/}

              <Select
                options={[
                  { label: 'Newest', value: 'newest' },
                  { label: 'Oldest', value: 'oldest' },
                ]}
                title='Order'
                value={filters.order}
                onChange={handleChange}
                name='order'
              />
            </div>
            <div className='item'>
              <div className='inner-flex'>
                <div className='inner-flex-item'>
                  <InputField
                    placeholder='Payout Amount: From'
                    type='number'
                    value={filters.payoutAmountFrom}
                    onChange={handleChange}
                    name='payoutAmountFrom'
                  />
                </div>
                <div className='inner-flex-item'>
                  <InputField
                    placeholder='Payout Amount: To'
                    type='number'
                    value={filters.payoutAmountTo}
                    onChange={handleChange}
                    name='payoutAmountTo'
                  />
                </div>
              </div>
            </div>
            <div className='item'>
              <div className='inner-flex'>
                <div className='inner-flex-item'>
                  <InputField
                    placeholder='Date: From'
                    type='date'
                    value={filters.dateFrom}
                    onChange={handleChange}
                    name='dateFrom'
                  />
                </div>
                <div className='inner-flex-item'>
                  <InputField
                    placeholder='Date: To'
                    type='date'
                    value={filters.dateTo}
                    onChange={handleChange}
                    name='dateTo'
                  />
                </div>
              </div>
            </div>
          </div>
        </Filters>

        {loading ? (
          <Loader />
        ) : (
          <TableContainer>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr
                    className='header-row'
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map(column => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <div className='row'>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <>
                                <div className='col'>
                                  <img
                                    src={assets.sortArrowUp}
                                    style={{ opacity: 0.6 }}
                                    alt=''
                                  />
                                  <img
                                    src={assets.sortArrowUp}
                                    style={{
                                      transform: 'rotate(180deg)',
                                    }}
                                    alt=''
                                  />
                                </div>
                              </>
                            ) : (
                              <div className='col'>
                                <img src={assets.sortArrowUp} alt='' />
                                <img
                                  src={assets.sortArrowUp}
                                  style={{
                                    transform: 'rotate(180deg)',
                                    opacity: 0.6,
                                  }}
                                  alt=''
                                />
                              </div>
                            )
                          ) : (
                            <>
                              {column.canSort && (
                                <div className='col'>
                                  <img
                                    src={assets.sortArrowUp}
                                    style={{ opacity: 0.6 }}
                                    alt=''
                                  />
                                  <img
                                    src={assets.sortArrowUp}
                                    style={{
                                      transform: 'rotate(180deg)',
                                      opacity: 0.6,
                                    }}
                                    alt=''
                                  />
                                </div>
                              )}
                            </>
                          )}
                          <div>{column.render('Header')}</div>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      <td>
                        <div className='row flex-start'>
                          <img
                            src={row.original.user.image_url || assets.user}
                            className='user-img'
                            alt=''
                          />
                          <p className='font-size-12'>
                            {row.original.user.fullname}
                          </p>
                        </div>
                      </td>
                      <td>
                        <EthAddress
                          address={
                            row.original.user.account.starz_wallet_address
                          }
                        />
                      </td>
                      <td>
                        <EthAddress
                          address={row.original.user.account.wallet_address}
                        />
                      </td>
                      <td>
                        <div className='row flex-start'>
                          <img
                            src={assets.stzIcon}
                            className='stz-icon'
                            alt=''
                          />
                          <p className='font-size-12'>
                            {typeof row.original.amount_distribution ===
                              'string' && (
                              <>
                                {
                                  JSON.parse(row.original.amount_distribution)
                                    ?.user
                                }{' '}
                                {
                                  JSON.parse(row.original.amount_distribution)
                                    ?.symbol
                                }
                              </>
                            )}
                          </p>
                        </div>
                      </td>
                      <td>{getFormatedTime(row.original.user_created_at)}</td>
                      <td>
                        <EthAddress
                          address={JSON.parse(row.original.response[0])?.hash}
                        />
                      </td>
                      <td>
                        <div className='icon-button'>
                          <img
                            src={assets.moreVert}
                            alt=''
                            onClick={async () => {
                              const { data: scholar } = await getScholarById(
                                row.original.user.user_id
                              );
                              const { accounts, ...user } = scholar;
                              const wallet_address = accounts[0].wallet_address;
                              const starz_wallet_address =
                                accounts[0].starz_wallet_address;
                              const scholarData = {
                                ...user,
                                wallet_address,
                                starz_wallet_address,
                              };
                              dispatch.scholars.setSelectedScholar(scholarData);
                              setOpenDialog(true);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TableContainer>
        )}

        <div className='margin'></div>
      </Container>
      {openDialog && (
        <PayoutHistoryDialog open={openDialog} setOpen={setOpenDialog} />
      )}
    </Page>
  );
};

export default PayoutHistory;

const Container = styled.div`
  & .margin {
    height: 80px;
  }

  .grid {
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 10px;

    & .inner-flex {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  }
  & .row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.flex-start {
      justify-content: flex-start;
      gap: 10px;
    }

    & .user-img {
      height: 30px;
      width: 30px;
      object-fit: cover;
    }
    & .stz-icon {
      width: 16px;
      height: 16px;
    }
  }

  & .icon-button {
    position: relative;
    & img {
      padding-inline: 5px;
      cursor: pointer;
    }
    & .menu {
      position: absolute;
      top: 0;
      right: 0;
      width: 260px;
      background-color: #0e0e0e;
      transform: translate(-10%, -105%);
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
      display: none;
      & p {
        opacity: 0.6;
      }
      &.show {
        display: block;
      }
      & .menu-popup {
        & .profile {
          display: flex;
          align-items: center;
          gap: 11px;

          padding: 14px 20px;
          & .user-image {
            height: 48px;
            width: 48px;
            padding: 0;
          }

          & .font-size-14 {
            color: #ffffff;
            opacity: 1;
          }

          & .address-container {
            display: flex;
            align-items: center;
            margin-top: 2px;
            position: relative;
            & img {
              cursor: pointer;
            }
            & p {
              background-color: #ffffff1a;
              padding: 2px;
              border-radius: 2px;
              margin-right: 4px;
            }
            & .tooltip {
              position: absolute;
              left: 105%;
              top: calc(50% - 10px);
              background-color: #ffffff33;
              padding-inline: 5px;
              border-radius: 2px;
              font-size: 10px;
              height: 20px;
              line-height: 16px;
              transform: scale(0.1);
              opacity: 0;
              pointer-events: none;
              transition: 0.2s ease;
              &.show {
                transform: scale(1);
                opacity: 1;
              }
            }
          }
        }
        & .menu-item {
          padding: 14px 22px;
          border-top: 1px solid #ffffff33;
          & p {
            opacity: 0.4;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    & .collapse {
      .grid {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  @media only screen and (max-width: 420px) {
    & .filters-and-search {
      margin-block: 40px 30px;
      & .row {
        gap: 20px;
        & .search-bar {
          flex-grow: 1;
          width: 100%;
        }
      }
    }
    & .collapse {
      .grid {
        grid-template-columns: 1fr;
      }
    }
  }
`;
const TableContainer = styled.div`
  overflow-x: auto;
  border: 1px solid #ffffff33;
  padding-inline: 30px;
  padding-bottom: 10px;
  & table {
    border-collapse: collapse;
    min-width: 950px;
    width: 100%;
    & .header-row {
      height: 40px;
      & th {
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        padding-top: 4px;
        color: #ffffff99;
        border-bottom: 1px solid #ffffff33;
        text-align: left !important;

        & img {
          width: 8px;
          opacity: 1;
        }

        & .row {
          display: flex;
          align-items: center;
          gap: 4px;
          justify-content: flex-start;
        }

        & .col {
          display: flex;
          flex-direction: column;
          gap: 3px;
        }
      }
    }
    & tr {
      height: 60px;
    }
    & td {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      border-bottom: 1px solid #ffffff33;
    }
    & tr:last-child {
      & td {
        border: none;
      }
    }
  }
`;
