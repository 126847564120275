import React, { FC } from 'react';

import styled from 'styled-components';
import classNames from 'classnames';

import theme from '../../theme';

interface IProps {
  title: string;
  label: string;
  value: string | number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  icon: string;
  name: string;
  error?: string;
  noIcon?: boolean;
}

const TokenInput: FC<IProps> = props => {
  const { title, label, value, onChange, icon, name, error, noIcon } = props;
  return (
    <Container>
      <p className='label font-size-10'>{label}</p>
      <div className='content'>
        <div className={classNames('left', error ? 'invalid-input' : '')}>
          <input
            type='number'
            value={value}
            onChange={onChange}
            placeholder='Amount'
            name={name}
            id=''
          />
          {/* <span className='label font-size-10'>≈ 302,935 USD</span> */}
        </div>
        <div className='right'>
          <div className='row'>
            {!noIcon && <img src={icon} alt='' />}
            <div
              className='row'
              style={{ justifyContent: !noIcon ? 'space-between' : 'center' }}
            >
              <p className='font-size-16'>{title}</p>
            </div>
          </div>
        </div>
      </div>
      {error && <p className='invalid'>{error}</p>}
    </Container>
  );
};

export default TokenInput;

const Container = styled.div`
  & .label {
    opacity: 0.4;
  }
  & .content {
    height: 58px;
    border-radius: 4px;
    margin-top: 6px;
    display: flex;
    overflow: hidden;
  }

  & .left {
    width: 40%;
    border: 1px solid #ffffff;
    border-radius: 4px 0px 0px 4px;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.invalid-input {
      border-color: ${theme.palette.secondary};
    }

    & input[type='number'] {
      background-color: transparent;
      outline: none;
      border: none;
      width: 100%;
      color: #ffffff;
    }
  }

  & .right {
    flex-grow: 1;
    border-radius: 0px 4px 4px 0px;
    border: 1px solid #ffffff33;
    border-left: none;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    & .row {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
      &.space-between {
        flex-grow: 1;
        justify-content: space-between;
      }
    }
  }
  .invalid {
    font-size: 10px;
    line-height: 16px;
    font-weight: 300;
    color: ${theme.palette.secondary};
    margin-top: 4px;
  }
`;
