import React, { FC } from 'react';

import styled from 'styled-components';
import theme from '../../theme';

interface IProps {
  name: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Switch: FC<IProps> = ({ name, checked, onChange }) => {
  return (
    <Input type="checkbox" name={name} checked={checked} onChange={onChange} />
  );
};

export default Switch;

const Input = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 20px;
  width: 40px;
  border-radius: 8px;
  transition: background 0.4s ease;
  background-color: ${theme.palette.secondary};
  position: relative;
  &::before {
    content: '';
    position: absolute;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    left: 4px;
    background-color: #ffffff;
    transition: 0.4s ease;
  }
  &:checked {
    background-color: ${theme.palette.primary};
  }

  &:checked::before {
    left: calc(100% - 16px);
  }
`;
