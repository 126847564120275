import React, { FC } from 'react';
import styled from 'styled-components';

const Page: FC<{ children: React.ReactNode }> = ({ children }) => {
  return <Box>{children}</Box>;
};

export default Page;

const Box = styled.div`
  padding-top: 32px;
  padding-inline: 30px;
  width: 100%;
  flex-grow: 1;

  @media only screen and (max-width: 700px) {
    padding-top: 40px;
    padding-inline: 25px;
  }
`;
