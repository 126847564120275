import assets from './assets';
export const apiUrl = process.env.REACT_APP_API_URL;

export const resizerUrl = process.env.REACT_APP_IMAGE_RESIZER;

export const badgeImagesByName: { [x: string]: any } = {
  'Gold Badge': assets.goldBadge,
  'Silver Badge': assets.silverBadge,
  'Bronze Badge': assets.bronzeBadge,
};

export const revenueSplitOptions = [
  {
    label: '0/100',
    value: '0',
  },
  {
    label: '10/90',
    value: '10',
  },
  {
    label: '20/80',
    value: '20',
  },
  {
    label: '30/70',
    value: '30',
  },
  {
    label: '40/60',
    value: '40',
  },
  {
    label: '50/50',
    value: '50',
  },
  {
    label: '60/40',
    value: '60',
  },
  {
    label: '70/30',
    value: '70',
  },
  {
    label: '80/20',
    value: '80',
  },
  {
    label: '90/10',
    value: '90',
  },
  {
    label: '100/0',
    value: '100',
  },
];
