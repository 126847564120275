import React, { ChangeEvent, FC, useState } from "react";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import { InferType, object, string } from "yup";
import { FormErrors, validateData } from "../../utils/validations";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../store";

import InputField from "../../components/InputField/InputField";
import Button from "../../components/Button/Button";
import LoginLayout from "../../layouts/LoginLayout";

const schema = object({
  code: string().required().label("Code"),
  password: string().required().label("Password"),
  confirm_password: string().required().label("Repeat password"),
});

interface IRecoverPassword extends InferType<typeof schema> {}

const PasswordReset: FC = () => {
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch<Dispatch>();
  const token = useSelector((state: RootState) => state.auth.varificatonToken);

  const [errors, setErrors] = useState<FormErrors<IRecoverPassword | null>>({
    code: "",
    password: "",
    confirm_password: "",
  });

  const [formData, setFormData] = useState<IRecoverPassword>({
    code: "",
    password: "",
    confirm_password: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((fd) => ({
      ...fd,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setErrors(null);
    const { data, errors: validationErrors } =
      await validateData<IRecoverPassword>(schema, formData);

    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }
    dispatch.auth.resetPassowrd({
      token,
      data,
      setValidated,
      setErrors,
    });
  };

  return (
    <LoginLayout>
      <Container>
        {validated ? (
          <>
            <div className="flex-1">
              <h1 className="title">Success</h1>
              <p className="font-size-14">Password updated sucessfully.</p>
            </div>
            <Button
              variant="primary"
              onClick={() => navigate("/")}
              className="btn"
            >
              Continue
            </Button>
          </>
        ) : (
          <>
            <div className="flex-1">
              <h1 className="title">New Password</h1>
              <p className="font-size-14">Please enter your new password</p>
              <InputField
                placeholder="Confirmation code"
                className="mb"
                type="number"
                name="code"
                value={formData.code}
                onChange={handleChange}
                error={errors?.code}
              />
              <InputField
                placeholder="Enter New Password"
                className="mb"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                error={errors?.password}
                hidden
              />
              <InputField
                placeholder="Repeat Password"
                type="password"
                name="confirm_password"
                value={formData.confirm_password}
                onChange={handleChange}
                error={errors?.confirm_password}
                hidden
              />
            </div>
            <Button variant="primary" onClick={handleSubmit} className="btn">
              Continue
            </Button>
          </>
        )}

        <div className="have-account">
          <p className="font-size-14">
            Having trouble? &nbsp;
            <Link to="#" className="anchor">
              Contact customer care
            </Link>
          </p>
        </div>
      </Container>
    </LoginLayout>
  );
};

export default PasswordReset;

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  & h1 {
    margin-bottom: 20px;
  }
  & .email {
    margin-block: 60px;
  }
  & .mb {
    margin-block: 40px 44px;
  }
  & .btn {
    margin-top: 60px;
  }
  & .have-account {
    text-align: center;
    margin-top: 40px;
  }
  @media only screen and (max-width: 700px) {
    & .flex-1 {
      flex-grow: 1;
    }
    & .btn {
      margin-top: 0px;
    }
  }
`;
