import { createModel } from '@rematch/core';
import { RootModel } from '.';

import {
  getAllScholars,
  getCaptainScholars as captainScholars,
  // getScholarAssets as getAssets,
  getScholarGames as getGames,
  getScholarsByGameId,
} from '../../http';

import { IScholarUser, IScholarNft, IGame } from '../../types/interfaces';

interface IGetScholars {
  query?: string;
  action?: (data: IScholarUser[]) => void;
}

interface IInitialState {
  allScholars: Array<IScholarUser>;
  loading: boolean;
  selected: IScholarUser | null;
  selectedScholarNfts: Array<IScholarNft>;
  selectedScholarGames: Array<IGame>;
  captainScholars: IScholarUser[];
}

export const scholars = createModel<RootModel>()({
  name: 'scholars',
  state: {
    allScholars: [],
    loading: false,
    selected: null,
    selectedScholarNfts: [],
    selectedScholarGames: [],
    captainScholars: [],
  } as IInitialState,
  reducers: {
    setAllScholars(state, payload: Array<IScholarUser>) {
      state.allScholars = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setSelectedScholar(state, payload: IScholarUser) {
      state.selected = payload;
    },
    setSelectedScholarNfts(state, payload: Array<IScholarNft>) {
      state.selectedScholarNfts = payload;
    },
    resetSelectedScholarNfts(state) {
      state.selectedScholarNfts = [];
    },
    setSelectedScholarGames(state, payload: Array<IGame>) {
      state.selectedScholarGames = payload;
    },
    resetSelectedScholarGames(state) {
      state.selectedScholarGames = [];
    },
    setCaptainScholars(state, payload: IScholarUser[]) {
      state.captainScholars = payload;
    },
  },
  effects: dispatch => ({
    async getScholars(payload?: IGetScholars) {
      dispatch.scholars.setLoading(true);
      try {
        const { data } = await getAllScholars(payload?.query || '');
        dispatch.scholars.setAllScholars(data);
        if (typeof payload?.action === 'function') {
          payload.action(data);
        }
      } catch (err) {
      } finally {
        dispatch.scholars.setLoading(false);
      }
    },
    async getScholarAssets(payload: string) {
      dispatch.scholars.setLoading(true);
      try {
        // const { data } = await getAssets({ public_address: payload });
        // const nfts = (data as Array<IScholarNft>)?.map(nft => {
        //   const { metadata, ...rest } = nft;
        //   return { ...rest, metadata: metadata ? JSON.parse(metadata) : null };
        // });
        // dispatch.scholars.setSelectedScholarNfts(nfts);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.scholars.setLoading(false);
      }
    },
    async getScholarGames(payload: string) {
      try {
        const { data } = await getGames(payload);
        dispatch.scholars.setSelectedScholarGames(data);
      } catch (err) {
        console.log(err);
      }
    },
    async getScholarshipAcceptedScholars(payload: string) {
      try {
        dispatch.scholars.setLoading(false);
        const { data } = await getScholarsByGameId(payload);
        console.log(data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.scholars.setLoading(false);
      }
    },
    async getCaptainScholars(_, state) {
      try {
        const selectedNotification = state.notifications.selected;
        if (selectedNotification) {
          dispatch.scholars.setLoading(true);
          const { data } = await captainScholars(
            selectedNotification.user_id,
            selectedNotification.game_id
          );
          dispatch.scholars.setCaptainScholars(data);
        }
      } catch (err) {
      } finally {
        dispatch.scholars.setLoading(false);
      }
    },
  }),
});
