/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useRef } from 'react';
import styled from 'styled-components';
import { Row } from 'react-table';
import ClickAwayListener from 'react-click-away-listener';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';

import EthAddress from '../../components/EthAddress/EthAddress';

import assets from '../../assets';
import { IScholarUser } from '../../types/interfaces';

import { truncateString, showToast } from '../../utils';
import { benjiPayout, payout } from '../../http';
import { ClipLoader } from 'react-spinners';

interface IProps {
  row: Row<IScholarUser>;
  gameId: string;
  setSendToken: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  resetScholarReward: (id: string) => void;
}

const EarningsRow: FC<IProps> = ({
  row,
  setSendToken,
  gameId,
  onChange,
  resetScholarReward,
}) => {
  const [loading, setloading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const games = useSelector((state: RootState) => state.games.games);
  const handleCopyAddress = (address: string) => {
    navigator.clipboard.writeText(address);
    toast('Address copied!');
  };

  const menu = useRef<HTMLDivElement>(null);

  const handlePayout = async () => {
    try {
      setloading(true);
      handleCloseMenu();
      if (row.original.payable === 0) {
        alert('Payout amount should be greater than 0');
        return;
      }
      if (gameId === games.find(game => game.name === 'Benji Bananas')?.id) {
        await benjiPayout({
          account_id: row.original.account_id,
          game_id: gameId,
          value: String(row.original?.payable),
          season: row.original?.season,
        });
      } else {
        await payout({
          account_id: row.original.account_id,
          game_id: gameId,
          value: String(row.original?.payable),
        });
      }

      resetScholarReward(row.original.id);
      showToast('success', 'Payout successful');
    } catch (err: any) {
      dispatch.errorModal.openDialog({ error: err.message });
      console.log(err.message);
    } finally {
      setloading(false);
    }
  };

  const handleMenuClick = (e: React.MouseEvent<HTMLImageElement>) => {
    const boundries = e.currentTarget.getBoundingClientRect();
    const top = boundries.top + window.scrollY;
    const left =
      window.innerWidth < boundries.left
        ? window.innerWidth - 60
        : boundries.left;
    if (menu.current) {
      menu.current.classList.add('show');
      menu.current.style.left = `${left}px`;
      menu.current.style.top = `${top}px`;
    }
  };

  const handleCloseMenu = () => {
    if (menu.current) {
      menu.current.classList.remove('show');
    }
  };

  return (
    <tr {...row.getRowProps()}>
      <td>
        <Link to={`/dashboard/user/${row.original.id}`} className='link'>
          <div className='row' style={{ justifyContent: 'flex-start' }}>
            <img
              src={row.original.image_url || assets.user}
              style={{
                height: '36px',
                width: '36px',
                objectFit: 'cover',
                borderRadius: '50%',
              }}
              alt=''
            />
            <p className='font-size-14' style={{ textDecoration: 'none' }}>
              {row.original.nick_name}
            </p>
          </div>
        </Link>
      </td>
      <td>{row.original.email}</td>
      <td>
        <EthAddress address={row.original.wallet_address} />
      </td>
      <td>
        <EthAddress address={row.original.starz_wallet_address} />
      </td>
      <td>
        {row.original.scholar_percentage
          ? `${100 - row.original?.scholar_percentage}% / ${
              row.original.scholar_percentage
            }%`
          : '-/-'}
      </td>
      <td>
        <div className='row flex-start'>
          {loading ? (
            <ClipLoader color='white' size={20} speedMultiplier={0.5} />
          ) : (
            <input
              type='number'
              value={row.original?.payable}
              onChange={e => onChange(e, row.original.id)}
              id=''
              className='amount-input'
              placeholder='Amount (STZ)'
            />
          )}
        </div>
      </td>
      <td>
        {loading ? (
          <ClipLoader color='white' size={20} speedMultiplier={0.5} />
        ) : (
          <div className='row flex-start'>
            {/* <img src={assets.stzIcon} className='stz-icon' alt='' /> */}
            <p className='font-size-12'>
              {row.original?.total} {row.original?.symbol}
            </p>
          </div>
        )}
      </td>
      <td>
        <ClickAwayListener onClickAway={handleCloseMenu}>
          <Menu>
            <img
              src={assets.moreVert}
              alt=''
              onClick={handleMenuClick}
              style={{ cursor: 'pointer' }}
            />
            <div className='menu' ref={menu}>
              <Profile className='profile'>
                <img
                  src={row.original.image_url || assets.user}
                  className='user-image'
                  alt=''
                />
                <div>
                  <p className='font-size-14' style={{ fontWeight: '600' }}>
                    {row.original.nick_name}
                  </p>
                  <div className='row flex-start'>
                    <p className='font-size-10'>
                      {truncateString(row.original.wallet_address)}
                    </p>
                    <img
                      src={assets.copyIcon}
                      onClick={() =>
                        handleCopyAddress(row.original.wallet_address)
                      }
                      alt=''
                      className='copy-icon'
                    />
                  </div>
                </div>
              </Profile>
              <Divider />
              <div className='menu-items'>
                <div
                  className='menu-item'
                  onClick={() => navigate(`/dashboard/user/${row.original.id}`)}
                >
                  <p className='font-size-12'>View Performance</p>
                </div>
                <div className='menu-item' onClick={handlePayout}>
                  <p className='font-size-12'>Give Payout</p>
                </div>
                <div
                  className='menu-item'
                  onClick={() => {
                    dispatch.scholars.setSelectedScholar(row.original);
                    setSendToken(true);
                    handleCloseMenu();
                  }}
                >
                  <p className='font-size-12'>Send Fungible Tokens</p>
                </div>
              </div>
            </div>
          </Menu>
        </ClickAwayListener>
      </td>
    </tr>
  );
};

export default EarningsRow;

const Menu = styled.div`
  & .menu {
    position: absolute;
    min-width: 208px;
    background-color: #0e0e0e;
    display: none;
    transform: translate(-95%, -100%);
    &.show {
      display: block;
    }
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);

    & .menu-items {
      padding-block: 4px;
    }

    & .menu-item {
      padding: 8px 16px;
      cursor: pointer;
      & p {
        opacity: 0.4;
      }

      &:hover {
        background-color: #000000;
        & p {
          opacity: 1;
        }
      }
    }
  }
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px;

  & img.user-image {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    object-fit: cover;
  }
  & .row {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 2px;

    & p {
      padding: 2px;
      border-radius: 2px;
      background-color: #ffffff1a;
    }

    & img.copy-icon {
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
  }
`;

const Divider = styled.div`
  border-top: 1px solid #ffffff33;
  margin-inline: 8px;
`;
