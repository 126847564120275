import React, { FC, useRef, useEffect, useState } from "react";

import styled from "styled-components";
import { Row } from "react-table";
import ClickAwayListener from "react-click-away-listener";
import { Link, useNavigate } from "react-router-dom";

import EthAddress from "../../components/EthAddress/EthAddress";

import {
  IMintedBadge,
  IScholarGame,
  IScholarUser,
} from "../../types/interfaces";

import { badgeImagesByName } from "../../app.config";

import assets from "../../assets";

import { showToast, truncateString } from "../../utils";
import { getScholarGames, getUserMintedBadges } from "../../http";
import Loader from "react-spinners/CircleLoader";
import theme from "../../theme";

interface IProps {
  row: Row<IScholarUser>;
}

const TableRow: FC<IProps> = ({ row }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [scholarGames, setScholarGames] = useState<IScholarGame[]>([]);
  const [highestBadge, setHighestBadge] = useState<IMintedBadge | null>(null);

  const menu = useRef<HTMLDivElement>(null);

  const handleMenuClick = (e: React.MouseEvent<HTMLImageElement>) => {
    const boundries = e.currentTarget.getBoundingClientRect();
    const top = boundries.top + window.scrollY;
    const left =
      window.innerWidth < boundries.left
        ? window.innerWidth - 60
        : boundries.left;
    if (menu.current) {
      menu.current.classList.add("show");
      menu.current.style.left = `${left}px`;
      menu.current.style.top = `${top}px`;
    }
  };

  const handleCopyAddress = (address: string) => {
    navigator.clipboard.writeText(address);
    showToast("success", "Address copied successfuly!");
  };

  const handleCloseMenu = () => {
    if (menu.current) {
      menu.current.classList.remove("show");
    }
  };

  const handleRenderGames = (list: IScholarGame[]): any => {
    let newAssets;
    let remaining;
    if (list.length > 5) {
      newAssets = list.slice(0, 5);
      remaining = list.length - 5;
    } else {
      newAssets = list;
    }
    return (
      <div className="games-container">
        {newAssets.map((game, index) => (
          <div className="game" key={index}>
            <img src={game.mini_icon} alt="" />
          </div>
        ))}
        {remaining && (
          <p className="font-size-8 more-text">+ {remaining} more</p>
        )}
      </div>
    );
  };

  const handleGetScholarGames = async () => {
    try {
      setLoading(true);
      const res = await Promise.all([
        getScholarGames(row.original.id),
        getUserMintedBadges(row.original.id),
      ]);
      setScholarGames(res[0].data);
      // if (res[0].data.length) {

      // }
      // const { data: nfts } = await getScholarAssets({
      //   public_address: row.original.starz_wallet_address,
      //   user_id: row.original.id,
      // });
      // console.log(nfts);
      const mintBadges: IMintedBadge[] = res[1].data;
      let highest = mintBadges[0];
      mintBadges.forEach((b) => {
        if (b.badge_type > highest.badge_type) {
          highest = b;
        }
      });
      setHighestBadge(highest);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetScholarGames();
    // eslint-disable-next-line
  }, []);

  return (
    <tr>
      <td>
        <Link
          to={`/dashboard/user-performance/${row.original.id}`}
          className="link"
        >
          <div className="row">
            <img
              src={row.original.image_url || assets.user}
              style={{
                height: "36px",
                width: "36px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
              alt=""
            />

            <p className="font-size-14">{row.original?.nick_name}</p>
          </div>
        </Link>
      </td>
      <td>
        <div className="row">
          <p className="font-size-14">
            {/* {truncateString(row.original.wallet_address)} */}
            {row.original?.starz_wallet_address}
          </p>
        </div>
      </td>
      <td>
        <EthAddress address={row.original?.email} />
      </td>
      <td>
        <div className="row">
          {loading ? (
            <Loader size={10} color={theme.palette.primary} />
          ) : (
            <>
              <img
                src={highestBadge ? badgeImagesByName[highestBadge?.name] : ""}
                alt=""
              />
              <p
                className="font-size-14"
                style={{ textTransform: "capitalize" }}
              >
                {row.original?.role}
              </p>
            </>
          )}
        </div>
      </td>
      <td>
        {loading ? (
          <Loader size={10} color={theme.palette.primary} />
        ) : (
          handleRenderGames(scholarGames)
        )}
      </td>
      <td>
        <p className="font-size-14">
          {`${100 - row.original?.scholar_percentage}% /`}
          {` ${row.original?.scholar_percentage}%`}
        </p>
      </td>
      <td>
        <ClickAwayListener onClickAway={handleCloseMenu}>
          <Menu>
            <img
              src={assets.moreVert}
              alt=""
              onClick={handleMenuClick}
              style={{ cursor: "pointer" }}
            />
            <div className="menu" ref={menu}>
              <Profile className="profile">
                <img
                  src={row.original.image_url || assets.user}
                  className="user-image"
                  alt=""
                />
                <div>
                  <p className="font-size-14" style={{ fontWeight: "600" }}>
                    {row.original.nick_name}
                  </p>
                  <div className="row">
                    <p className="font-size-10">
                      {truncateString(row.original.wallet_address)}
                    </p>
                    <img
                      src={assets.copyIcon}
                      onClick={() =>
                        handleCopyAddress(row.original.wallet_address)
                      }
                      alt=""
                      className="copy-icon"
                    />
                  </div>
                </div>
              </Profile>
              <Divider />
              <div className="menu-items">
                <div
                  className="menu-item"
                  onClick={() => navigate(`/dashboard/user/${row.original.id}`)}
                >
                  <p className="font-size-12">Scholar Profile</p>
                </div>
                {/* <div
                  className="menu-item"
                  onClick={() => navigate(`/dashboard/user/${row.original.id}`)}
                >
                  <p className="font-size-12">Edit Assets</p>
                </div> */}
                {/* <div
                  className="menu-item"
                  onClick={() => navigate(`/dashboard/user/${row.original.id}`)}
                >
                  <p className="font-size-12">Change Revenue Split</p>
                </div> */}
              </div>
            </div>
          </Menu>
        </ClickAwayListener>
      </td>
    </tr>
  );
};

export default TableRow;

const Menu = styled.div`
  & .menu {
    position: absolute;
    min-width: 208px;
    background-color: #0e0e0e;
    display: none;
    transform: translate(-95%, -100%);
    &.show {
      display: block;
    }
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);

    & .menu-items {
      padding-block: 4px;
    }

    & .menu-item {
      padding: 8px 16px;
      cursor: pointer;
      & p {
        opacity: 0.4;
      }

      &:hover {
        background-color: #000000;
        & p {
          opacity: 1;
        }
      }
    }
  }
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px;

  & img.user-image {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    object-fit: cover;
  }
  & .row {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 2px;

    & p {
      padding: 2px;
      border-radius: 2px;
      background-color: #ffffff1a;
    }

    & img.copy-icon {
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
  }
`;

const Divider = styled.div`
  border-top: 1px solid #ffffff33;
  margin-inline: 8px;
`;
