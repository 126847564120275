import React, { FC, useState, useEffect } from 'react';

import styled from 'styled-components';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../store';

import Button from '../components/Button/Button';
import Select from '../components/Select/Select';
import DialogLayout from './Dialog';
import TokenAmountSelector from '../components/TokenAmountSelector/TokenAmountSelector';

import assets from '../assets';
import {
  INfts,
  ISingleScholar,
  INFTContract,
  IScholarNftResponse,
  IGameTokenBalance,
} from '../types/interfaces';
// import NftImage from '../components/NftImage/NftImage';
import {
  gameFungibleTokens,
  getGameNftContract,
  getNftsByGameAndChain,
  pullbackNfts,
  transferFungibleToken,
} from '../http';
import axios from 'axios';
import Loader from '../components/Loader/Loader';
import NftImage from '../components/NftImage/NftImage';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  user: ISingleScholar | null;
  getUserData: () => void;
}

const EditAssetsDialog: FC<IProps> = ({ open, setOpen, user, getUserData }) => {
  const dispatch = useDispatch<Dispatch>();
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingNfts, setLoadingNFts] = useState(false);
  const [tokenBalance, setTokenBalance] = useState<IGameTokenBalance | null>(
    null
  );

  const [balanceLoading, setBalanceLoading] = useState(false);
  const [value, setValue] = useState('');

  const [allNfts, setAllNfts] = useState<INfts[]>([]);
  const [selectedNfts, setSelectedNfts] = useState<INfts[]>([]);
  const chains = useSelector((state: RootState) => state.chains.nft_contract);

  const [chainOpetions, setChainOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);

  const games = useSelector(
    (state: RootState) => state.games.gamesWithContracts
  );

  const [filters, setFilters] = useState({
    game: games[0]?.game_id || '',
    chain: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.currentTarget;

    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleSelectNft = (nft: INfts) => {
    if (selectedNfts.length === 10) {
      return dispatch.feedback.open({
        title: 'NFT pullback limit reached!',
        subtitle: 'You cannot pullback more than 10 nfts at a time',
      });
    }
    const _allNfts = allNfts.filter(_nft => _nft.token_hash !== nft.token_hash);
    setSelectedNfts(prev => [...prev, nft]);
    setAllNfts([..._allNfts]);
  };
  const handleDeSelectNft = (nft: INfts) => {
    const _selected = selectedNfts.filter(
      _nft => _nft.token_hash !== nft.token_hash
    );
    setAllNfts(prev => [...prev, nft]);
    setSelectedNfts([..._selected]);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      if (user) {
        const data = selectedNfts.map(nft => {
          if (nft.contract_type === 'ERC1155') {
            return {
              token_id: nft.token_id!,
              game_id: nft.game_id!,
              contract_id: nft.contract_id!,
              amount: nft.amount,
            };
          } else {
            return {
              token_id: nft.token_id!,
              game_id: nft.game_id!,
              contract_id: nft.contract_id!,
            };
          }
        });

        await pullbackNfts({
          Nfts: data,
          user_id: user.id,
          chain: filters.chain,
        });
        getUserData();
        setOpen(false);
      }
      dispatch.feedback.open({
        title: 'Success',
        subtitle: 'Nft pulled back successfully',
      });
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const errorMessage = (err as any).response.data.message;
        dispatch.feedback.open({ title: 'Error', subtitle: errorMessage });
      } else {
        dispatch.feedback.open({ title: 'Error', subtitle: err.message });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGameChainsFilter = async () => {
    if (games.length && filters.game) {
      const currentGame = games.find(game => game.game_id === filters.game)!;
      const { data } = await getGameNftContract(currentGame?.game_id);
      const options: Array<{ label: string; value: string }> = [];
      (data as INFTContract[]).forEach(contract => {
        chains.forEach(c => {
          if (c.chain === contract.chain) {
            const exist = options.find(op => op.value === c.chain);
            if (!exist) {
              options.push({ label: c.name, value: c.chain });
            }
          }
        });
      });
      setChainOptions(options);
    }
  };

  const getGameTokenBalance = async () => {
    try {
      setBalanceLoading(true);
      const { data } = await gameFungibleTokens(filters.game);
      setTokenBalance(data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setBalanceLoading(false);
    }
  };

  const handleTransferToken = async () => {
    try {
      setLoading(true);
      if (+value > +tokenBalance!.balance) {
        dispatch.feedback.open({
          title: 'Invalid token amount',
          subtitle: `You do not have enough ${tokenBalance?.symbol} tokens`,
        });
      } else {
        const apiData = {
          game_id: filters.game,
          to: user!.accounts[0]!.starz_wallet_address,
          value: value,
        };
        // eslint-disable-next-line
        const { data } = await transferFungibleToken(apiData);
        getGameTokenBalance();
        setValue('');
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!filters.game && games.length) {
      setFilters(prev => ({ ...prev, game: games[0]?.game_id }));
    }
    handleGameChainsFilter();
    // eslint-disable-next-line
  }, [games, filters]);

  useEffect(() => {
    if (filters.game) {
      getGameTokenBalance();
    }
    if (filters.game && filters.chain) {
      (async () => {
        setLoadingNFts(true);
        try {
          const { data } = await getNftsByGameAndChain({
            public_address: user?.accounts[0].starz_wallet_address!,
            gameId: filters.game,
            chainId: filters.chain,
          });
          let sNfts: INfts[] = [];
          (data as IScholarNftResponse[]).forEach(e => {
            e.Nfts.forEach(nft => {
              sNfts.push({
                ...nft,
                contract: e.contract,
                game_id: e.game_id,
                contract_id: e.contract_id,
              });
            });
          });
          setAllNfts(sNfts);
        } catch (err: any) {
          console.log(err.message);
        } finally {
          setLoadingNFts(false);
        }
      })();
    }
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    dispatch.games.gamesWithContracts();
    dispatch.chains.getChains();
    // eslint-disable-next-line
  }, []);
  return (
    <DialogLayout open={open}>
      <Container>
        <div className='close-button'>
          <img src={assets.closeIcon} alt='' onClick={() => setOpen(false)} />
        </div>

        <Content>
          <h1 className='title'>Edit Assets For {user?.nick_name}</h1>

          <Tabs>
            <p
              className={classNames(
                'tab font-size-14 title',
                tab === 0 && 'active'
              )}
              onClick={() => setTab(0)}
            >
              NFTs
            </p>
            <p
              className={classNames(
                'tab font-size-14 title',
                tab === 1 && 'active'
              )}
              onClick={() => setTab(1)}
            >
              Fungible Tokens
            </p>
          </Tabs>

          {tab === 0 && (
            <>
              <Filters>
                <Select
                  title='Select game'
                  options={games.map(game => ({
                    label: game.name,
                    value: game.game_id,
                  }))}
                  onChange={handleChange}
                  name='game'
                  value={filters.game}
                />
                <Select
                  title='Select chain'
                  options={chainOpetions}
                  onChange={handleChange}
                  name='chain'
                  value={filters.chain}
                />
              </Filters>
              <Assets>
                {selectedNfts.map((nft, index) => {
                  // const metaData = JSON.parse(nft.metadata);
                  return (
                    <div className='user-asset' key={index}>
                      <div
                        className='remove-btn'
                        onClick={() => handleDeSelectNft(nft)}
                      >
                        <img src={assets.closeIcon} alt='' />
                      </div>
                      <NftImage nftData={nft} size={60} />
                      {/* <img
                        src={metaData.image_url || metaData.image}
                        alt=''
                        style={{ width: '100%' }}
                      /> */}
                    </div>
                  );
                })}
              </Assets>
              <Divider />
              <SearchBar htmlFor='search-bar'>
                <img src={assets.searchIcon} alt='' />
                <input
                  type='text'
                  name=''
                  className='font-size-12'
                  id='search-bar'
                  placeholder='Search'
                />
              </SearchBar>

              {loadingNfts ? (
                <Loader noPadding />
              ) : (
                <Assets style={{ marginBottom: '30px' }}>
                  {allNfts.map((nft, index) => {
                    const metaData = JSON.parse(nft.metadata);
                    console.log(metaData);
                    return (
                      <div
                        className='user-asset'
                        key={index}
                        onClick={() => handleSelectNft(nft)}
                        style={{ cursor: 'pointer' }}
                      >
                        <NftImage nftData={nft} size={60} />
                        {/* {metaData && (
                          <img
                            src={metaData?.image_url || metaData?.image}
                            alt=''
                            style={{ width: '100%' }}
                          />
                        )} */}
                      </div>
                    );
                  })}
                </Assets>
              )}
            </>
          )}

          {tab === 1 && (
            <>
              <Filters>
                <Select
                  title='Select game'
                  options={games.map(game => ({
                    label: game.name,
                    value: game.game_id,
                  }))}
                  onChange={handleChange}
                  name='game'
                  value={filters.game}
                />
              </Filters>
              {/* <TokenContainer>
                <TokenCount>
                  <img src={assets.THC} alt='' />
                  <div>
                    <p className='font-size-20'>
                      889.56 <span className='font-size-12'>THC</span>
                    </p>
                    <p className='font-size-10' style={{ opacity: '0.6' }}>
                      ≈ 16.69 USD
                    </p>
                  </div>
                </TokenCount>
                <TokenCount>
                  <img src={assets.stzIcon} alt='' />
                  <div>
                    <p className='font-size-20'>
                      567.23 <span className='font-size-12'>STZ</span>
                    </p>
                    <p className='font-size-10' style={{ opacity: '0.6' }}>
                      ≈ 16.69 USD
                    </p>
                  </div>
                </TokenCount>
              </TokenContainer> */}
              <Divider />
              {balanceLoading ? (
                <Loader noPadding style={{ paddingBlock: '30px' }} />
              ) : (
                <TokenSelectorContainer>
                  {tokenBalance && (
                    <>
                      <TokenAmountSelector
                        tokenBalance={tokenBalance}
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setValue(e.currentTarget.value)
                        }
                      />
                      <div className='remainingTokens'>
                        <img src={assets.stzIcon} alt='' />
                        <div>
                          <p className='font-size-18'>
                            {tokenBalance.balance} {tokenBalance.symbol}
                          </p>
                          <p
                            className='font-size-10'
                            style={{ opacity: '0.4' }}
                          >
                            left available for you to transfer
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </TokenSelectorContainer>
              )}
            </>
          )}

          <ButtonContainer>
            <Button variant='secondary' onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={() => (tab === 0 ? handleSave() : handleTransferToken())}
              disabled={loading}
              loading={loading}
            >
              Save Changes
            </Button>
          </ButtonContainer>
        </Content>
      </Container>
    </DialogLayout>
  );
};

export default EditAssetsDialog;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  & .close-button {
    display: flex;
    justify-content: flex-end;
    padding-block: 6px;
    & img {
      cursor: pointer;
    }
  }
`;

const Content = styled.div`
  background: #121212;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
  padding: 40px 30px;

  @media only screen and (max-width: 578px) {
    padding: 20px;
  }
`;

const Assets = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  margin-block: 20px;
  max-height: 210px;
  overflow: auto;
  padding-top: 10px;

  & .user-asset {
    height: 60px;
    width: 60px;
    position: relative;
    border-radius: 6px;
    & img.asset-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    & .remove-btn {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(50%, -50%);
      height: 16px;
      width: 16px;
      border: 1px solid #ffffff33;
      background-color: #000000;
      border-radius: 30px;
      display: grid;
      place-items: center;
      cursor: pointer;
      & img {
        width: 6px;
        opacity: 0.4;
      }

      &:hover {
        & img {
          opacity: 1;
        }
      }
    }
  }
`;

const Divider = styled.div`
  border-top: 1px solid #ffffff1a;
`;

const SearchBar = styled.label`
  height: 34px;
  display: flex;
  align-items: center;
  padding-inline: 16px;
  gap: 8px;
  background-color: #000000;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  margin-block: 20px 4px;

  & input {
    flex-grow: 1;
    outline: none;
    border: none;
    background-color: transparent;
    height: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media only screen and (max-width: 578px) {
    gap: 10px;
    & button {
      height: 40px;
    }
  }
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  border-bottom: 1px solid #ffffff33;
  margin-top: 24px;
  padding-bottom: 8px;
  padding-inline: 16px;

  & .tab {
    cursor: pointer;
    opacity: 0.4;
    transition: 0.4s ease;
    &.active {
      opacity: 1;
    }
  }
`;

// const TokenContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 30px;
//   padding-block: 24px;
//   padding-inline: 16px;
// `;

// const TokenCount = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 10px;

//   & img {
//     width: 30px;
//   }
// `;

const TokenSelectorContainer = styled.div`
  padding-block: 24px 32px;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  align-items: center;

  & .remainingTokens {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    margin-top: 30px;
  }
`;

const Filters = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-block: 40px 20px;
`;
