import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';

import { CircleLoader } from 'react-spinners';
import { useDispatch } from 'react-redux';
import { Dispatch } from '../../../store';

import Button from '../../../components/Button/Button';
import EthAddress from '../../../components/EthAddress/EthAddress';

import { benjiPerformance, captainPayout } from '../../../http';
import theme from '../../../theme';
import { IScholarUser } from '../../../types/interfaces';
import { toast } from 'react-toastify';
import assets from '../../../assets';

interface IProps {
  scholar: IScholarUser;
  captain: IScholarUser;
  showScholar: boolean;
  setShowScholar: React.Dispatch<React.SetStateAction<boolean>>;
  gameId: string;
  setTotalEarnings: React.Dispatch<React.SetStateAction<number>>;
}

const CaptainScholarRow: FC<IProps> = ({
  scholar,
  captain,
  showScholar,
  gameId,
  setTotalEarnings,
}) => {
  const dispatch = useDispatch<Dispatch>();
  // const [performance, setPeroformance] = useState<IBenjiPerformance | null>(
  //   null
  // );
  const [loading, setLoading] = useState(false);
  const [payoutLoading, setPayoutLoading] = useState(false);
  const [payout, setPayout] = useState(0);
  const [earnings, setEarnings] = useState(0);
  const [season, setSeason] = useState('');
  const [captainPayment, setCaptainPayment] = useState(0);
  const [alreadyPayout, setAlreadyPayout] = useState(false);

  const handleGetPerformance = async () => {
    try {
      setLoading(true);
      const { data } = await benjiPerformance(scholar.starz_wallet_address);
      // setPeroformance(data);
      setSeason(data.performanceStats[0]?.season);
      setAlreadyPayout(data.isPayout);
      const amount: number = +data.performanceStats[0]?.amount || 0;
      if (typeof amount === 'number') {
        setTotalEarnings(prev => prev + amount);
      }
      setEarnings(amount || 0);
      const captainCut = (amount / 100) * +captain.scholar_percentage;
      setCaptainPayment(captainCut);
      const scholarCut = (captainCut / 100) * +scholar.scholar_percentage;
      setPayout(scholarCut);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePayout = async () => {
    try {
      setPayoutLoading(true);
      const apiData = {
        season: season,
        captain_amount: captainPayment - payout,
        scholar_amount: payout,
        game_id: gameId,
        scholar_id: scholar.id,
        captain_id: captain.id,
      };

      const { data } = await captainPayout(apiData);
      console.log(data);
      toast.success('Payout successfully!');
      handleGetPerformance();
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const errorMessage = (err as any).response?.data.message;
        dispatch.feedback.open({ title: 'Error!', subtitle: errorMessage });
      }
    } finally {
      setPayoutLoading(false);
    }
  };

  useEffect(() => {
    if (showScholar) {
      handleGetPerformance();
    }
    // eslint-disable-next-line
  }, [showScholar]);
  return (
    <>
      <tr>
        <td>
          <div className='row' style={{ justifyContent: 'flex-start' }}>
            <img
              src={scholar.image_url || assets.noImage}
              style={{
                height: '30px',
                width: '30px',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              alt=''
            />
            <p className='font-size-14'>{scholar.fullname || '------'}</p>
          </div>
        </td>
        <td>
          <EthAddress address={scholar.wallet_address} />
        </td>
        <td>
          <EthAddress address={scholar.starz_wallet_address} />
        </td>
        <td>
          {100 - scholar.scholar_percentage}% / {scholar.scholar_percentage}%
        </td>
        <td>
          {loading ? (
            <CircleLoader size={10} color={theme.palette.primary} />
          ) : (
            `${earnings} PRIMATE`
          )}
        </td>
        <td>
          {loading ? (
            <CircleLoader size={10} color={theme.palette.primary} />
          ) : (
            `${payout.toFixed(3) || 0} PRIMATE`
          )}
        </td>
        <td>
          <Button
            variant='primary'
            style={{ height: '30px' }}
            disabled={loading || alreadyPayout || !payout || payoutLoading}
            onClick={handlePayout}
          >
            Payout
          </Button>
        </td>
      </tr>
    </>
  );
};

export default CaptainScholarRow;
