import React, { FC, useEffect, useState } from 'react';

import { Row } from 'react-table';

import Button from '../../components/Button/Button';
import EthAddress from '../../components/EthAddress/EthAddress';

import assets from '../../assets';
import { IScholarUser, IMintedBadge } from '../../types/interfaces';

import { getScholarBadges } from '../../http';

interface IProps {
  row: Row<IScholarUser>;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setScholar: React.Dispatch<React.SetStateAction<IScholarUser | null>>;
}

const PullbacksRow: FC<IProps> = ({ row, setOpenDialog, setScholar }) => {
  const [badges, setBadges] = useState<Array<IMintedBadge> | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getScholarBadges(row.original.id);
        setBadges(data);
        // console.log(data);
      } catch (err: any) {
        console.log(err.message);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <tr {...row.getRowProps()}>
      <td>
        <div className='row flex-start'>
          <img
            src={row.original.image_url ? row.original.image_url : assets.user}
            className='user-img'
            alt=''
          />
          <p className='font-size-12'>{row.original.fullname}</p>
        </div>
      </td>
      <td>
        <EthAddress address={row.original.wallet_address} />
      </td>
      <td>
        <EthAddress address={row.original.starz_wallet_address} />
      </td>
      <td>
        <div className='row flex-start' style={{ gap: '20px' }}>
          {badges?.map((badge, i) => (
            <img
              className='badge-image'
              key={i}
              src={badge.badge_logo}
              alt=''
            />
          ))}
        </div>
      </td>
      <td className='text-center'>-</td>
      <td>
        <Button
          variant='transparent'
          onClick={() => {
            setScholar(row.original);
            setOpenDialog(true);
          }}
          className='done-btn'
        >
          Withdraw
        </Button>
      </td>
    </tr>
  );
};

export default PullbacksRow;
