import React, { FC, useEffect, useState } from "react";

import styled from "styled-components";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";

import { useSelector } from "react-redux";
import { RootState } from "../../store";

import Game from "../Game/Game";
import assets from "../../assets";
import ScholarCard from "../ScholarshipCard/ScholarshipCard";

interface IProps {
  editScholarShip: boolean;
  setEditScholarShip: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScholarshipSlider: FC<IProps> = ({
  editScholarShip,
  setEditScholarShip,
}) => {
  // eslint-disable-next-line
  const [showArrows, setShowArrows] = useState(true);
  const scholarships = useSelector(
    (state: RootState) => state.scholarship.scholarships
  );

  const handleWindowResize = () => {
    // if (window.innerWidth > 1000 && scholarships.length > 3) {
    //   setShowArrows(true);
    // } else if (
    //   window.innerWidth > 500 &&
    //   window.innerWidth <= 800 &&
    //   scholarships.length > 2
    // ) {
    //   setShowArrows(true);
    // } else if (window.innerWidth <= 500 && scholarships.length > 1) {
    //   setShowArrows(true);
    // } else {
    //   setShowArrows(false);
    // }
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Container>
      {/* @ts-ignore */}
      <Splide
        renderControls={() => (
          <>
            {showArrows && (
              <div className="splide__arrows">
                <div className="splide__arrow--prev" role="button">
                  <img src={assets.arrowLeft} alt="" />
                </div>
                <div className="splide__arrow--next" role="button">
                  <img src={assets.arrowRight} alt="" />
                </div>
              </div>
            )}
          </>
        )}
        options={{
          perPage: 3,
          gap: "24px",
          pagination: false,
          rewind: true,
          breakpoints: {
            1200: {
              perPage: 3,
              gap: "24px",
            },
            1000: {
              perPage: 2,
              gap: "20px",
            },
            500: {
              perPage: 1,
              gap: "16px",
            },
          },
        }}
      >
        {scholarships ? (
          <>
            {scholarships.map((scholarship, index) => (
              <SplideSlide key={index}>
                <ScholarCard
                  scholarship={scholarship}
                  editScholarShip={editScholarShip}
                  setEditScholarShip={setEditScholarShip}
                />
              </SplideSlide>
            ))}
          </>
        ) : (
          <>
            {Array.from({ length: 12 }, (_, i) => i).map((i) => (
              <SplideSlide key={i}>
                <Game />
              </SplideSlide>
            ))}
          </>
        )}
      </Splide>
    </Container>
  );
};

export default ScholarshipSlider;

const Container = styled.div`
  & .splide__arrows {
    & .splide__arrow--prev {
      position: absolute;
      top: 10rem;
      left: 0px;
      /* height: calc(100%); */
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      /* background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%); */
      background-color: transparent;
      cursor: pointer;
    }
    & .splide__arrow--next {
      position: absolute;
      top: 10rem;
      right: 0px;
      /* height: calc(100%); */
      height: 20px;
      background-color: red;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      /* background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000000 100%); */
      background-color: transparent;
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 700px) {
    & .splide__arrows {
      & .splide__arrow--prev,
      & .splide__arrow--next {
        width: 50px;
        & img {
          width: 10px;
        }
      }
    }
  }
  @media only screen and (max-width: 420px) {
    & .splide__arrows {
      & .splide__arrow--prev,
      & .splide__arrow--next {
        width: 40px;
        & img {
          width: 10px;
        }
      }
    }
  }
`;
