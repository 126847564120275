import { createGlobalStyle } from 'styled-components';
import theme from '.';

export default createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Krona One', sans-serif;
  font-family: 'Montserrat', sans-serif;
}

body {
  background-color: #000000;
  
  position: relative;
}
h1 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}

h2 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400px;
}

h3 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

h1,h2,h3,h4,h5,h6,p,th,td{
  color: #ffffff
}

.apexcharts-tooltip-marker{
  color: #000000;
}

.font-size-10 {
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  color: #ffffff
}

.font-size-8{
  font-size: 8px;
  line-height: 14px;
  font-weight: 400;
}

.font-size-12 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #ffffff
}

.font-size-14 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #ffffff
}

.font-size-16 {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #ffffff
}

.font-size-18 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  color: #ffffff
}

.font-size-20 {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  color: #ffffff
}

.title {
  font-family: 'Krona One', sans-serif;
}

.anchor {
  text-decoration: none;
  color: ${theme.palette.anchor};
}

.anchor-secondary {
  text-decoration: none;
  color: ${theme.palette.secondary};
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.text-center {
 
  text-align: center;
}

.text-right{
  text-align: right;
}

.link{
  text-decoration: none;
}

.warning{
  font-size: 10px;
  line-height: 14px;
  color: ${theme.palette.secondary};
  
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button{
  display: none;
  margin: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background-position: right;
    background-size: auto;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: auto;
}


input[type="time"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

@media only screen and (max-width: 700px) {
  h1.title {
    font-size: 20px;
    line-height: 28px;
  }
}


/* scroll bars */


::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      border-radius: 2px;
      background: #000000;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #FFFFFF;
      border-radius: 2px;
    }

    @keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}


`;
