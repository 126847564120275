import React, { FC, useState, useEffect, useMemo } from 'react';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from '../../store';
import { useTable, useSortBy } from 'react-table';
import { useNavigate } from 'react-router-dom';

import Page from '../../layouts/Page';
import Filters from '../../components/Filters/Filters';
import Select from '../../components/Select/Select';

import assets from '../../assets';
import { IScholarUser } from '../../types/interfaces';
import Loader from '../../components/Loader/Loader';

const tableColumns = [
  {
    Header: 'Captain',
    accessor: 'nick_name',
    disableSortBy: true,
  },
  {
    Header: 'Email Address',
    accessor: 'email',
    disableSortBy: true,
  },
  {
    Header: 'Wallet Address',
    accessor: 'walletAddress',
    disableSortBy: true,
  },
  {
    Header: 'Revenue Split (Captain / Admin)',
    accessor: 'revinueSplit',
    disableSortBy: true,
  },
];

const Captains: FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const columns = useMemo(() => tableColumns, []);

  const captains = useSelector((state: RootState) => state.captain.captains);
  const loading = useSelector((state: RootState) => state.captain.loading);

  const tableInstance = useTable(
    {
      // @ts-ignore
      columns,
      data: captains,
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } =
    tableInstance;

  const [filters, setFilters] = useState({
    game: '',
  });

  const games = useSelector((state: RootState) => state.games.games);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.currentTarget;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleRowClick = (captain: IScholarUser) => {
    dispatch.captain.setSelected(captain);
    navigate('/dashboard/captain-details');
  };

  useEffect(() => {
    dispatch.games.getAllGames();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (games.length) {
      setFilters(prev => ({ ...prev, game: games[0].id }));
    }
  }, [games]);

  useEffect(() => {
    if (filters.game) {
      dispatch.captain.getFilteredCaptains({ gameId: filters.game });
    }
    // eslint-disable-next-line
  }, [filters]);

  return (
    <Page>
      <h1 className='title'>Captains</h1>
      <Container>
        <Filters>
          <Grid>
            <Select
              name='game'
              title='Game'
              value={filters.game}
              onChange={handleChange}
              options={games.map(game => ({
                label: game.name,
                value: game.id,
              }))}
            />
          </Grid>
        </Filters>
        {loading && !captains.length ? (
          <Loader />
        ) : (
          <TableContainer>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr
                    className='header-row'
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map(column => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <div className='row'>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <>
                                <div className='col'>
                                  <img
                                    src={assets.sortArrowUp}
                                    style={{ opacity: 0.6 }}
                                    alt=''
                                  />
                                  <img
                                    src={assets.sortArrowUp}
                                    style={{
                                      transform: 'rotate(180deg)',
                                    }}
                                    alt=''
                                  />
                                </div>
                              </>
                            ) : (
                              <div className='col'>
                                <img src={assets.sortArrowUp} alt='' />
                                <img
                                  src={assets.sortArrowUp}
                                  style={{
                                    transform: 'rotate(180deg)',
                                    opacity: 0.6,
                                  }}
                                  alt=''
                                />
                              </div>
                            )
                          ) : (
                            <>
                              {column.canSort && (
                                <div className='col'>
                                  <img
                                    src={assets.sortArrowUp}
                                    style={{ opacity: 0.6 }}
                                    alt=''
                                  />
                                  <img
                                    src={assets.sortArrowUp}
                                    style={{
                                      transform: 'rotate(180deg)',
                                      opacity: 0.6,
                                    }}
                                    alt=''
                                  />
                                </div>
                              )}
                            </>
                          )}
                          <div>{column.render('Header')}</div>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr
                      key={index}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleRowClick(row.original)}
                    >
                      <td>{row.original.nick_name}</td>
                      <td>{row.original.email}</td>
                      <td>{row.original.wallet_address}</td>
                      <td>
                        {100 - row.original.scholar_percentage}% /{' '}
                        {row.original.scholar_percentage}%
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TableContainer>
        )}
      </Container>
    </Page>
  );
};

export default Captains;

const Container = styled.div``;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
`;

const TableContainer = styled.div`
  overflow-x: auto;
  border: 1px solid #ffffff33;
  padding-bottom: 10px;
  width: 100%;
  margin-block: 30px 60px;
  border-radius: 4px;
  & table {
    border-collapse: collapse;
    min-width: 950px;
    width: 100%;
    & tbody {
      padding-inline: 10px;
    }
    & .header-row {
      height: 40px;
      background-color: #0e0e0e;
      & th {
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        opacity: 0.6;
        padding-top: 4px;
        border-bottom: 1px solid #ffffff33;
        text-align: left;
        &:first-of-type {
          padding-left: 32px;
        }

        & .row {
          display: flex;
          align-items: center;
          gap: 8px;

          & .col {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
        }
      }
    }
    & tr {
      height: 60px;
    }
    & td {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      border-bottom: 1px solid #ffffff33;
      padding-block: 12px;
      &:first-of-type {
        padding-left: 32px;
      }

      &:last-of-type {
        padding-right: 10px;
      }

      & .row {
        display: flex;
        align-items: center;
        gap: 10px;

        & img {
          width: 20px;
        }
      }

      & .icon-button {
        & img {
          padding-inline: 5px;
          cursor: pointer;
        }
        & .menu {
          position: absolute;
          width: 200px;
          background: #0e0e0e;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
          transform: translate(-90%, -115%);
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
          display: none;
          & .menu-item {
            padding: 10px 5px;
            cursor: pointer;
            &:hover {
              background-color: #000000;
            }
          }
          & p {
            opacity: 0.6;
          }
          &.show {
            display: block;
          }
        }
      }
    }
    & tr:last-child {
      & td {
        border: none;
      }
    }
  }
`;
