import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { IerrorModal } from '../../types/interfaces';

export const errorModal = createModel<RootModel>()({
  name: 'errorModal',
  state: {
    open: false,
    error: '',
    action: null,
  } as IerrorModal,
  reducers: {
    openDialog(state, payload: { error: string; action?: any }) {
      state.open = true;
      state.error = payload.error;
      state.action = payload?.action;
    },
    closeDialog(state) {
      state.open = false;
      state.error = '';
      state.action = null;
    },
  },
});
