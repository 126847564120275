import React, { FC } from 'react';

import styled from 'styled-components';
import { toast } from 'react-toastify';

interface IProps {
  address: string;
  style?: { [x: string]: string };
  overlyStyle?: { [x: string]: string };
}

const EthAddress: FC<IProps> = ({ address, style, overlyStyle }) => {
  const handleCopyAddress = () => {
    if (address) {
      navigator.clipboard.writeText(address);
      toast('Address copied');
    }
  };
  return (
    <Container onClick={handleCopyAddress} style={style} title='Click to copy'>
      {address ? address : '----------'}
      <div className='overly' style={overlyStyle}></div>
    </Container>
  );
};

export default EthAddress;

const Container = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 19ch;
  cursor: pointer;
  position: relative;
  & .overly {
    position: absolute;
    width: 40px;
    height: 100%;
    right: 0;
    top: 0;
    pointer-events: none;
    background: linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  }
`;
