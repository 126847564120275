import { Models } from '@rematch/core';
import { sideBar } from './sideBar';
import { dialogs } from './dialogs';
import { auth } from './auth';
import { games } from './games';
import { badges } from './badge';
import { errorModal } from './errorDialog';
import { scholars } from './scholars';
import { nfts } from './nfts';
import { chains } from './chains';
import { vault } from './vault';
import { scholarship } from './scholarship';
import { notifications } from './notifications';
import { feedback } from './feedback';
import { captain } from './captain';
export interface RootModel extends Models<RootModel> {
  sideBar: typeof sideBar;
  dialogs: typeof dialogs;
  auth: typeof auth;
  games: typeof games;
  badges: typeof badges;
  errorModal: typeof errorModal;
  scholars: typeof scholars;
  nfts: typeof nfts;
  chains: typeof chains;
  vault: typeof vault;
  scholarship: typeof scholarship;
  notifications: typeof notifications;
  feedback: typeof feedback;
  captain: typeof captain;
}

export const models: RootModel = {
  sideBar,
  dialogs,
  auth,
  games,
  badges,
  errorModal,
  scholars,
  nfts,
  chains,
  vault,
  scholarship,
  notifications,
  feedback,
  captain,
};
