import { createModel } from '@rematch/core';
import axios from 'axios';
import { RootModel } from '.';
import { getAllNft, getAllFilteredNft, createSet } from '../../http';
import {
  IGetNfts,
  INfts,
  IGetFilteredNfts,
  ICreateSetApiData,
  IVaultFilters,
  IVaultNft,
} from '../../types/interfaces';

interface IInitialState {
  allNfts: Array<INfts>;
  loading: boolean;
  selectedNft: IVaultNft | null;
  nftAmountToTransfer: number;
}

interface ICreateSet {
  showToast: (type: string | undefined, msg: string) => void;
  data: ICreateSetApiData;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  doFilter: boolean;
  filters: IVaultFilters;
}

export const nfts = createModel<RootModel>()({
  name: 'nfts',
  state: {
    allNfts: [],
    loading: false,
    selectedNft: null,
    nftAmountToTransfer: 0,
  } as IInitialState,
  reducers: {
    setAllNfts(state, payload: Array<INfts>) {
      state.allNfts = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setSelectedNft(state, payload: IVaultNft) {
      state.selectedNft = payload;
    },
    setNftAmountToTransfer(state, payload: number) {
      state.nftAmountToTransfer = payload;
    },
  },
  effects: dispatch => ({
    async getAllNfts(payload: IGetNfts) {
      dispatch.nfts.setLoading(true);
      try {
        const { data } = await getAllNft(payload);
        dispatch.nfts.setAllNfts(data);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log((err as any).response?.data);
        }
        console.log(err.message);
      } finally {
        dispatch.nfts.setLoading(false);
      }
    },
    async getFilteredNfts(payload: IGetFilteredNfts) {
      dispatch.nfts.setLoading(true);
      try {
        const { data } = await getAllFilteredNft(payload);
        dispatch.nfts.setAllNfts(data);
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          console.log((err as any).response?.data);
        }
      } finally {
        dispatch.nfts.setLoading(false);
      }
    },
    async createBundle(payload: ICreateSet, state) {
      dispatch.nfts.setLoading(true);

      try {
        // eslint-disable-next-line
        const { data } = await createSet(payload.data);
        payload.showToast('success', 'The set has created successfully!');
        payload.setOpen(false);
        if (payload.doFilter) {
          const selectedBadge = state.badges.badges.find(
            badge => badge.id === payload.filters.badge
          );
          const selected_game = state.games.games.find(
            e => e.id === payload.filters.selectGame
          );

          if (selected_game) {
            const apiData = {
              public_address: selected_game.public_address,
              badge_category: selectedBadge?.id || undefined,
              game_id: selected_game.id,
            };
            dispatch.nfts.getFilteredNfts(apiData);
          }
        } else {
          dispatch.nfts.getAllNfts({ game_id: payload.filters.selectGame });
        }
      } catch (err: any) {
        if (axios.isAxiosError(err)) {
          const errMessage = (err as any).response?.data.message;
          payload.showToast('error', errMessage);
          return;
        }
        payload.showToast('error', err.message);
      } finally {
        dispatch.nfts.setLoading(false);
      }
    },
  }),
});
