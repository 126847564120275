export enum BadgesEnum {
  BRONZE = 0,
  SILVER = 1,
  GOLD = 2,
}

export enum NotificationTypes {
  SCHOLARSHIP = 'scholarship',
  ASSET_TRANSFER = 'asset-transfer',
  OTHERGUILDSCHOLARS = 'otherguildscholars',
}
