import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';

import {
  // useSelector,
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  // RootState,
  Dispatch,
  RootState,
} from '../../store';
import { useNavigate } from 'react-router-dom';

import { array, InferType, object, string, mixed } from 'yup';
import { FormErrors, validateData } from '../../utils/validations';

import Select from '../../components/Select/Select';
import InputField from '../../components/InputField/InputField';
import ImagePicker from '../../components/ImagePicker/ImagePicker';
// import AddSimilarGame from '../../components/AddSimilarGame/AddSimilarGame';
import Button from '../../components/Button/Button';
import ScreenshotPicker from '../../components/ScreenshotPicker/ScreenshotPicker';
import TokenInput from '../../components/TokenInput/TokenInput';

import theme from '../../theme';
import assets from '../../assets';

import {
  addGame,
  addGameImages,
  addGameScreenShots,
  addGameNftContract,
  getGameNftContract,
  deleteGameNftContract,
  updateGameNftContract,
  updateGame,
  getGameScreenshots,
  deleteGameScreenshots,
  getTokenMetaData,
} from '../../http';
import axios from 'axios';
import { IScreenshot, ITokenMetadata } from '../../types/interfaces';

const intialContractData = {
  contract_chain: '',
  contract_address: '',
  contract_type: '',
  id: '',
};

const gameSchema = object({
  name: string().required().label('Game title'),
  erc_contract: string().optional().label('Erc contract'),
  erc_contract_chain: string().optional().label('Erc contract chain'),
  short_description: string().required().label('Short description'),
  description: string().required().label('Long description'),
  minimum_token: string().required().label('Minmum token'),
  minimum_gold_badge_token: string()
    .required()
    .label('Minmum gold badge token'),
  minimum_silver_badge_token: string()
    .required()
    .label('Minmum silver badge token'),
  minimum_bronze_badge_token: string()
    .required()
    .label('Minmum bronze badge token'),
  creators: string().required().label('Creators'),
  developers: string().required().label('Developers'),
  platforms: string().required().label('Platforms'),
  genres: string().required().label('Genres'),
  modes: string().required().label('Modes'),
  release_date: string().required().label('Release date'),
  guild_captain_stz_requirement: string().required().label('STZ'),
});

const imageSchema = object({
  icon: mixed().required('Game Icon'),
  logo: mixed().required().label('Game logo'),
  left_align_logo: mixed().required().label('Game left align logo'),
  banner: mixed().required().label('Game banner'),
  cover_phote: mixed().required().label('Game cover photo'),
});

const nftContractSchema = object({
  contract_address: string().optional().label('NFT Contract address'),
  contract_chain: string().optional().label('Contract chain'),
  contract_type: string().optional().label('Contract type'),
  id: string(),
});

const nftContractsSchema = array().of(nftContractSchema);

interface IGameData extends InferType<typeof gameSchema> {}

interface IGameImageData extends InferType<typeof imageSchema> {}

interface IGameContractData extends InferType<typeof nftContractSchema> {}

const AddNewGame: FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const [gameToken, setGameToken] = useState<ITokenMetadata | null>(null);

  const supportedChains = useSelector(
    (state: RootState) => state.chains.nft_contract
  );
  const supportedTypes = useSelector(
    (state: RootState) => state.chains.nft_type
  );

  // TODO : add game update functionality
  // eslint-disable-next-line
  const editGame = useSelector((state: RootState) => state.games.editGame);

  const [screenshots, setScreenshots] = useState<File[]>([]);
  const [prevScreenshots, setPrevScreenshots] = useState<IScreenshot[]>([]);
  const [deleteScreenshotIds, setDeleteScreenshotsIds] = useState<string[]>([]);

  // const badges = useSelector((state: RootState) => state.badges.badges);
  const [loading, setLoading] = useState(false);
  const [coverPhoto, setCoverPhoto] = useState<File | null | string>(
    editGame?.cover_photo || null
  );
  const [gameLogo, setGameLogo] = useState<File | null | string>(
    editGame?.logo || null
  );
  const [gameLeftAlignLogo, setGameLeftAlignLogo] = useState<
    File | null | string
  >(editGame?.left_logo || null);
  const [gameIcon, setGameIcon] = useState<File | null | string>(
    editGame?.mini_icon || null
  );
  const [gameBanner, setGameBanner] = useState<File | null | string>(
    editGame?.banner || null
  );
  const [gameBasicInfo, setGameBasicInfo] = useState<IGameData>({
    name: editGame?.name || '',
    erc_contract: editGame?.erc_contract || '',
    erc_contract_chain: editGame?.erc_contract_chain || '',
    short_description: editGame?.short_description || '',
    description: editGame?.description || '',
    minimum_token: editGame?.minimum_token.toString() || '',
    minimum_gold_badge_token:
      editGame?.minimum_gold_badge_token.toString() || '',
    minimum_silver_badge_token:
      editGame?.minimum_silver_badge_token.toString() || '',
    minimum_bronze_badge_token:
      editGame?.minimum_bronze_badge_token.toString() || '',
    creators: editGame?.creators || '',
    developers: editGame?.developers || '',
    platforms: editGame?.platforms || '',
    genres: editGame?.genres || '',
    modes: editGame?.modes || '',
    release_date: editGame?.release_date || '',
    guild_captain_stz_requirement:
      editGame?.guild_captain_stz_requirement || '0',
  });

  const [gameErrors, setGameErrors] =
    useState<FormErrors<IGameData | null>>(null);

  const [nftContracts, setNftContracts] = useState<IGameContractData[]>([]);

  const [gameImageErrors, setGameImageErrors] =
    useState<FormErrors<IGameImageData | null>>(null);

  // eslint-disable-next-line
  const [nftContractErrors, setNftContractErrors] =
    useState<FormErrors<IGameContractData[] | null>>(null);

  const handleRemoveContract = async (index: number, id: string) => {
    if (editGame && id.length) {
      await deleteGameNftContract(editGame.id, id);
    }
    const fields = [...nftContracts];

    fields.splice(index, 1);
    setNftContracts([...fields]);
  };

  const nftContractOnChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number
  ) => {
    setNftContracts(contracts =>
      contracts.map((contract, i) => {
        if (i === index && e.target) {
          return {
            ...contract,
            [e.target.name]: e.target.value,
          };
        }

        return contract;
      })
    );
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.currentTarget;

    setGameBasicInfo(prev => ({ ...prev, [name]: value }));
  };

  const handleSaveGame = async () => {
    let hasErrors = false;
    setGameErrors(null);
    setNftContractErrors(null);
    setGameImageErrors(null);

    const { errors: validationErrors } = await validateData<IGameData>(
      gameSchema,
      gameBasicInfo
    );

    const { errors: nftContractErrors } = await validateData<
      IGameContractData[]
    >(nftContractsSchema, nftContracts);

    if (!editGame) {
      const { errors: _gameImageErrors } = await validateData<IGameImageData>(
        imageSchema,
        {
          banner: gameBanner,
          cover_phote: coverPhoto,
          icon: gameIcon,
          left_align_logo: gameLeftAlignLogo,
          logo: gameLogo,
        }
      );

      if (_gameImageErrors) {
        setGameImageErrors(_gameImageErrors);
        hasErrors = true;
      }
    }

    if (validationErrors) {
      setGameErrors(validationErrors);
      hasErrors = true;
    }
    if (nftContractErrors) {
      setNftContractErrors(nftContractErrors);
      hasErrors = true;
    }

    if (hasErrors) {
      return alert('Please fill up all fields');
    }

    const images = new FormData();
    const _screenshots = new FormData();

    // game images

    images.append('icon', gameIcon!);
    images.append('logo', gameLogo!);
    images.append('left_align_logo', gameLeftAlignLogo!);
    images.append('banner', gameBanner!);
    images.append('cover_photo', coverPhoto!);

    // game screenshots

    screenshots.forEach(screenshot => {
      _screenshots.append('screenshots', screenshot!);
    });

    try {
      setLoading(true);
      if (editGame) {
        await updateGame(editGame.id, { ...gameBasicInfo });
        if (nftContracts.length) {
          await Promise.all(
            nftContracts.map(async contract => {
              if (contract.id && editGame) {
                await updateGameNftContract(editGame.id, contract.id, {
                  contract: contract.contract_address,
                  chain: contract.contract_chain,
                  contract_type: contract.contract_type,
                });
              } else {
                await addGameNftContract(editGame.id, {
                  contract: contract.contract_address,
                  chain: contract.contract_chain,
                  contract_type: contract.contract_type,
                });
              }
            })
          );
        }

        const gameUpdatedImages = new FormData();
        if (gameIcon && typeof gameIcon !== 'string') {
          gameUpdatedImages.append('icon', gameIcon);
        }
        if (gameLogo && typeof gameLogo !== 'string') {
          gameUpdatedImages.append('logo', gameLogo);
        }
        if (gameLeftAlignLogo && typeof gameLeftAlignLogo !== 'string') {
          gameUpdatedImages.append('left_align_logo', gameLeftAlignLogo);
        }
        if (gameBanner && typeof gameBanner !== 'string') {
          gameUpdatedImages.append('banner', gameBanner);
        }
        if (coverPhoto && typeof coverPhoto !== 'string') {
          gameUpdatedImages.append('cover_photo', coverPhoto);
        }
        await Promise.all([
          addGameImages(gameUpdatedImages, editGame.id),
          screenshots.length && addGameScreenShots(_screenshots, editGame.id),
          deleteScreenshotIds.length &&
            deleteGameScreenshots(deleteScreenshotIds),
        ]);
        dispatch.feedback.open({
          title: 'Success!',
          subtitle: 'Game updated succuessfully.',
        });
      } else {
        const { data } = await addGame({
          ...gameBasicInfo,
        });
        await Promise.all([
          addGameImages(images, data?.id),
          screenshots.length && addGameScreenShots(_screenshots, data?.id),
        ]);

        if (nftContracts.length) {
          await Promise.all(
            nftContracts.map(
              async contract =>
                await addGameNftContract(data.id, {
                  contract: contract.contract_address,
                  chain: contract.contract_chain,
                  contract_type: contract.contract_type,
                })
            )
          );
        }

        dispatch.feedback.open({
          title: 'Success!',
          subtitle: 'Game Added succuessfully.',
        });
      }

      navigate('/dashboard/games');
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        if ((err as any).code === 413) {
          dispatch.feedback.open({
            title: 'Error',
            subtitle:
              'Some of your images are too large in size please compress them. Game is added successfully!',
          });
        }
      }
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteScreenShot = (id: string) => {
    const ss = prevScreenshots.filter(screenshot => screenshot.id !== id);
    setPrevScreenshots([...ss]);
    setDeleteScreenshotsIds([...deleteScreenshotIds, id]);
  };

  const handleGetGameContracts = async () => {
    try {
      const { data } = await getGameNftContract(editGame?.id!);
      let formatedData = data.map(contract => ({
        contract_chain: contract.chain,
        contract_address: contract.contract,
        contract_type: contract.contract_type,
        id: contract.id,
      }));
      setNftContracts([...formatedData]);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  const handleGetGameScreenshots = async () => {
    try {
      const { data } = await getGameScreenshots(editGame?.id!);
      setPrevScreenshots(data);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    const _errors = { ...gameErrors };
    delete _errors.erc_contract;
    setGameErrors(_errors);
    (async () => {
      try {
        if (gameBasicInfo.erc_contract && gameBasicInfo.erc_contract_chain) {
          const { data: metadata } = await getTokenMetaData({
            public_address: gameBasicInfo.erc_contract,
            chain_id: gameBasicInfo.erc_contract_chain,
          });
          setGameToken(metadata);
        }
      } catch (err) {
        console.error(err);
        setGameErrors(prev => ({
          ...prev,
          erc_contract: 'Could not fetch token metadata for this contract!',
        }));
      }
    })();
    // eslint-disable-next-line
  }, [gameBasicInfo.erc_contract, gameBasicInfo.erc_contract_chain]);

  useEffect(() => {
    dispatch.badges.getAllBadges();
    dispatch.games.getAllGames();
    if (editGame) {
      handleGetGameContracts();
      handleGetGameScreenshots();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <GameBasicInfo>
        <div className='cover-image-placeholder'>
          {coverPhoto && (
            <>
              <img
                src={
                  coverPhoto && typeof coverPhoto !== 'string'
                    ? URL.createObjectURL(coverPhoto)
                    : coverPhoto || ''
                }
                alt=''
              />
              <div className='cover-photo-overly'></div>
            </>
          )}
        </div>
        <div className='left' style={{ zIndex: 1 }}>
          <div className='picker'>
            <ImagePicker
              placeholder='Upload or drag and drop Game logo (left alignment)'
              image={gameLeftAlignLogo}
              setImage={setGameLeftAlignLogo}
              height={'100px'}
              error={gameImageErrors?.left_align_logo}
              // customBaseStyles={{ borderColor: '#ffffff66' }}
            />
          </div>
          <GameTitleAndShortDescription className='game-info'>
            <textarea
              value={gameBasicInfo.name}
              onChange={handleChange}
              name='name'
              id=''
              className='font-size-18 title game-title'
              placeholder='Game Title'
            ></textarea>
            {gameErrors?.name && <p className='warning'>{gameErrors.name}</p>}

            <Divider />
            <textarea
              value={gameBasicInfo.short_description}
              onChange={handleChange}
              name='short_description'
              id=''
              className='font-size-14 game-short-description'
              placeholder='Enter Short Game Describtion'
            ></textarea>
            {gameErrors?.short_description && (
              <p className='warning'>{gameErrors.short_description}</p>
            )}
          </GameTitleAndShortDescription>

          <GameContracts>
            <div className='contract-container'>
              <Select
                name='erc_contract_chain'
                options={supportedChains.map(chain => ({
                  label: chain.name,
                  value: chain.chain,
                }))}
                title='Select Chain'
                value={gameBasicInfo.erc_contract_chain || ''}
                onChange={handleChange}
                error={gameErrors?.erc_contract_chain}
              />
              <InputField
                placeholder='Fungible Token Address'
                type='text'
                name='erc_contract'
                value={gameBasicInfo.erc_contract}
                onChange={handleChange}
                error={gameErrors?.erc_contract}
              />
            </div>
            {nftContracts.map((contract, index) => (
              <div className='wrapper' key={index}>
                <img
                  src={assets.closeIcon}
                  alt=''
                  className='close-icon'
                  onClick={() => handleRemoveContract(index, contract.id || '')}
                />

                <div className='nft-contract-container' key={index}>
                  <div className='grid responsive'>
                    <Select
                      name='contract_chain'
                      options={supportedChains.map(chain => ({
                        label: chain.name,
                        value: chain.chain,
                      }))}
                      title='Select Chain'
                      value={contract.contract_chain || ''}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        nftContractOnChange(e, index)
                      }
                      error={
                        nftContractErrors
                          ? // @ts-ignore
                            nftContractErrors[`[${index}].contract_chain`]
                          : undefined
                      }
                    />
                    <Select
                      name='contract_type'
                      options={supportedTypes.map(type => ({
                        label: type.name,
                        value: type.contract_type,
                      }))}
                      title='Select contract type'
                      value={contract.contract_type || ''}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                        nftContractOnChange(e, index)
                      }
                      error={
                        nftContractErrors
                          ? // @ts-ignore
                            nftContractErrors[`[${index}].contract_type`]
                          : undefined
                      }
                    />
                  </div>

                  <InputField
                    placeholder='NFT contract address'
                    type='text'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      nftContractOnChange(e, index)
                    }
                    name='contract_address'
                    value={contract.contract_address}
                    error={
                      nftContractErrors
                        ? // @ts-ignore
                          nftContractErrors[`[${index}].contract_address`]
                        : undefined
                    }
                  />
                </div>
              </div>
            ))}

            <div>
              <button
                className='new-contract font-size-12'
                onClick={() =>
                  setNftContracts([...nftContracts, { ...intialContractData }])
                }
              >
                Add another NFT Contract
              </button>
            </div>
          </GameContracts>
        </div>
        <div className='right' style={{ zIndex: 1 }}>
          <div className='container'>
            <div className='picker'>
              <ImagePicker
                image={gameLogo}
                setImage={setGameLogo}
                placeholder='Upload or drag and drop Game Logo (centered)'
                height='139px'
                error={gameImageErrors?.logo}
                // customBaseStyles={{ borderColor: '#ffffff66' }}
              />
            </div>
            <div className='picker'>
              <ImagePicker
                image={gameIcon}
                setImage={setGameIcon}
                placeholder='Upload or drag and drop Game Icon'
                height='139px'
                error={gameImageErrors?.icon}
                // customBaseStyles={{ borderColor: '#ffffff66' }}
              />
            </div>
            <div className='picker'>
              <ImagePicker
                image={gameBanner}
                setImage={setGameBanner}
                placeholder='Upload or drag and drop Game Banner'
                height='139px'
                error={gameImageErrors?.banner}
                // customBaseStyles={{ borderColor: '#ffffff66' }}
              />
            </div>
            <div>
              <label htmlFor='cover-photo' className='upload-btn font-size-12'>
                Upload Cover Photo
              </label>
              <input
                type='file'
                style={{ display: 'none' }}
                id='cover-photo'
                accept='image/*'
                onChange={e => {
                  // @ts-ignore
                  setCoverPhoto(e.target.files[0]);
                }}
              />
              {gameImageErrors?.cover_phote && (
                <p className='warning' style={{ marginTop: '5px' }}>
                  {gameImageErrors.cover_phote}
                </p>
              )}
            </div>
          </div>
        </div>
      </GameBasicInfo>
      <Padding>
        <TokenFiledsContainer>
          {gameToken && (
            <div className='token-item'>
              <TokenInput
                icon={assets.stzIcon}
                label={`Minimum Required ${gameToken?.symbol} Tokens`}
                name='minimum_token'
                value={gameBasicInfo.minimum_token}
                title={gameToken?.symbol || ''}
                noIcon
                onChange={handleChange}
                error={gameErrors?.minimum_token}
              />
            </div>
          )}
          <div className='token-item'>
            <TokenInput
              icon={assets.stzIcon}
              label='Minimum Required Bronze Badge Tokens'
              name='minimum_bronze_badge_token'
              value={gameBasicInfo.minimum_bronze_badge_token}
              title='STZ'
              onChange={handleChange}
              error={gameErrors?.minimum_bronze_badge_token}
            />
          </div>
          <div className='token-item'>
            <TokenInput
              icon={assets.stzIcon}
              label='Minimum Required Silver Badge Tokens'
              name='minimum_silver_badge_token'
              value={gameBasicInfo.minimum_silver_badge_token}
              title='STZ'
              onChange={handleChange}
              error={gameErrors?.minimum_silver_badge_token}
            />
          </div>
          <div className='token-item'>
            <TokenInput
              icon={assets.stzIcon}
              label='Minimum Required Gold Badge Tokens'
              name='minimum_gold_badge_token'
              value={gameBasicInfo.minimum_gold_badge_token}
              title='STZ'
              onChange={handleChange}
              error={gameErrors?.minimum_gold_badge_token}
            />
          </div>
          <div className='token-item'>
            <TokenInput
              icon={assets.stzIcon}
              label='Required STZ Tokens Per Scholar'
              name='guild_captain_stz_requirement'
              value={gameBasicInfo.guild_captain_stz_requirement}
              title='STZ'
              onChange={handleChange}
              error={gameErrors?.guild_captain_stz_requirement}
            />
          </div>
        </TokenFiledsContainer>
        <ScreenShotSection>
          <h1
            className='font-size-20 title'
            style={{ fontWeight: '400', marginBottom: '30px' }}
          >
            Add In-game Screenshots
          </h1>
          <div className='grid'>
            {prevScreenshots.map(screenshot => (
              <div className='item' key={screenshot.id}>
                <div className='image'>
                  <img src={screenshot.screenshot_url} alt='' />
                </div>
                <div className='row'>
                  <p className='font-size-12'>delete</p>
                  <img
                    src={assets.kill}
                    className='remove-btn'
                    alt=''
                    onClick={() => handleDeleteScreenShot(screenshot.id)}
                  />
                </div>
              </div>
            ))}

            {screenshots.map((screenshot, index) => (
              <div className='item' key={index}>
                <ScreenshotPicker
                  image={screenshot}
                  imageList={screenshots}
                  setImages={setScreenshots}
                  index={index}
                />
              </div>
            ))}
            {screenshots.length + prevScreenshots.length < 8 && (
              <div className='item'>
                <ScreenshotPicker
                  image={null}
                  imageList={screenshots}
                  setImages={setScreenshots}
                />
              </div>
            )}
          </div>
        </ScreenShotSection>
        <GameDescriptionAndInfo>
          <div className='description'>
            <h1
              className='font-size-18 title'
              style={{ paddingInline: '10px' }}
            >
              Full Game Description
            </h1>
            <Divider style={{ marginBlock: '20px' }} />
            <textarea
              value={gameBasicInfo.description}
              name='description'
              onChange={handleChange}
              className='font-size-14'
              placeholder='Enter game description'
            ></textarea>
            {gameErrors?.description && (
              <p className='warning'>{gameErrors.description}</p>
            )}
            {/* <div className='overly'></div> */}
          </div>
          <div className='info'>
            <div className='row'>
              <p className='font-size-12 key'>Release Date</p>

              <input
                type='date'
                placeholder='Add release date'
                className='font-size-14'
                value={gameBasicInfo.release_date}
                name='release_date'
                onChange={handleChange}
                style={{ position: 'relative' }}
              />
              {gameErrors?.release_date && (
                <p className='warning'>{gameErrors.release_date}</p>
              )}
            </div>
            <div className='row'>
              <p className='font-size-12 key'>Gendre(s)</p>
              <input
                type='text'
                placeholder='Add Gendre(s)'
                className='font-size-14'
                value={gameBasicInfo.genres}
                name='genres'
                onChange={handleChange}
              />
              {gameErrors?.genres && (
                <p className='warning'>{gameErrors.genres}</p>
              )}
            </div>
            <div className='row'>
              <p className='font-size-12 key'>Mode(s)</p>
              <input
                type='text'
                placeholder='Add Mode(s)'
                className='font-size-14'
                value={gameBasicInfo.modes}
                name='modes'
                onChange={handleChange}
              />
              {gameErrors?.modes && (
                <p className='warning'>{gameErrors.modes}</p>
              )}
            </div>
            <div className='row'>
              <p className='font-size-12 key'>Creator(s)</p>
              <input
                type='text'
                placeholder='Add Creator(s)'
                className='font-size-14'
                value={gameBasicInfo.creators}
                name='creators'
                onChange={handleChange}
              />
              {gameErrors?.creators && (
                <p className='warning'>{gameErrors.creators}</p>
              )}
            </div>
            <div className='row'>
              <p className='font-size-12 key'>Developer(s)</p>
              <input
                type='text'
                placeholder='Add Developer(s)'
                className='font-size-14'
                value={gameBasicInfo.developers}
                name='developers'
                onChange={handleChange}
              />
              {gameErrors?.developers && (
                <p className='warning'>{gameErrors.developers}</p>
              )}
            </div>
            <div className='row'>
              <p className='font-size-12 key'>Platform(s)</p>
              <input
                type='text'
                placeholder='Add Platform(s)'
                className='font-size-14'
                value={gameBasicInfo.platforms}
                name='platforms'
                onChange={handleChange}
              />
              {gameErrors?.platforms && (
                <p className='warning'>{gameErrors.platforms}</p>
              )}
            </div>
          </div>
        </GameDescriptionAndInfo>
        {/* <SimilarGamesSection>
          <h1 className='font-size-20 title' style={{ fontWeight: '400' }}>
            Similiar Games
          </h1>
          <div className='grid responsive'>
            <AddSimilarGame />
            <AddSimilarGame />
            <AddSimilarGame />
            <AddSimilarGame />
          </div>
        </SimilarGamesSection> */}
        <ButtonContainer>
          <Button variant='secondary'>Cancel</Button>
          <Button
            variant='primary'
            onClick={handleSaveGame}
            disabled={loading}
            loading={loading}
            className='saveButton'
          >
            Save changes
          </Button>
        </ButtonContainer>
      </Padding>
    </Page>
  );
};

export default AddNewGame;

const Page = styled.div`
  padding-top: 32px;
`;

const Padding = styled.div`
  padding: 60px 56px;
  @media (min-width: 280px) and (max-width: 520px) {
    padding: 20px 20px;
  }
`;

const GameBasicInfo = styled.div`
  padding: 42px 56px;
  background-image: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  background-color: ${theme.palette.primary};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  @media (min-width: 280px) and (max-width: 520px) {
    padding: 20px 20px;
  }

  & .cover-image-placeholder {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  & .cover-photo-overly {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  }

  & .left {
    width: 100%;
    max-width: 600px;
    & .picker {
      width: 280px;
    }
  }

  & .right {
    width: 100%;
    max-width: 184px;
    & .container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: center;
      & .upload-btn {
        padding: 4px 16px;
        background-color: #ffffff1a;
        border-radius: 2px;
        border: none;
        cursor: pointer;
      }
    }
  }
`;

const GameTitleAndShortDescription = styled.div`
  background-color: #000000;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding-inline: 30px;
  padding-block: 24px;
  margin-block: 20px 52px;
  max-width: 540px;
  & textarea {
    width: 100%;
    resize: none;
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0px;
    margin-inline: 10px;
    &.game-title {
      margin-bottom: 10px;
      &::placeholder {
        opacity: 1;
        color: #ffffff;
      }
    }

    &.game-short-description {
      margin-top: 20px;
      &::placeholder {
        opacity: 0.8;
      }
    }
  }
`;

const Divider = styled.div`
  border-top: 1px solid #ffffff33;
`;

const ScreenShotSection = styled.section`
  & .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
    gap: 60px 24px;
    & .item {
      height: 162px;

      & .image {
        height: 162px;
        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      & .row {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
        & img {
          cursor: pointer;
        }
      }
    }
  }
`;

const GameDescriptionAndInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 84px;
  margin-top: 90px;
  flex-wrap: wrap;
  justify-content: center;
  & .description {
    background: #000000;
    border-radius: 4px;
    padding-inline: 30px;
    padding-block: 24px 30px;
    flex: 1;
    position: relative;
    & textarea {
      width: 100%;
      resize: none;
      background-color: transparent;
      color: #ffffff;
      outline: none;
      border: none;
      padding-inline: 10px;
      height: 180px;
      max-height: 180px;
      overflow-y: auto;
    }
    & .overly {
      position: absolute;
      left: 0;
      bottom: 30px;
      width: 100%;
      pointer-events: none;
      height: 30px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    }
  }

  & .info {
    flex: 1;
    background: #121212;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    padding: 10px 20px;

    & .row {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 15px 10px;
      border-bottom: 1px solid #ffffff33;

      &:last-of-type {
        border-bottom: none;
      }

      & .key {
        width: 15ch;
      }

      & input {
        flex-grow: 1;
        background-color: transparent;
        border: none;
        outline: none;
      }
    }
  }
`;
// eslint-disable-next-line
const SimilarGamesSection = styled.section`
  margin-top: 60px;

  & .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
  }
  & .responsive {
    @media (min-width: 280px) and (max-width: 520px) {
      grid-template-columns: 1fr;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-block: 40px 60px;
`;

const TokenFiledsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
  gap: 24px;
  margin-bottom: 60px;
`;

const GameContracts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & .wrapper {
    position: relative;

    & img.close-icon {
      position: absolute;
      right: 0;
      top: -10px;
      transform: translate(0%, -100%);
      cursor: pointer;
    }
  }
  & .nft-contract-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 34px;

    & .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
    }
    & .responsive {
      @media (min-width: 280px) and (max-width: 520px) {
        grid-template-columns: 1fr;
      }
    }
  }
  & .contract-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 24px;
    @media (min-width: 280px) and (max-width: 520px) {
      grid-template-columns: 1fr;
    }
  }
  & .new-contract {
    background-color: #ffffff1a;
    border: none;
    border-radius: 2px;
    padding: 4px 16px;
    cursor: pointer;
  }
`;
