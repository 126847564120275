import React, { FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

interface IProps {
  open: boolean;
  children: React.ReactNode;
  width?: string | number;
}

const DialogLayout: FC<IProps> = ({ open, children, width }) => {
  const sidebar = useSelector((state: RootState) => state.sideBar);
  return (
    <>
      {open && (
        <Dialog
          style={{
            paddingLeft: sidebar.open
              ? sidebar.hidden
                ? '0px'
                : '104px'
              : sidebar.hidden
              ? '0px'
              : '104px',
          }}
        >
          <div className='dialog-content' style={{ width: width }}>
            {children}
          </div>
        </Dialog>
      )}
    </>
  );
};

export default DialogLayout;

const Dialog = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.3s ease;
  background-color: #0e0e0ee6;
  z-index: 101;
  & .dialog-content {
    max-height: 95vh;
    /* padding-block: 50px; */
    overflow-y: auto;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 700px) {
    padding-inline: 25px;
    padding-left: 25px !important;
    & .dialog-content {
      padding: 0px;
    }
  }
`;
