import React, { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import classNames from 'classnames';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from '../../store';

import Page from '../../layouts/Page';
import InputField from '../../components/InputField/InputField';
import Button from '../../components/Button/Button';
import Filters from '../../components/Filters/Filters';
import Select from '../../components/Select/Select';

import assets from '../../assets';
import {
  getCaptainGames,
  getCaptainScholars,
  updateScholarPercentage,
} from '../../http';
import axios from 'axios';
import { IFilterGame, IScholarUser } from '../../types/interfaces';
import Loader from '../../components/Loader/Loader';

const CaptainProfile: FC = () => {
  const dispatch = useDispatch<Dispatch>();

  const captain = useSelector((state: RootState) => state.captain.selected);
  const [filters, setFitlers] = useState({
    game: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.currentTarget;
    setFitlers(prev => ({ ...prev, [name]: value }));
  };

  const [loading, setLoading] = useState(true);
  const [scholars, setScholars] = useState<IScholarUser[]>([]);

  const [captainGames, setCaptainGames] = useState<IFilterGame[]>([]);

  const [tab, setTab] = useState(0);
  const [revenueSplit, setRevenueSplit] = useState(
    captain?.scholar_percentage || ''
  );
  const [rLoading, setRLoading] = useState(false);

  const handleUpdateRevenueSplit = async () => {
    try {
      setRLoading(true);

      await updateScholarPercentage(captain!.id, { percentage: +revenueSplit });
      dispatch.feedback.open({
        title: 'Success',
        subtitle: 'Revenue split updated successfuly',
      });
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        const errorMessage = (err as any).response?.data.message;
        dispatch.feedback.open({
          title: 'Error',
          subtitle: errorMessage,
        });
      }
    } finally {
      setRLoading(false);
    }
  };

  useEffect(() => {
    if (captainGames.length) {
      setFitlers(prev => ({ ...prev, game: captainGames[0].id }));
    }
    // eslint-disable-next-line
  }, [captainGames]);

  useEffect(() => {
    (async () => {
      if (captain && filters.game) {
        try {
          setLoading(true);
          const { data: scholars } = await getCaptainScholars(
            captain.id,
            filters.game
          );
          setScholars(scholars);
        } catch (err) {
          if (axios.isAxiosError(err)) {
            const errorMessage = (err as any).response?.data.message;
            dispatch.feedback.open({ title: 'Error', subtitle: errorMessage });
          }
        } finally {
          setLoading(false);
        }
      }
    })();
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    (async () => {
      try {
        if (captain) {
          const { data: games } = await getCaptainGames(captain!.id);
          setCaptainGames(games);
        }
      } catch (err: any) {
        console.log(err.message);
      }
    })();
    // eslint-disable-next-line
  }, []);

  if (!captain) {
    return <Navigate to='/dashboard' />;
  }

  return (
    <Page>
      <Filters>
        <Grid>
          <Select
            title='Game'
            name='game'
            value={filters.game}
            onChange={handleChange}
            options={captainGames.map(game => ({
              // @ts-ignore
              label: game?.name,
              // @ts-ignore
              value: game?.id,
            }))}
          />
        </Grid>
      </Filters>
      <Container>
        <ProfileAndAssets>
          <div className='wrapper'>
            <div className='user-image'>
              <img src={captain.image_url || assets.noImage} alt='' />
            </div>
            <p className='font-size-18 title'>{captain?.nick_name}</p>
          </div>
        </ProfileAndAssets>
        <Information>
          <Tabs>
            <p
              className={classNames(
                'font-size-14 title tab',
                tab === 0 && 'active'
              )}
              onClick={() => setTab(0)}
            >
              Account Information
            </p>
            <p
              className={classNames(
                'font-size-14 title tab',
                tab === 1 && 'active'
              )}
              onClick={() => setTab(1)}
            >
              Payout History
            </p>
          </Tabs>

          {tab === 0 && (
            <TabPanel>
              <div className='grid'>
                <InputField
                  placeholder='Nickname*'
                  type='text'
                  value={captain?.nick_name || ''}
                  disabled
                />

                <InputField
                  placeholder='Email Address'
                  type='text'
                  value={captain?.email || ''}
                  disabled
                />

                <InputField
                  placeholder='Country*'
                  type='text'
                  value={captain?.country || ''}
                  disabled
                />
                <InputField
                  placeholder='Discord Handle'
                  type='text'
                  value={captain?.discord || ''}
                  disabled
                />
                <InputField
                  placeholder='Payout Wallet Address'
                  type='text'
                  value={captain?.wallet_address || ''}
                  disabled
                />

                {/* <Select
                  title="Revenue Split (captain / admin)"
                  options={revenueSplitOptions}
                  value={revenueSplit || ""}
                  name="rev"
                  onChange={(e) => setRevenueSplit(e.target.value)}
                /> */}

                <InnerDiv>
                  <div className='revenueSplits'>
                    <p>
                      {100 - +revenueSplit}/{revenueSplit}
                    </p>
                  </div>
                  <InputField
                    type='range'
                    className='rangestyle'
                    name='rev'
                    placeholder='Revenue Split (scholar / admin)'
                    value={revenueSplit || ''}
                    max='100'
                    min='0'
                    step='1'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setRevenueSplit(e.target.value)
                    }
                    style={{ paddingInline: '0px  ' }}
                  />
                </InnerDiv>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',
                }}
              >
                <Button
                  variant='primary'
                  onClick={handleUpdateRevenueSplit}
                  loading={rLoading}
                  disabled={rLoading}
                >
                  Save changes
                </Button>
              </div>
            </TabPanel>
          )}
          {tab === 1 && (
            <></>
            // <TabPanel>
            //   {payoutHistory.length && (
            //     <TableContainer>
            //       <table>
            //         <thead>
            //           <tr className='header-row'>
            //             <th>Wallet Address</th>
            //             <th>Subwallet Address</th>
            //             <th>Payout</th>
            //           </tr>
            //         </thead>
            //         <tbody>
            //           {payoutHistory.map((history, index) => (
            //             <tr key={index}>
            //               <td>
            //                 <EthAddress
            //                   address={
            //                     scholar?.accounts[0].wallet_address || ''
            //                   }
            //                 />
            //               </td>
            //               <td>
            //                 <EthAddress
            //                   address={
            //                     scholar?.accounts[0].starz_wallet_address || ''
            //                   }
            //                 />
            //               </td>
            //               <td>
            //                 <div className='row'>
            //                   <img src={assets.stzIcon} alt='' />
            //                   <p className='font-size-14'>
            //                     {history.payout_amount_user}{' '}
            //                     {history.payout_symbol}
            //                   </p>
            //                 </div>
            //               </td>
            //               <td>
            //                 {/* {getFormattedDate(history.created_at)} */}
            //                 </td>
            //             </tr>
            //           ))}
            //         </tbody>
            //       </table>
            //     </TableContainer>
            //   )}
            // </TabPanel>
          )}
        </Information>
      </Container>
      {loading && !scholars.length ? (
        <Loader />
      ) : (
        <TableContainer>
          <table>
            <thead>
              <tr className='header-row'>
                <th>User</th>
                <th>Email</th>
                <th>Starz wallet address</th>
                <th>Revenue split (Captain / Scholar)</th>
              </tr>
            </thead>
            <tbody>
              {scholars?.map(scholar => (
                <tr key={scholar.id}>
                  <td>
                    <div className='row'>
                      <img
                        style={{
                          borderRadius: '50%',
                          height: '30px',
                          width: '30px',
                        }}
                        src={scholar.image_url || assets.noImage}
                        alt=''
                      />
                      <p className='font-size-14'> {scholar.fullname}</p>
                    </div>
                  </td>
                  <td>{scholar.email}</td>
                  <td>{scholar.starz_wallet_address}</td>
                  <td>
                    {100 - scholar.scholar_percentage}% /{' '}
                    {scholar.scholar_percentage}%
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      )}
    </Page>
  );
};

export default CaptainProfile;

const Container = styled.div`
  margin-block: 80px 60px;
  display: flex;
  gap: 64px;

  @media only screen and (max-width: 950px) {
    flex-direction: column;
  }
`;

const ProfileAndAssets = styled.div`
  flex: 0 0 39%;
  max-width: 456px;
  padding-bottom: 40px;
  & .wrapper {
    background-image: url(${assets.glitch});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-inline: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & .user-image {
      margin-top: -40px;
      & img {
        height: 144px;
        width: 144px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    & .row {
      display: flex;
      align-items: center;
      gap: 8px;

      &.highest-badge {
        & img {
          width: 25px;
        }
      }
    }

    & p.title {
      margin-top: 30px;
      margin-bottom: 8px;
    }
  }

  & .btn {
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 950px) {
    flex: 0 0 100%;
    margin-inline: auto;

    & .wrapper {
      padding-inline: 20px;
    }
  }
`;

const Information = styled.div`
  flex-grow: 1;
`;

const Tabs = styled.div`
  display: flex;
  gap: 32px;
  padding-inline: 16px;
  border-bottom: 1px solid #ffffff33;
  padding-block: 8px;

  & .tab {
    cursor: pointer;
    opacity: 0.4;
    transition: 0.4s ease;
    &.active {
      opacity: 1;
    }
  }
`;

const TabPanel = styled.div`
  & .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px 38px;
    margin-top: 52px;
    & .rangestyle {
      /* background-color: aqua; */
      padding: 5px;
    }
  }

  @media only screen and (max-width: 420px) {
    & .grid {
      grid-template-columns: 1fr;
    }
  }
`;

const TableContainer = styled.div`
  overflow-x: auto;
  border: 1px solid #ffffff33;
  padding-bottom: 10px;
  width: 100%;
  margin-block: 30px 60px;
  border-radius: 4px;
  & table {
    border-collapse: collapse;
    min-width: 950px;
    width: 100%;
    & tbody {
      padding-inline: 10px;
    }
    & .header-row {
      height: 40px;
      background-color: #0e0e0e;
      & th {
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        opacity: 0.6;
        padding-top: 4px;
        border-bottom: 1px solid #ffffff33;
        text-align: left;
        &:first-of-type {
          padding-left: 32px;
        }

        & .row {
          display: flex;
          align-items: center;
          gap: 8px;

          & .col {
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
        }
      }
    }
    & tr {
      height: 60px;
    }
    & td {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      border-bottom: 1px solid #ffffff33;
      padding-block: 12px;
      &:first-of-type {
        padding-left: 32px;
      }

      &:last-of-type {
        padding-right: 10px;
      }

      & .row {
        display: flex;
        align-items: center;
        gap: 10px;

        & img {
          width: 20px;
        }
      }

      & .icon-button {
        & img {
          padding-inline: 5px;
          cursor: pointer;
        }
        & .menu {
          position: absolute;
          width: 200px;
          background: #0e0e0e;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
          transform: translate(-90%, -115%);
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
          display: none;
          & .menu-item {
            padding: 10px 5px;
            cursor: pointer;
            &:hover {
              background-color: #000000;
            }
          }
          & p {
            opacity: 0.6;
          }
          &.show {
            display: block;
          }
        }
      }
    }
    & tr:last-child {
      & td {
        border: none;
      }
    }
  }
`;

const InnerDiv = styled.div`
  color: white;
  & .revenueSplits {
    position: absolute;
    right: 2.5rem;
    margin-top: -1.4rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
`;
